    import React from 'react';
    import { Line } from 'react-chartjs-2';
    import 'chart.js/auto';
    import { useTranslation } from 'react-i18next';

    const AudiogramChart = ({ audiogram }) => {
    const { t } = useTranslation();

    if (!audiogram || !audiogram.results) {
    return <p>{t('audiogramChart_noData')}</p>;
    }

    const desiredFrequencies = [125, 250, 500, 1000, 2000, 4000, 8000];

    // Function to map frequencies to positions
    const mapFrequencyToPosition = (freq) => {
    const baseFreq = 125;
    const octave = Math.log2(freq / baseFreq);
    const position = octave * 10; // Scale so that 1 octave = 10 units
    return position;
    };

    // Function to normalize ear values
    const normalizeEarValue = (earValue) => {
    if (earValue === '1' || earValue === 'right') return 'right';
    if (earValue === '2' || earValue === 'left') return 'left';
    return null;
    };

    const getResultForFrequency = (freq, ear) => {
    const normalizedEar = normalizeEarValue(ear);
    const resultsForFreqEar = audiogram.results.filter(
        (result) => result.Hz === freq && normalizeEarValue(result.ear) === normalizedEar
    );
    return resultsForFreqEar[0] || null;
    };

    const rightEarData = [];
    const leftEarData = [];

    if (audiogram && audiogram.results) {
    desiredFrequencies.forEach((freq) => {
        // Right Ear
        const rightResult = getResultForFrequency(freq, 'right');
        if (rightResult) {
        rightEarData.push({ x: mapFrequencyToPosition(freq), y: rightResult.dbLevel });
        }

        // Left Ear
        const leftResult = getResultForFrequency(freq, 'left');
        if (leftResult) {
        leftEarData.push({ x: mapFrequencyToPosition(freq), y: leftResult.dbLevel });
        }
    });
    }

    const data = {
    datasets: [
        {
        label: t('audiogramChart_rightEar'),
        data: rightEarData,
        fill: false,
        borderColor: 'red',
        pointBackgroundColor: 'white',
        pointBorderColor: 'red',
        pointBorderWidth: 2,
        pointRadius: 8,
        pointStyle: 'circle',
        showLine: true,
        },
        {
        label: t('audiogramChart_leftEar'),
        data: leftEarData,
        fill: false,
        borderColor: 'blue',
        pointBackgroundColor: 'white',
        pointBorderColor: 'blue',
        pointBorderWidth: 2,
        pointRadius: 8,
        pointStyle: 'crossRot',
        showLine: true,
        },
    ],
    };

    const options = {
    maintainAspectRatio: true,
    aspectRatio: 1,
    layout: {
        padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 10,
        },
    },
    scales: {
        x: {
        type: 'linear',
        min: -2, // Extend 5 units before 125 Hz
        max: 62, // Extend 5 units after 8000 Hz
        ticks: {
            stepSize: 10,
            callback: function (value) {
            // Label only at desired frequencies
            if (value % 10 === 0 && value >= 0 && value <= 60) {
                const freq = 125 * Math.pow(2, value / 10);
                return freq >= 1000 ? `${freq / 1000}k` : freq;
            }
            return '';
            },
        },
        title: {
            display: true,
            text: t('audiogramChart_xAxisTitle'),
        },
        grid: {
            drawTicks: true,
            drawBorder: true,
            color: function (context) {
            if (context.tick.value % 10 === 0) {
                return 'rgba(0, 0, 0, 0.1)'; // Solid lines at desired frequencies
            } else {
                return 'rgba(0, 0, 0, 0.05)'; // Lighter grid lines
            }
            },
        },
        },
        y: {
        reverse: true,
        min: -10,
        max: 110,
        ticks: {
            stepSize: 10,
        },
        title: {
            display: true,
            text: t('audiogramChart_yAxisTitle'),
        },
        },
    },
    };

    return (
        <div style={{ width: '800px', height: '800px' }}>
            <Line data={data} options={options} />
        </div>
        );
    };
    export default AudiogramChart;
