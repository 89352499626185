// AudiogramCompensationMain.js

import React, { useState } from 'react';
import SettingsNavbar from "../../Settingspage/SettingsNavbar";
import CompensationSettingsBox from './CompensationSettingsBox';
import CompensationFrequencyDataBox from './CompensationFrequencyDataBox';

const AudiogramCompensationMain = () => {
    const [selectedModel, setSelectedModel] = useState('');
    const [compensationData, setCompensationData] = useState({});

    const handleModelChange = (modelName, data) => {
        setSelectedModel(modelName);
        setCompensationData(data);
    };

return (
    <div className="min-h-screen bg-gray-100 flex p-6">
        <div className=""></div>
            <SettingsNavbar />
            <div className="min-h-screen bg-gray-100 flex">
            {/* Left Section */}
            <div className="flex-grow p-10 bg-white ">
                <CompensationSettingsBox onModelChange={handleModelChange} />
            </div>
            {/* Right Section */}
            <div className="flex-grow p-10 bg-white">
                <CompensationFrequencyDataBox
                selectedModel={selectedModel}
                compensationData={compensationData}
                />
            </div>
        </div>
    </div>
    );
};

export default AudiogramCompensationMain;
