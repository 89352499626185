import React, { useState } from 'react';
import { FINFlagIcon, FRIcon, UKFlagIcon, globe } from '../../assets/Icons';
import useLanguage from '../../hooks/useLanguage';

function LanguageSwitcher({ className = "flex items-center gap-2 px-4 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 w-full" }) {
    const { language, changeLanguage } = useLanguage();
    const [showDropdown, setShowDropdown] = useState(false);

    // Language options
    const languages = [
        { code: 'en', label: 'English', icon: UKFlagIcon },
        { code: 'fi', label: 'Finnish', icon: FINFlagIcon },
        ////{ code: 'fr', label: 'French', icon: FRIcon },
        // Add more languages here as needed
    ];

    const handleLanguageChange = (langCode) => {
        changeLanguage(langCode);
        setShowDropdown(false); // Close the dropdown after selecting a language "flex items-center gap-2 px-4 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100"
    };

    return (
        <div className="relative inline-block w-full">
            {/* Language Button */}
            <button
                className={className}
                onClick={() => setShowDropdown(!showDropdown)}
            >
                {/* Optional: Include the current language icon */}
                <img
                    src={languages.find((lang) => lang.code === language)?.icon || UKFlagIcon}
                    alt={`${globe} flag`}
                    className="w-6 h-6"
                />
                <span className="text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide">
                    Language
                </span>
            </button>

            {/* Dropdown Menu */}
            {showDropdown && (
                <div className="absolute right-0 mt-2 py-2 bg-white border border-gray-300 rounded-lg shadow-lg z-50 w-full">
                    {languages.map((lang) => (
                        <div
                            key={lang.code}
                            className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-200 gap-x-2"
                            onClick={() => handleLanguageChange(lang.code)}
                        >
                            <img src={lang.icon} alt={`${lang.label} flag`} className="w-6 h-6" />
                            <span className="text-gray-700">{lang.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LanguageSwitcher;