import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../Slices/authSlice";
import { soundSlice } from "../Slices/soundSilce";
import { dinSlice } from "../Slices/dinSettingSlice";
import { sideBarSlice } from "../Slices/sideBarSlice";
import { selectedPatientSlice } from "../Slices/selectedPatientSlice";
import { languageSlice } from "../Slices/languageSlice";
import { lessThan70Slice } from "../Slices/lessthan70ExitSlice";

/* Configure store for global state management */
export const store = configureStore({
  reducer: {
    authState: authSlice.reducer,
    soundState: soundSlice.reducer,
    dinState: dinSlice.reducer,
    soundtest: soundSlice.reducer, // Use 'soundtest' as the key,
    sidebrState: sideBarSlice.reducer,
    selectedPatientState: selectedPatientSlice.reducer,
    languageState: languageSlice.reducer,
    lessThan70State: lessThan70Slice.reducer
  },
});