import React, { useEffect, useState } from 'react';
import GlobalNavbar from '../../../utils/GlobalNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserDisplaySection from '../../Global/UserDisplaySection';
import ContentWrapper from '../../../utils/ContentWrapper';
import CheckCircleIconPath from '../../../assets/Icons/check-circle.png'; // Import as image path
import HeadPhoneIcon from '../../../assets/Icons/headphones.png'; // Import as image path
import SpeakersIcon from '../../../assets/Icons/speaker.png'; // Import as image path

import { setSoundTestIndex } from '../../../Slices/soundSilce';
import { useTranslation } from 'react-i18next';


const TestLandingPage = () => {
    const { t } = useTranslation(); // Use useTranslation hook

    /* Config */
    const user =
        useSelector((state) => state.authState.user) ||
        JSON.parse(localStorage.getItem('user'));

    const soundTestFromStore = useSelector((state) => state.soundState.soundTest);
    const [soundTest] = useState(soundTestFromStore);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const includedTests = soundTest.filter((test) => test.included);

    



    return (
        user && (
            <div className="min-h-screen bg-gray-100 flex flex-col items-center">
                <GlobalNavbar />

                <div className="pt-0 flex justify-center flex-col px-4 min-w-[50%] overflow-auto">
                    <UserDisplaySection user={user} bannerText={t('testLandingPage_welcome')} />
                    <ContentWrapper className="">
                        <div className="px-2 flex items-center gap-x-2">
                            <img src={HeadPhoneIcon} className="w-8 h-8" alt="" />
                            <span>/</span>
                            <img src={SpeakersIcon} className="w-8 h-8" alt="" />
                            <p className="text-2xl text-[#344054] font-bold">
                                {t('testLandingPage_tests')}
                            </p>
                        </div>
                        <div className="px-4 mt-8  overflow-auto">
                            {/* Map to display test options below */}
                            {includedTests.map((test, index) => (
                                <div
                                    className={`text-sm md:text-sm lg:text-[24px] flex items-center justify-between py-2 border-b border-gray-200 ${index === 0 ? 'border-t border-gray-200' : ''
                                        }`}
                                    key={index}
                                >
                                    {/* Wrap icon and text in a flex container */}
                                    <div className="flex items-center">
                                        {test.icon && (
                                            <span className="mr-2">
                                                <img src={test.icon} alt="icon" className="w-8 h-8" />
                                            </span>
                                        )}
                                        <div>
                                            <p className="text-md font-bold">{t(test.name)}</p>
                                            <p className="text-[12px]">{test.duration}</p>
                                        </div>
                                    </div>

                                    {/* Button or Completed Status */}
                                    <div>
                                        {!test.completed ? (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    dispatch(setSoundTestIndex(index));
                                                    navigate(test.navigate);
                                                }}
                                                className="w-full bg-white text-[#53389E] border border-[#53389E] py-6 px-6 transition-colors duration-300 ease-out hover:bg-purple-600 hover:text-white hover:border-purple-600 rounded-xl"
                                            >
                                                {t('testLandingPage_startTest')}
                                            </button>
                                        ) : (
                                            <div className="flex items-center gap-x-[0px] relative py-2 px-4">
                                                <p className="text-[12px] font-bold">{t('testLandingPage_completed')}</p>
                                                <div className="relative p-2">
                                                    <img
                                                        src={CheckCircleIconPath}
                                                        className="w-6 h-6 absolute right-0 top-1/2 transform -translate-y-1/2"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </ContentWrapper>
                </div>
            </div>
        )
    );
};

export default TestLandingPage;
