import React from 'react';
import { CrossIcon, InfoIcon, LeftIcon, RippleImage1, StarIcon } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import './dinhalynewui.css';
import { useNavigate } from 'react-router-dom';


//

// V2 is for Speaker while the v1 is for headphone routing

///

const DINHalyPageNewUiAntiphasic = () => {
    const { t } = useTranslation();
    const selectedNumbersForModal = [4, 0, "?"];
    const navigate = useNavigate();
    return (
        <div className="px-4 py-4  h-screen overflow-hidden bg-[#F9FAFB] relative">
            {/* Exit and help */}
            <div className=' bg-transparent w-full mt-0 z-50 absolute' >
                <div className='text-sm gap-x-2 flex py-2 justify-between px-4 rounded-xl bg-transparent'>
                    <div className='text-md font-bold flex gap-x-1'>
                        <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-4 lg:px-6
                               2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1' onClick={(e) => {
                                navigate('/testLandingPage');
                            }}>
                            <img src={LeftIcon} alt='' className='size-4' />
                            <button className='' >{t('dinhalynewui_PreviousButton')}</button>
                        </div>
                        <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-4 lg:px-19
                               2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1'
                            onClick={e => { }}  >
                            <img src={InfoIcon} alt='ℹ️' className='size-4' />
                            <button className='' >{t('dinv6_help')}</button>
                        </div>
                    </div>
                    {/* <div className='font-bold flex justify-center items-center  '>
                            <p>{currentTripletIndex + 1} /20</p>
                        </div> */}
                </div>
            </div>

            <div className="px-4 py-4  h-screen overflow-hidden bg-[#F9FAFB] flex relative">
                {/* Div For Image */}
                <div className="flex-[50%] hidden md:flex lg:flex 2xl:flex flex-col justify-center items-center relative "
                >
                    <div className='numberpad-containernewui flex justify-center '>
                        <div className="after-bottom-right"></div>
                        {/* Top Numbers (in a flex row layout) */}
                        <div className="absolute inline-block cursor-pointer left-[-20%] top-[5%] z-40">
                            <div className="tooltipnewui text-[#42307D]">
                            {t('dinhalynewui_numbers')} 4, 0, <span 
                                    className="inline-block rounded-full  shadow-md"
                                    style={{
                                        // backgroundColor: "rgba(128, 128, 128, 0.3)", // Grayish background color
                                        boxShadow: "0 0 0px 0px rgba(138, 138, 138, 0.4)"
                                    }}
                                >?</span>
                                <div className="absolute top-14 -left-6 transform rotate-45 bg-green-500"></div>
                            </div>
                        </div>
                        {/* Number pad  */}
                        <div className="overflow-auto flex flex-col justify-center items-center z-30 ">
                            <div className=" px-12 py-4 bg-white rounded-3xl " >
                                <div className='z-[20] flex items-center gap-x-4 mt-3 mb-0 justify-center'>
                                    <div className='flex gap-x-3'>
                                        {Array(3).fill(null).map((_, index) => (
                                            <div key={index} className='w- h-8 lg:w-[48px] lg:h-[48px] 2xl:w-[80px] 2xl:h-[80px] bg-[#F4EBFF] border-2
                                             border-white rounded-full flex justify-center items-center text-gray-800 text-[24px]'>
                                                {selectedNumbersForModal[index] !== undefined ? selectedNumbersForModal[index] : ''}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-y-3 md:gap-y-3 lg:gap-y-4 2xl:gap-y-4" >
                                    <div className='flex justify-center w-full px-2 py-4 z-20 mb-0'>
                                        <div className='grid grid-cols-3 gap-5 mt-0 '>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'cross', 0, '?'].map((item, index) => (
                                                <button
                                                    key={index}
                                                    id={`button-${item}`} // Add ID for easy keypress highlighting
                                                    className={`w-[40px] h-[40px] lg:w-[80px] lg:h-[80px] 2xl:w-[120px] 2xl:h-[120px] rounded-xl 2xl:rounded-2xl flex justify-center items-center text-lg lg:text-2xl 2xl:text-3xl font-bold 
bg-white transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
shadow-[4.1px_2.6px_1px_rgba(0,0,0,1),_0px_0px_1px_rgba(0,0,0,0.8)] 
    border-[3px] border-black text-gray-800


`}
                                                >
                                                    {item === 'cross' ? <img src={CrossIcon} alt='X' className='text-sm' /> : item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Div For Info */}
                <div className="flex-[50%]">
                    <div className="h-[95vh] overflow-auto flex flex-col justify-center items-center ">
                        <div className="flex flex-col gap-y-4 md:gap-y-4 lg:gap-y-6 xl:gap-y-6">
                            <div className='flex flex-col justify-start items-center gap-y-2 flex-grow '>
                                <div className='w-full'>
                                    <h1 className='text-3xl sm:text-3xl md:text-4xl lg:text-7xl font-bold text-[#344054] '>{t('dinhalynewui_Header')}</h1>
                                </div>
                                <div className='text-[#344054] mt-2 text-sm sm:text-sm  md:text-2xl lg:text-2xl'>
                                    <div className='w-full '>
                                        <h1 className='text-left'>{t('dinhalynewui_TestIntroLine1')}</h1>
                                    </div>
                                    <div className='w-full '>
                                        <h1 className=''>{t('dinhalynewui_TestIntroLine2')}</h1>
                                    </div>
                                    <div className='w-full mt-6'>
                                        <h1 className=''>{t('dinhalynewui_TestInstructions')}</h1>
                                    </div>

                                    <div className='w-full'>

                                        <h1 className=''>{t('dinhalynewui_TestHint')} <span
                                            className={`
        ml-1 mr-1 w-[20px] h-[20px] lg:w-[20px] lg:h-[20px] 2xl:w-[20px] 2xl:h-[20px] 
        rounded-md bg-white transition-all duration-200 ease-out transform hover:scale-105 
        active:scale-95 shadow-[2px_0px_0px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)] 
        border-2 border-[#6c6f75] text-gray-800 inline-block justify-center items-center 
        text-sm lg:text-sm 2xl:text-sm
      `}
                                            style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            ?
                                        </span> {t('dinhalynewui_ButtonLabel')}.</h1>
                                    </div>
                                    <div className='w-full'>
                                        <h1 className='text-left mt-6'>{t('dinhalynewui_WriteNumbersOrder')}</h1>
                                    </div>
                                </div>



                            </div>

                            <div className="w-full flex justify-start cursor-pointer z-40" onClick={(e) => navigate('/DinTestV10Antiphasic')} >
                                <div className=' bg-purple-700 text-white px-6 py-4 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-xl' >
                                    <button> {t('dinv6_full_modal_continue_button')}</button>
                                </div>
                            </div>

                        </div>


                    </div>

                </div>
            </div>
        </div >
    );
};

export default DINHalyPageNewUiAntiphasic;