import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AudiogramChart from './AudiogramChart';

// Define mapping objects
    const validityTextMap = {
    0: 'valid data',
    1: 'test was not completed patient or user interrupted',
    2: 'same level was not heard 2 out of 3 presentations',
    };

    const uncertainTextMap = {
    0: 'Kyllä',
    1: 'Ei',
    2: 'Ei 2/3',
    };

    // Function to normalize ear values
    const normalizeEarValue = (earValue) => {
    if (earValue === '1' || earValue.toLowerCase() === 'right') return 'right';
    if (earValue === '2' || earValue.toLowerCase() === 'left') return 'left';
    return null;
    };

    // Function to map ear values to labels
    const getEarLabel = (earValue) => {
    if (earValue === 'right') return 'Oikea';
    if (earValue === 'left') return 'Vasen';
    return 'Tuntematon'; // 'Unknown' in Finnish
    };

    const AudiometryResult = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [expandedSections, setExpandedSections] = useState({});
    const [expandedFullResults, setExpandedFullResults] = useState({});
    const selectedPatient = location.state?.patient;
    console.log('selectedPatient:', selectedPatient);

    if (!selectedPatient) {
        return <div>Potilasta ei valittu</div>;
    }

    const audiograms = selectedPatient.audiogram;

    if (!audiograms || audiograms.length === 0) {
        return <div>Ei dataa saatavilla</div>;
    }

    const toggleExpandSection = (index) => {
        setExpandedSections((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
        }));
    };

        const toggleExpandFullResults = (index) => {
            setExpandedFullResults((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
            }));
        };

    return (
        <div className="min-h-screen flex flex-col lg:px-32 px-5 pt-0 lg:pt-0">
            <div className="flex flex-col items-center lg:flex-row justify-between pt-16"></div>
            <div className="p-4">
            <button
                onClick={() => navigate('/Dashboard')}
                className="bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md px-6 py-3 mb-4"
            >
                Takaisin
            </button>
            <h2 className="text-2xl font-bold mb-4">
                Kuulokynnystulokset {selectedPatient.firstName} {selectedPatient.lastName}
            </h2>

            {/* Iterate over all audiogram results */}
            <div className="space-y-6">
                {audiograms.slice().reverse().map((audiogram, index) => {
                const audiogramNumber = audiograms.length - index; // For numbering
                const testDate = new Date(audiogram.testStartTimestamp).toLocaleDateString();

                // Normalize ear values in the results
                const normalizedResults = audiogram.results.map((result) => ({
                    ...result,
                    ear: normalizeEarValue(result.ear),
                }));

                // Split the results into right and left ear
                const rightEarResults = normalizedResults.filter(
                    (result) => result.ear === 'right'
                );
                const leftEarResults = normalizedResults.filter(
                    (result) => result.ear === 'left'
                );

                // Sort each array by frequency
                const sortByFrequency = (a, b) => a.Hz - b.Hz;

                rightEarResults.sort(sortByFrequency);
                leftEarResults.sort(sortByFrequency);

                return (
                    <div
                    key={audiogram._id}
                    className="bg-white shadow-lg rounded-xl hover:shadow-2xl transition-shadow p-8"
                    >
                    <div className="flex items-center justify-between">
                        <h3 className="text-xl font-semibold">
                        Kuulokynnys  {audiogramNumber} - {testDate}
                        </h3>
                        <button
                        onClick={() => toggleExpandSection(index)}
                        className="flex items-center text-blue-600 font-semibold"
                        >
                        {expandedSections[index] ? 'Piilota tiedot' : 'Näytä tiedot'}
                        <svg
                            className={`w-5 h-5 ml-2 transition-transform ${
                            expandedSections[index] ? 'rotate-180' : 'rotate-0'
                            }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                        </button>
                    </div>

                    {/* Display audiogram details when expanded */}
                    {expandedSections[index] && (
                        <div className="mt-4">
                        <p>
                            <strong>Testi suoritettu:</strong>{' '}
                            {new Date(audiogram.testStartTimestamp).toLocaleString()}
                        </p>
                        <p>
                            <strong>Testin kesto:</strong> {audiogram.testDuration}
                        </p>
                        <p>
                            <strong>Testi keskeytettiin:</strong>{' '}
                            {audiogram.interrupted ? 'Kyllä' : 'Ei'}
                        </p>
                    {/*     <p>
                        <strong>Testin tulos tarkka oikealla:</strong>{' '}
                            {audiogram.accuracyRight}
                        </p>
                        <p>
                            <strong>Testin tulos tarkka vasemmalla:</strong>{' '}
                            {audiogram.accuracyLeft}
                        </p> */}
                        <p>
                            <strong>Pätevä tulos:</strong>{' '}
                            {validityTextMap[audiogram.validity]}
                        </p>

                        {/* Results Table and Chart */}
                        <div className="flex flex-wrap mt-6">
                            {/* Results Table */}
                            <div className="w-full md:w-1/3 pr-4">
                            <h4 className="text-xl text-center font-semibold">Taulukoitu tulos:</h4>
                            <table className="w-full mt-2 border-collapse border border-gray-300">
                                <thead>
                                <tr>
                                    <th className="border border-gray-300 p-2">Taajuus (Hz)</th>
                                    <th className="border border-gray-300 p-2">Taso (dB)</th>
                                    <th className="border border-gray-300 p-2">Korva</th>
                                    <th className="border border-gray-300 p-2">Selkeä tulos</th>
                                </tr>
                                </thead>
                                <tbody>
                                {/* Right Ear Results */}
                                {rightEarResults.map((result, idx) => (
                                    <tr key={`right-${idx}`}>
                                    <td className="border text-sm border-gray-300 p-2">{result.Hz}</td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {result.dbLevel || result.uncertain}
                                    </td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {getEarLabel(result.ear)}
                                    </td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {uncertainTextMap[result.uncertain]}
                                    </td>
                                    </tr>
                                ))}
                                {/* Left Ear Results */}
                                {leftEarResults.map((result, idx) => (
                                    <tr key={`left-${idx}`}>
                                    <td className="border text-sm border-gray-300 p-2">{result.Hz}</td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {result.dbLevel || result.uncertain}
                                    </td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {getEarLabel(result.ear)}
                                    </td>
                                    <td className="border text-sm border-gray-300 p-2">
                                        {uncertainTextMap[result.uncertain]}
                                    </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </div>

                            {/* Audiogram Chart */}
                            <div className="w-full md:w-2/3">
                            <h4 className="text-xl text-center font-semibold">Kuulokynnys:</h4>
                            <AudiogramChart audiogram={audiogram} />
                            </div>

                                
                {/* Display audiogram details when expanded */}
                            {expandedSections[index] && (
                            <div className="mt-4">
                                {/* ... existing details and results table ... */}



                                {/* Toggle Button for Full Results */}
                                <div className="mt-6">
                                <button
                                    onClick={() => toggleExpandFullResults(index)}
                                    className="flex items-center text-blue-600 font-semibold"
                                >
                                    {expandedFullResults[index] ? 'Piilota täydelliset tulokset' : 'Näytä täydelliset tulokset'}
                                    <svg
                                    className={`w-5 h-5 ml-2 transition-transform ${
                                        expandedFullResults[index] ? 'rotate-180' : 'rotate-0'
                                    }`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                    ></path>
                                    </svg>
                                </button>

                                {/* Full Results Table */}
                                {expandedFullResults[index] && (
                                    <div className="mt-4 overflow-auto">
                                    <h4 className="text-lg font-semibold">Täydelliset tulokset:</h4>
                                    <table className="w-full mt-2 border-collapse border border-gray-300 text-sm">
                                        <thead>
                                        <tr>
                                            <th className="border border-gray-300 p-2">Taajuus (Hz)</th>
                                            <th className="border border-gray-300 p-2">Taso (dB)</th>
                                            <th className="border border-gray-300 p-2">Korva</th>
                                            <th className="border border-gray-300 p-2">Vaihe</th>
                                            <th className="border border-gray-300 p-2">Vastausaika (s)</th>
                                            <th className="border border-gray-300 p-2">Vastaus</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {audiogram.fullResults.map((result, idx) => (
                                            <tr key={idx}>
                                            <td className="border border-gray-300 p-2">{result.Hz}</td>
                                            <td className="border border-gray-300 p-2">{result.dbLevel}</td>
                                            <td className="border border-gray-300 p-2">
                                                {getEarLabel(normalizeEarValue(result.ear))}
                                            </td>
                                            <td className="border border-gray-300 p-2">{result.phase}</td>
                                            <td className="border border-gray-300 p-2">{result.responseDuration}</td>
                                            <td className="border border-gray-300 p-2">
                                                {result.heard ? 'Kyllä' : 'Ei'}
                                            </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                )}
                                </div>
                            </div>
                            )}
                        
                            
                        </div>
                        </div>
                    )}
                    </div>
                );
                })}
            </div>
            </div>
        </div>
    );
};

export default AudiometryResult;


