import React from 'react';

const ButtonUtil = (
    {
        className = `text-sm flex flex-nowrap gap-x-1 bg-white px-6 py-4 rounded-md transition-colors duration-300 ease-in-out hover:bg-blue-600 hover:text-white-500 text-[#344054] cursor-pointer`,
        handleClick, icon, text = 'Button Text' }) => {

    return (
        <div className={`${className}`} onClick={handleClick}>
            <img src={icon} className='size-4' alt='' />
            <button >{text}</button>
        </div>
    );
};

export default ButtonUtil;