// defaultSoundTests.js

import HeadPhoneIcon from '../../../assets/Icons/headphones.png';
import SpeakersIcon from '../../../assets/Icons/speaker.png';
import Edit from '../../../assets/Icons/edit.png';
/* Fall back updated for language support */
/* Updated to use the /DINHalyPageNewUi and the language support */
export const defaultSoundTests = [
  { name: 'soundSlice_noiseSpeechTest', duration: '20 min', navigate: '/DINHalyPageNewUiSpeakers', completed: false, included: true, icon: SpeakersIcon },
  { name: 'soundSlice_noiseSpeechTest2', duration: '20 min', navigate: '/DINHalyPageNewUi', completed: false, included: false, icon: HeadPhoneIcon },
  { name: 'soundSlice_noiseSpeechTestWithThree', duration: '20 min', navigate: '/DINHalyPageNewUi3Speakers', completed: false, included: false, icon: HeadPhoneIcon },
  { name: 'soundSlice_hearingSurvey', duration: '5 min', navigate: '/KuuloKyselyLandingPage', completed: false, included: true, icon: Edit },
  { name: 'soundSlice_headsetFitting', duration: '2 min', navigate: '/Kuulokkeiden', completed: false, included: false, icon: HeadPhoneIcon },
  { name: 'soundSlice_hearingThreshold', duration: '15 min', navigate: '/AudiogramLandingPage2', completed: false, included: true, icon: HeadPhoneIcon },
  { name: 'soundSlice_sliderThreshold', duration: '5 min', navigate: '/SliderAudiogram2', completed: false, included: false, icon: HeadPhoneIcon },
  { name: 'soundSlice_antiphaseSpeechTest', duration: '20 min', navigate: '/DINHalyPageNewUiAntiphasic', completed: false, included: false, icon: HeadPhoneIcon }
  // Add any additional default tests here
];