import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ResultsPageHelp from "../../Modals/AudiogramResultsPageModal"; // Modal for help
import { useTranslation } from "react-i18next";
import { InfoIcon } from "../../../assets/Icons";
import AudiogramChart from "../../Results/AudiogramChart";


const AudiogramResults = () => {
  // For language support
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [audiogramData, setAudiogramData] = useState(null);
    const [loading, setLoading] = useState(true); // Add a loading state
    const [error, setError] = useState(null); // Add an error state
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Tracks if the help modal is open

    // Fetch data from localStorage when the component mounts
    useEffect(() => {
        try {
        const data = localStorage.getItem("audiogramData");
        if (data) {
            const parsedData = JSON.parse(data);
            setAudiogramData(parsedData);
            console.log("Retrieved audiogramData from localStorage:", parsedData);
        } else {
            console.warn("No audiogram data found in localStorage.");
            setError("No audiogram data found.");
        }
        } catch (error) {
        console.error("Failed to read data from localStorage:", error);
        setError("Failed to read data from localStorage.");
        } finally {
        setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (audiogramData) {
        console.log("Audiogram Data:", audiogramData);
        }
    }, [audiogramData]);

    // Three modals state handling - 1) HelpModal 2) PauseModal 3) AbortModal
    const handleHelpClick = () => {
        setIsHelpModalOpen(true); // Show the help modal
    };

    const handleCloseHelpModal = () => {
        setIsHelpModalOpen(false); // Hide the help modal
    };

    const handleResultsClick = () => {
        // Navigate to the results page
        navigate("/TestLandingPage"); // Adjust the path as needed
    };

    // Conditional rendering based on loading and error states
    if (loading) {
        return <div>Loading audiogram data...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="min-h-screen box-border bg-gray-100 overflow-y-auto relative">
        {/* Top Bar */}
        <div className="hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-0 relative z-50 ">
            <div className="rounded-xl absolute w-full left-[0.5%] top-[8px]">
            <div className="text-sm gap-x-2 flex py-2 justify-between px-4 bg-gray-100 text-black rounded-xl w-full">
                <div className="text-md font-bold flex gap-x-2 top-2 ">
                <button
                    className="flex justify-center items-center bg-white text-gray-700 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                            2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                    onClick={handleHelpClick}
                >
                    <img src={InfoIcon} alt="ℹ️" className="w-5 h-5 " />
                    {t("audiogramResults_help")}
                </button>
                </div>
                <button
                className="flex justify-center items-center bg-purple-700 text-white border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-purple-900 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide mr-4"
                onClick={handleResultsClick}
                >
                {t("audiogramResults_continue")}
                </button>
            </div>
            </div>
        </div>

        {/* Audiogram Chart */}
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="flex flex-col items-center mb-12">
            <div className="text-gray-700 text-5xl font-bold font-3xl font-sprat uppercase tracking-widest text-center">
                {t("kuulokynnystestin TULOS")}
            </div>

                {/*
            <div className="flex flex-col items-center mb-12">
            <div className="text-gray-700 text-2xl font-bold font-3xl font-red-hat-display tracking-widest text-center">
                {t("Kuulokynnystesti")}
            </div>
            </div>*/}

            <br />
            <div className="relative flex items-center justify-center bg-white rounded-[40px] shadow-lg p-6 md:p-10 z-20">
                <div className="flex min-w-[750px]  min-h-[500px] items-center gap-2">
                {audiogramData && audiogramData.results ? (
                    <AudiogramChart audiogram={audiogramData} />
                ) : (
                    <p>No audiogram data available.</p>
                )}
                </div>
            </div>
            </div>

        {/* Disclaimer Text */}
        <p className=" flex justify-center items-center text-sm md:text-1xl lg:text-[20px] text-red-700 mt-4">
            {t('dinResultPage_disclaimer')}
        </p>
        </div>

        

        {/* Help Modal */}
        {isHelpModalOpen && <ResultsPageHelp onClose={handleCloseHelpModal} />}
        </div>
    );
};

export default AudiogramResults;
