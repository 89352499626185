import { createSlice } from '@reduxjs/toolkit';

export const lessThan70Slice = createSlice({
    name: 'lessThan70',
    initialState: {
        lessThan70Exit: Boolean(JSON.parse(localStorage.getItem('lessThan70State'))?.lessThan70Exit) || false,
        lessThan70ForEars: JSON.parse(localStorage.getItem('lessThan70ForEars')) || [],
    },
    reducers: {
        setLessThan70Exit: (state, action) => {
            state.lessThan70Exit = Boolean(action.payload);
            // Save the updated lessThan70Exit independently
            localStorage.setItem(
                'lessThan70State',
                JSON.stringify({ lessThan70Exit: state.lessThan70Exit })
            );
        },
        setLessThan70ForEars: (state, action) => {
            state.lessThan70ForEars = action.payload;
            // Save lessThan70ForEars independently
            localStorage.setItem('lessThan70ForEars', JSON.stringify(state.lessThan70ForEars));
        },
    },
});

export const { setLessThan70Exit, setLessThan70ForEars } = lessThan70Slice.actions;
// export default lessThan70Slice.reducer;
