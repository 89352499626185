import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Help_Icon, HomeIcon, Lock_Icon, Logout_Icon } from '../assets/Icons';
import Modal from '../Components/Modals/Modal';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployee, setEmployeeToken, setToken, setUser } from '../Slices/authSlice';
import { updateSoundTestStatusALL } from '../Functions/upDateSoundTestStatus';
import { setSoundTest } from '../Slices/soundSilce';
import { t } from 'i18next';
import LanguageSwitcher from '../Components/LanguageSwitcher/LanguageSwitcher';
import LanguageSwitcherButton from '../Components/LanguageSwitcher/LanguageSwicherButton';

// There is still clean up left to do 
/* Reusable Navbar Component  */
const GlobalNavbar = () => {
    /* States */
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipHome, setShowTooltipHome] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();

    const soundTest = useSelector((state) => state.soundState.soundTest); // Access the soundTest array from Redux
    

    const [loginInformationStaff, setLoginInformationStaff] = useState({
        userName: '',
        password: ''
    });

    /* Log Out */
    const handle_Logout = (e) => {
        if (localStorage.getItem('user'))
            localStorage.removeItem('user');
        if (localStorage.getItem('token'))
            localStorage.removeItem('token');
        try {
            for (let i = 0; i < soundTest.length; i++)
                // Update all soundTest statuses to false at once
                updateSoundTestStatusALL(soundTest, dispatch, setSoundTest, false);

        } catch (error) {
            console.log(`error User logout error`, error);
        }
        return navigate('/LandingPage');
    };




    /* Close the modal */
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    /* Not needed any more */
    const handleLogin = async () => {
        // Handle the staff login logic here
        try {
            const { userName, password } = loginInformationStaff;
            if (!userName || !password)
                toast.error('All fields requried', { position: 'top-right' });
            console.log({ userName, password });
            const { data } = await axios.post(process.env.REACT_APP_BACKEND_URL + '/employee/login',
                {
                    userName: userName,
                    password: password
                }
            );
            console.log(data);
            if (data.status === false)
                throw new Error(data.message);

            toast.success(data.message);
            /* Set global states */
            dispatch(setEmployee(data.employee));
            dispatch(setEmployeeToken(data.token));
            dispatch(setUser(data.employee));
            dispatch(setToken(data.token));


            /* Add Employee data to local storage */
            localStorage.setItem('employee', JSON.stringify(data.employee));
            localStorage.setItem('user', JSON.stringify(data.employee));
            localStorage.setItem('userToken', JSON.stringify(data.token));
            localStorage.setItem('employeeToken', JSON.stringify(data.token));
            localStorage.setItem('token', JSON.stringify(data.token));

        } catch (error) {
            setIsModalVisible(false); // Close the modal after login
            toast.error(error.message);

        }
    };


    return (
        <nav className='w-full cursor-pointer'>
            <div className='p-4 flex items-center justify-between'>
                <div className='flex gap-x-5'>
                    {/* Logout Button */}
                    <div className="flex gap-x-1 bg-white px-6 py-4 rounded-xl justify-center items-center box-border transition-colors duration-300 ease-in-out hover:bg-gray-400 font-bold font-['Red Hat Display'] tracking-wide leading-loose " onClick={handle_Logout}>
                        <img src={Logout_Icon} className='size-4' alt='←' />
                        <button >{t('userDetails_logout')}</button>
                    </div>
                    {/* Home Button */}
                    <div
                        className='relative ml-2 flex items-center justify-center'
                        onMouseEnter={() => setShowTooltipHome(true)}
                        onMouseLeave={() => setShowTooltipHome(false)}
                        onClick={(e) => navigate('/LandingPage')}
                    >
                        <img src={HomeIcon} className='size-8 transition-colors duration-300 ease-in-out hover:bg-gray-400 rounded-lg' alt='Home' />
                        {showTooltipHome && (
                            <div className='absolute mb-0 top-[101%] left-[70%] transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-md py-2 px-2 opacity-100 transition-opacity duration-300 ease-in-out'>
                                {t('globalNavbar_hometooltip')}
                            </div>
                        )}
                    </div>

                    {/* Assistance Button */}
                    <div
                        className='relative flex justify-center items-center'
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        <img src={Help_Icon} className='size-16 transition-colors duration-300 ease-in-out hover:bg-gray-400 rounded-lg' alt='Help' />
                        {showTooltip && (
                            <div className='absolute mb-0 top-[101%] left-[70%] transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-md py-2 px-2 opacity-100 transition-opacity duration-300 ease-in-out'>
                                {t('globalNavbar_helptooltip')}

                            </div>
                        )}
                    </div>

                    

                    {/* Language Switcher */}

                </div>
 {/*
                 <LanguageSwitcher className="flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 " />
                Staff Login Image */}
                {/* <div
                    className='relative'
                    onMouseEnter={() => setShowTooltipStaff(true)}
                    onMouseLeave={() => setShowTooltipStaff(false)}
                    onClick={handleLoginStaffClick} // Open the modal when clicked
                >
                    <img src={Key_Icon} className='size-10 transition-colors duration-300 ease-in-out hover:bg-gray-400 rounded-lg' />
                    {showTooltipStaff && (
                        <div className='absolute mb-0 top-[102%] left-[50%] transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-md py-2 px-2 opacity-100 transition-opacity duration-300 ease-in-out'>
                            Staff Login
                        </div>
                    )}
                </div> */}
            </div>

            {/* Modal for Staff Login */}
            {isModalVisible && (
                <Modal className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg w-96'>
                        <div className='flex items-center py-1 gap-x-1'>
                            <div>
                                <img src={Lock_Icon} alt='Lock Icon' className='size-16' />
                            </div>
                            <div>
                                <h2 className='text-lg font-semibold '>Henkilökunnan sisäänkirjaus</h2>
                            </div>
                        </div>
                        <input
                            type='text'
                            placeholder='Username'
                            value={loginInformationStaff.userName}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, userName: e.target.value })}
                            className='w-full mb-4 px-3 py-2 border rounded-md'
                        />
                        <input
                            type='password'
                            placeholder='Password'
                            value={loginInformationStaff.password}
                            onChange={(e) => setLoginInformationStaff({ ...loginInformationStaff, password: e.target.value })}
                            className='w-full mb-4 px-3 py-2 border rounded-md'
                        />
                        <div className='flex justify-end'>
                            <button onClick={handleCancel} className='px-4 py-2 bg-gray-400 text-white rounded-md mr-2'>
                                Peruuta
                            </button>
                            <button onClick={handleLogin} className='px-4 py-2 bg-blue-500 text-white rounded-md'>
                                Kirjaudu
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            <ToastContainer />
        </nav>
    );
};

export default GlobalNavbar;
