import React from 'react';
import { useTranslation } from 'react-i18next';

function QuestionTitle({ text }) {
    const { t } = useTranslation();
    
    return (
        <header className="flex flex-col  font-bold text-center text-slate-700 w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
                <h1 className="text-sm  uppercase tracking-[4px] max-md:max-w-full font-bold text-gray-700">
                {t('PromLandingpage_questionnnaire')}
                </h1>
                <h2 className="flex justify-center py-4 text-gray-900 font-bold text-2xl md:text-lg lg:text-6xl font-['Sprat'] leading-10 overflow-x-auto">
                    {text}
                </h2>
            </div>
        </header>
    );
}

export default QuestionTitle;
