import React from 'react';
import DinResultPageForPrematureExit from './DinResultPageForPrematureExit';

const DinResultPageFinalForSoundField = () => {

    return (
        <div>
            <DinResultPageForPrematureExit l50={-8.5} />
        </div>
    );
};

export default DinResultPageFinalForSoundField;