import React from "react";
import { Circle } from "../../../assets/Icons";

export const Frame200 = () => {
    return (
        <div className="absolute inset-0 z-10 overflow-hidden">
        <div className="relative w-full h-full">
            {/* Circles */}
            <div className="absolute w-[336px] h-[336px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-90 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[480px] h-[480px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-80 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[624px] h-[624px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-70 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[768px] h-[768px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-60 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[912px] h-[912px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-50 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1056px] h-[1056px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-40 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1200px] h-[1200px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-30 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1344px] h-[1344px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-20 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1488px] h-[1488px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-10 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"></div>

            {/* Center Circle Image */}
            <img
            className="absolute w-48 h-48 top-1/2 left-1/4 transform -translate-x-1/2 -translate-y-1/2"
            alt="Circle"
            src={Circle}
            />
        </div>
        </div>
    );
};

export const Frame201 = () => {
    return (
        <div className="absolute inset-0 z-10 overflow-hidden">
        <div className="relative w-full h-full">
            {/* Circles */}
            <div className="absolute w-[336px] h-[336px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-90 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[480px] h-[480px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-80 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[624px] h-[624px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-70 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[768px] h-[768px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-60 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[912px] h-[912px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-50 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1056px] h-[1056px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-40 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1200px] h-[1200px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-30 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1344px] h-[1344px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-20 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute w-[1488px] h-[1488px] rounded-full border-[1.5px] border-solid border-purple-300 opacity-10 top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>

            {/* Center Circle Image */}
            <img
            className="absolute w-48 h-48 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            alt="Circle"
            src={Circle}
            />
        </div>
        </div>
    );
};
