import React, { useEffect, useState } from 'react';
import { InfoIcon, ArrowLeft } from '../../../assets/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import SegmentedColorBar3Segments from './SegmentedColorBar3';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    curveFitLogistic,
    getAverageAnswers,
    getUniqueX,
    } from '../../../Components/Modules/LogisticeModule/LogisticHelper' 
    

    const DinResultPageHeadphones = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

     // Access the passed data
    const dinTestData = location.state?.dinTestData;

    const selectedPatient = useSelector(
        (state) => state.selectedPatientState.selectedPatient
    );

    const [l50LeftArray, setL50LeftArray] = useState([]);
    const [l50RightArray, setL50RightArray] = useState([]);
    const [l50BothArray, setL50BothArray] = useState([]);  //Used for stereo headphones setup if testing normal vs antiphasic test
    const [isLeftAvailable, setIsLeftAvailable] = useState(false);
    const [isRightAvailable, setIsRightAvailable] = useState(false);
    const [isBothAvailable, setIsBothAvailable] = useState(false);


            useEffect(() => {
                if (dinTestData && dinTestData.questionHistory && dinTestData.questionHistory.length > 0) {
                let l50LeftArrayTemp = [];
                let l50RightArrayTemp = [];
                let l50BothArrayTemp = [];
            
                dinTestData.questionHistory.forEach((test) => {
                    const testName = test.name.trim().toLowerCase();
            
                    if (testName.includes('left') || testName.includes('vasen')) {
                    // Left ear test
                    const L50 = calculateL50ForTest(test);
                    if (L50 !== null) {
                        l50LeftArrayTemp.push(L50);
                        setIsLeftAvailable(true);
                    }
                    } else if (testName.includes('right') || testName.includes('oikea')) {
                    // Right ear test
                    const L50 = calculateL50ForTest(test);
                    if (L50 !== null) {
                        l50RightArrayTemp.push(L50);
                        setIsRightAvailable(true);
                    }
                    }
                    else if (testName.includes('both') || testName.includes('stereo')) {
                        // Right ear test
                        const L50 = calculateL50ForTest(test);
                        if (L50 !== null) {
                            l50BothArrayTemp.push(L50);
                            setIsBothAvailable(true);
                        }
                        }
                });
            

                setL50LeftArray(l50LeftArrayTemp);
                setL50RightArray(l50RightArrayTemp);
                setL50BothArray(l50BothArrayTemp);
                } else {
                console.error('No test data available');
                navigate('/TestLandingPage');
                }
            }, [dinTestData, navigate]);


    const calculateL50ForTest = (test) => {
        if (!test.name.toLowerCase().includes('muuttumaton')) {
        const xData = test.value.map((question) => question.speechDB - 65);
        const yData = test.value.map((question) => (question.correct ? 1 : 0));

        const uniqueX = getUniqueX(xData);
        const averageY = getAverageAnswers(xData, yData, uniqueX);

        if (uniqueX.length >= 3) {
            try {
            const { L50 } = curveFitLogistic(uniqueX, averageY);
            return L50;
            } catch (error) {
            console.error('Error in logistic regression:', error);
            return null;
            }
        } else {
            console.warn(
            `Not enough data points for logistic regression in test "${test.name}".`
            );
            return null;
        }
        }
        return null;
    };

        const getSegmentClassForEar = (l50Array) => {
            if (!l50Array || l50Array.length === 0) return '';
        // Option 1 use the highhest value
        //const l50 = Math.min(...l50Array); // Use the worst (highest) L50 value
        // Option 2 use the last value
        const l50 = l50Array[l50Array.length - 1]; // Use the last value in the array
            return getSegmentClass(l50);
        };
        
        const getSegmentClass = (l50) => {
            if (l50 === null) return '';
            switch (true) {
            case l50 >= -8:
                return 'highlight-segment-2'; // Second Segment
            case l50 < -8 && l50 >= -10:
                return 'highlight-segment-3'; // Third Segment
            default:
                return 'highlight-segment-5'; // Final Segment
            }
        };
        
        const getMessageKeysForEar = (l50Array) => {
            if (!l50Array || l50Array.length === 0) return null;
        // Option 1 use the highhest value
        //const l50 = Math.min(...l50Array); // Use the worst (highest) L50 value
        // Option 2 use the last value
        const l50 = l50Array[l50Array.length - 1]; // Use the last value in the array
            return getMessageKeys(l50);
        };

        const getMessageKeys = (l50) => {
            if (l50 === null) return null;
            switch (true) {
            case l50 >= -8:
                return {
                first: 'dinResultPage3_likely_hearing_loss_first',
                second: 'dinResultPage3_likely_hearing_loss_second',
                };
            case l50 < -8 && l50 >= -10:
                return {
                first: 'dinResultPage3_possible_hearing_loss_first',
                second: 'dinResultPage3_possible_hearing_loss_second',
                };
            default:
                return {
                first: 'dinResultPage3_normal_hearing_first',
                second: 'dinResultPage3_normal_hearing_second',
                };
            }
        };

        // Determine segment classes and messages for each ear
        const segmentClassL = getSegmentClassForEar(l50LeftArray);
        const segmentClassR = getSegmentClassForEar(l50RightArray);
        const segmentClassBoth = getSegmentClassForEar(l50BothArray);

        const leftEarMessageKeys = getMessageKeysForEar(l50LeftArray);
        const rightEarMessageKeys = getMessageKeysForEar(l50RightArray);
        const BothEarMessageKeys = getMessageKeysForEar(l50BothArray);


        const handleNext = () => {
            navigate('/TestLandingPage');
        };



return (
    <div className="h-screen bg-gray-100">
        <div className="h-screen flex flex-col items-center overflow-auto">
            {/* Navigation Center */}
            <div className="text-md font-bold flex justify-between w-full p-4">
            {/* Left Side Buttons */}
            <div className="flex gap-x-4">

                {/* Help Button */}
                <div
                    className="flex justify-center items-center bg-white text-gray-700 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                    onClick={() => {}}
                    >
                    <img src={InfoIcon} alt="ℹ️" className="w-6 h-6" />
                    <button>{t('dinResultPage_navigation_help')}</button>
                </div>
            </div>
    
            {/* Right Side Button */}
            <button
                className="flex justify-center items-center bg-purple-700 text-white border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-purple-900 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide mr-4"
                onClick={handleNext}
            >
                {t('audiogramResults_continue')}
            </button>
        </div>
        

   {/* Contents */}
    <div className="flex flex-col py-10 gap-y-10">
            <div className="flex flex-col gap-y-6">
            {/* Header */}
            <p className="text-sm md:text-3xl lg:text-4xl font-bold px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
                {t('dinResultPage_header')}
            </p>
            <div className="px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
                {(!isLeftAvailable && !isRightAvailable && !isBothAvailable) ? (
                <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900">
                    {t('dinResultPage_no_data')}
                </p>
                ) : (
                <>
                    {isLeftAvailable && leftEarMessageKeys && (
                    <>
                        <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900">
                        <span className="">
                            {t('dinResultPage_results_summary_part1')} 
                        </span>
                        <span className="font-bold">
                            {t('dinResultPage_left_ear_bold')} 
                        </span>
                            <span className="text-sm md:text-1xl lg:text-[20px] text-gray-900 lowercase">
                            {t(leftEarMessageKeys.first)} {t(leftEarMessageKeys.second)} {t('dinResultPage_and')} 
                        </span>

                        {/* Display all L50 values separated by commas */}
                        {/* {l50LeftArray.map((l50, index) => (
                            <span key={index}> {l50.toFixed(2)}{index < l50LeftArray.length - 1 ? ',' : ''}</span>
                        ))}  */}
                        </p>
                    </>
                    )}
                    {isRightAvailable && rightEarMessageKeys && (
                    <>
                        <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900">
                        <span className="font-bold">
                            {t('dinResultPage_right_ear_bold')}
                        </span>
                        <span className="text-sm md:text-1xl lg:text-[20px] text-gray-900 lowercase">
                        {t(rightEarMessageKeys.first)} {t(rightEarMessageKeys.second)}
                        </span>

                        {/* Display all L50 values separated by commas */}
                        {/* {l50RightArray.map((l50, index) => (
                            <span key={index}> {l50.toFixed(2)}{index < l50RightArray.length - 1 ? ',' : ''}</span>
                        ))} */}

                        </p>
                    </>
                    )}
                </>
                )}
                {isBothAvailable && BothEarMessageKeys && (
                    <>
                        <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900">
                        <span className="">
                            {t('dinResultPage_results_summary_part1')} 
                        </span>
                        <span className="text-sm md:text-1xl lg:text-[20px] text-gray-900 lowercase font-bold">
                        {t(BothEarMessageKeys.first)} {t(BothEarMessageKeys.second)}
                        </span>
                        <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900">
                        </p>
                        <span className="">
                            {t('dinResultPage3_stereo')}
                        </span>

                        {/* Display all L50 values separated by commas */}
                        {/* {l50RightArray.map((l50, index) => (
                            <span key={index}> {l50.toFixed(2)}{index < l50RightArray.length - 1 ? ',' : ''}</span>
                        ))} */}

                        </p>
                    </>
                    )}
            </div>
            </div>

            <div className="flex gap-x-4 flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-y-4 justify-center items-center">

            {/* Left Ear Result */}
            {isLeftAvailable && (
                <div className="p-4">
                <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col">
                    <div className="relative flex justify-center items-center w-[360px] h-[360px] flex-col gap-y-4">
                    <div>
                        <p className="text-gray-900">
                        {t('dinResultPage_test_result')}
                        </p>
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                        {t('dinResultPage_left_ear_result')}
                        </p>
                        {/* Display all L50 values separated by commas */}
                        {l50LeftArray.length > 0 ? (
                        <p className="text-sm md:text-l lg:text-l ">
                            {l50LeftArray.map((l50, index) => (
                            <span key={index}>
                                {l50.toFixed(2)}{index < l50LeftArray.length - 1 ? ', ' : ''}
                            </span>
                            ))}
                        </p>
                        ) : (
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                            {t('dinResultPage_no_data')}
                        </p>
                        )}
                    </div>
                    {/* Segmented Color Bar */}
                    <div className="w-full flex justify-center">
                        <div className="flex ml-20" />
                        <div className = "relative ml-10" />
                        <SegmentedColorBar3Segments segmentClass={segmentClassL} />
                        
                        {/* Text Labels for Each Segment */}
                        <div className="space-y-4 ml-6">
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_second')}
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            )}

            {/* Right Ear Result */}
            {isRightAvailable && (
                <div className="p-4">
                <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col">
                    <div className="relative flex justify-center items-center w-[360px] h-[360px] flex-col gap-y-4">
                    <div>
                        <p className="text-gray-900">
                        {t('dinResultPage_test_result')}
                        </p>
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                        {t('dinResultPage_right_ear_result')}
                        </p>
                        {/* Display all L50 values separated by commas */}
                        {l50RightArray.length > 0 ? (
                        <p className="text-sm md:text-l lg:text-l text-gray-700">
                            {l50RightArray.map((l50, index) => (
                            <span key={index}>
                                {l50.toFixed(2)}{index < l50RightArray.length - 1 ? ', ' : ''}
                            </span>
                            ))}
                        </p>
                        ) : (
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                            {t('dinResultPage_no_data')}
                        </p>
                        )}
                    </div>
                    {/* Segmented Color Bar */}
                    <div className="w-full flex justify-center">
                        <div className="flex ml-20" />
                        <div className = "relative ml-10" />
                        <SegmentedColorBar3Segments segmentClass={segmentClassR} />
                        {/* Text Labels for Each Segment */}
                        <div className="space-y-4 ml-6">
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_second')}
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                
            )}

                {/* Both Results available - meaning stereo */}
                {isBothAvailable && (
                <div className="p-4">
                <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col">
                    <div className="relative flex justify-center items-center w-[360px] h-[360px] flex-col gap-y-4">
                    <div>
                        <p className="text-gray-900">
                        {t('dinResultPage_test_result')}
                        </p>
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                        {t('dinResultPage3_stereo_short')}
                        </p>
                        {/* Display all L50 values separated by commas */}
                        {l50BothArray.length > 0 ? (
                        <p className="text-sm md:text-l lg:text-l text-gray-700">
                            {l50BothArray.map((l50, index) => (
                            <span key={index}>
                                {l50.toFixed(2)}{index < l50BothArray.length - 1 ? ', ' : ''}
                            </span>
                            ))}
                        </p>
                        ) : (
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                            {t('dinResultPage_no_data')}
                        </p>
                        )}
                    </div>
                    {/* Segmented Color Bar */}
                    <div className="w-full flex justify-center">
                        <div className="flex ml-20" />
                        <div className = "relative ml-10" />
                        <SegmentedColorBar3Segments segmentClass={segmentClassBoth} />
                        {/* Text Labels for Each Segment */}
                        <div className="space-y-4 ml-6">
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_likely_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_possible_hearing_loss_second')}
                            </span>
                        </div>
                        <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                            {t('dinResultPage3_normal_hearing_second')}
                            </span>
                        </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>
                
            )}
            </div>
        {/* Disclaimer Text */}
        <p className=" flex justify-center items-center text-sm md:text-1xl lg:text-[20px] text-red-700 mt-4">
            {t('dinResultPage_disclaimer')}
        </p>
        </div>
        </div>
    </div>
);
};

export default DinResultPageHeadphones;
