import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../Shared/DashboardWrapper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import isValidJSON from "../../Functions/IsValidJSON";
import { setSelectedPatient } from "../../Slices/selectedPatientSlice";
import { useTranslation } from "react-i18next";
import LanguageSwitcherButton from "../LanguageSwitcher/LanguageSwicherButton";

const Dashboard = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const tokenFromStorage = localStorage.getItem('token');
  const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);
  const [patients, setPatients] = useState([]);
  const dispatch = useDispatch();

  const fetchUsers = async (invoke = false) => {
    try {
      if (invoke === true && search === '')
        return;
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/patient/searchUsers?search=${search}&page=${page}&limit=${limit}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const { data } = response;
      if (data.status === true)
        setPatients(data.users);
    } catch (error) {
      console.log(`[+] Error FetchUsers:`, error.message);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page]);

  const handleViewDinResult = (patient) => {
    dispatch(setSelectedPatient(patient));
    navigate('/DinResult');
  };

  const handleViewAudiometryResult = (patient) => {
    console.log('Selected patient:', patient);
    navigate('/AudiometryResult', { state: { patient } }); // Pass patient data here
  };

  const handleViewPromResult = (patient) => {
    console.log('Selected patient:', patient);
    navigate('/PromResult', { state: { patient } }); // Pass patient data here
  };


  return (

    <div className="min-h-screen flex flex-col lg:px-32 px-5 pt-0 lg:pt-0">
      <div className="flex flex-col items-center lg:flex-row justify-between pt-16">
        <div className="">
          <button onClick={() => navigate('/MainSettings')} className='bg-purple-700 text-white p-2 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-md px-6 py-3'>
            {t('dashboard_back_to_settings')}
          </button>
          <br></br>
          <h1 className="text-4xl mt-1  font-semibold text-center lg:text-start">
            {t('dashboard_scoreboard')}
          </h1>
        </div>
        {/* Search input */}
        <div className="mt-5 lg:mt-0  flex items-center">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search patients..."
            className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          />
          <button
            onClick={() => fetchUsers(true)}
            className="bg-purple-600 text-white ml-2 px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:border-purple-600"
          >
            {t('dashboard_search')}

          </button>
         {/* <LanguageSwitcherButton/> */}
        </div>
      </div>
      {/* Responsive table */}
      <div className="overflow-auto mt-10 h-[50vh]">
        <table className="table-auto w-full border-collapse border border-gray-300 ">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 p-3">{t('dashboard_first_name')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_last_name')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_social_security_number')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_latest_measurement')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_din_result')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_audiometry')}</th>
              <th className="border border-gray-300 p-3">{t('dashboard_survey_result')}</th>
              {/* <th className="border border-gray-300 p-3">Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {patients.map((patient) => (
              <tr key={patient._id}>
                <td className="border border-gray-300 p-3">{patient.firstName}</td>
                <td className="border border-gray-300 p-3">{patient.lastName}</td>
                <td className="border border-gray-300 p-3">{patient.socialSecurityNumber}</td>
                <td className="border border-gray-300 p-3">{patient.latestVisitation ? new Date(patient.latestVisitation).toLocaleString() : 'Not Available'}</td>
                <td className="border border-gray-300 p-3">
                  {patient.digitInNoise?.length > 0 ? <button
                    onClick={() => handleViewDinResult(patient)}
                    className="bg-purple-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 transition-all w-full shadow-sm"
                  >
                    {t('dashboard_open')}
                  </button> : ""} </td>
                <td className="border border-gray-300 p-3">
                  {patient.audiogram?.length > 0 ? <button
                    onClick={() => handleViewAudiometryResult(patient)}
                    className="bg-purple-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 transition-all w-full shadow-sm"
                  >
                    {t('dashboard_open')}

                  </button> : ""} </td>

                <td className="border border-gray-300 p-3">
                  {patient.questionnaires?.length > 0 ? <button
                    onClick={() => handleViewPromResult(patient)}
                    className="bg-purple-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 transition-all w-full shadow-sm"
                  >
                    {t('dashboard_open')}

                  </button> : ""} </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="flex justify-between items-center mt-5">
        <button
          onClick={() => setPage(page > 1 ? page - 1 : 1)}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          {t('dashboard_previous')}
        </button>
        <span className="text-gray-700">Page {page}</span>
        <button
          onClick={() => setPage(page + 1)}
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          {t('dashboard_next')}
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
