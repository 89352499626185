import React, { useEffect, useRef, useState } from 'react';
import { AbortIcon, ArrowRightIcon, CornerDownRightIcon, CrossIcon, Help_Icon, InfoIcon, NextIcon, PauseIcon, ResumeIcon, RippleImage1, SoundIcon, SquareIcon, StarIcon, TickIcon } from '../../../assets/Icons';
import Header from '../MedicalGlobal/Header';
import { useDispatch, useSelector } from 'react-redux';
import isValidJSON from '../../../Functions/IsValidJSON';
import { useNavigate } from 'react-router-dom';
import { audioObject } from '../../../Functions/AudioClass';
import { answers, lists } from './DINData';
import FullPageLoader from '../../../utils/Loader/FullPageLoader';
import ModalWrapper from '../../Modals/ModalWrapper/ModalWrapper';
import { ToastContainer } from 'react-toastify';
import './DIN.css';  // Import the CSS file
import './NextButtonAdv.css';  // Import the CSS file
import NormalTextLoader from '../../../utils/Loader/NormalTextLoader';
import axios from 'axios';
import FullPageModalWrapper from '../../Modals/ModalWrapper/FullPageModalWrapper';
import { updateTestStatusByName } from '../../../Functions/upDateSoundTestStatus';
import { setSoundTest } from '../../../Slices/soundSilce';
import { useTranslation } from 'react-i18next';
import renderTextWithBreaks from '../../../Functions/renderTextWithBreaks';
import { GrLinkNext } from "react-icons/gr";


// For speaker the channel calibration value is changed
//  let leftCalibrationValue = speakerLeftEarSetting;

// adn the info text is changed to front speaker instead of riht or left
// <p className="text-[#344054] text-[20px]">{t('dinv6_full_modal_next_test_intro')} <span className='font-bold'>{isMoreRoundToPlayText.testName === 'Adaptive' ? t('dinv6_full_modal_next_test_type_adaptive') : t('dinv6_full_modal_next_test_type_static')}</span>. {t('dinv6_full_modal_sound_played')} <span className='font-bold'>{isMoreRoundToPlayText.testChannel === 'right' ? 'ede' : 'ede'}stä</span>.<br />{t('dinv6_full_modal_next_round')} <span className='font-bold'>{currentRestRoundRef.current}/{(muutumatonSetting + adaptiveSetting) * earsSetting.length}.</span></p>


// For antiphasic the digitPromises is changed also the sound is forced as stereo!

//let earsSetting = ["both"];

//        const digitPromises = lists[selectedList].map((digit, index) =>
//    fetch(require(`../../../assets/16BitDigitFiles/${digitFolderName}/${selectedList}/${digit}.wav`))
//.then(response => response.arrayBuffer())
//.then(buffer => audioContextRef.current.decodeAudioData(buffer))
//.then(decodedBuffer => {
//    digitBuffersRef.current[index] = decodedBuffer;
//    console.log(`Digit ${digit} loaded and decoded From Number List ${selectedList} path:../../../assets/16BitDigitFiles/${digitFolderName}/${selectedList}/${digit}.wav`);
//})
//);



const DinTestV10Antiphasic = () => {
    /* Translation language */
    /* States for playing the sound icon */
    const [isPlaying, setIsPlaying] = useState(false);

    const { t } = useTranslation();
    /* States of the selected or pressed numbers */
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    /* Manage the test completion status */
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    /* Current Triplet Index state */
    const [currentTripletIndex, setCurrentTripletIndex] = useState(0);
    /* The line `const [isAnswerButtonDisabled, setIsAnswerButtonDisabled] = useState(true);` is
    declaring a state variable `isAnswerButtonDisabled` using the `useState` hook in React. */
    const [isAnswerButtonDisabled, setIsAnswerButtonDisabled] = useState(true);

    // Create a ref for the numberpad container
    const numberpadRef = useRef(null);

    /* Showing test passed  */

    const dispatch = useDispatch();
    const soundTest = useSelector((state) => state.soundState.soundTest);
    //updateTestStatusByName(soundTest, 'Hälypuhetesti', dispatch, setSoundTest, );

    /* Get the token */
    const token = useSelector((state) => state.authState.token) || (localStorage.getItem('token') && isValidJSON(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : null);
    /* Navigations and dispatch */
    const navigate = useNavigate();
    /* Din Settings */
    /* When the page loads mount the settings */
    let headPhoneLeftEarSetting = useSelector((state) => state.dinState.headPhoneLeftEarSetting);
    let headPhoneRightEarSetting = useSelector((state) => state.dinState.headPhoneRightEarSetting);
    let speakerLeftEarSetting = useSelector((state) => state.dinState.speakerLeftEarSetting);
    let speakerRightEarSetting = useSelector((state) => state.dinState.speakerRightEarSetting);
    let soundSourceSetting = useSelector((state) => state.dinState.soundSourceSetting);
    let adaptiveSetting = useSelector((state) => state.dinState.adaptiveSetting);
    let muutumatonSetting = useSelector((state) => state.dinState.muutumatonSetting);
    //let earsSetting = useSelector((state) => state.dinState.earsSetting);
    let earsSetting = ["both"];
    let numberSeriesSetting = useSelector((state) => state.dinState.numberSeriesSetting);
    let speechVolumeSetting = useSelector((state) => state.dinState.speechVolumeSetting);
    let digitFolderNameSetting = "FinnishTripletsAntiphasic"
    /* The below audio object will update the speech and noise volume settings as for defaults */
    audioObject.setDefaults(speechVolumeSetting);
    /* Load the calibration Value as per the sound source */
    let leftCalibrationValue = soundSourceSetting === 'headPhone' ? headPhoneLeftEarSetting : speakerLeftEarSetting;
    let rightCalibrationValue = soundSourceSetting === 'headPhone' ? headPhoneRightEarSetting : speakerRightEarSetting;

    const isDataSavedRef = useRef(false);


    const [selectedList, setSelectedList] = useState(numberSeriesSetting);
    const [digitFolderName, setDigitFolderName] = useState(digitFolderNameSetting);
    /* this will help us keep track for new round */
    const [isNewTestLoaded, setIsNewTestLoaded] = useState(false);

    /* This will help to update the test to new list */
    const [currentListIndex, setCurrentListIndex] = useState(1);
    /* With this we are managing if there are more rounds to play*/
    const [isMoreRoundToPlay, setIsMoreRoundToPlay] = useState(false);

    /* Tests Inital Phase */
    const [isTestLoaded, setIsTestLoaded] = useState(false);
    /* This will keep the track of the ongoing test */
    const [onGoingTest, setOnGoingTest] = useState(null);

    /* This keeps the track for the test algorithm */
    const [testsDin, setTestsDin] = useState([]);
    /* This will help us keep the track of which test we are in right now */
    const testIndexRef = useRef(0);
    const [testIndex, setTestIndex] = useState(0);

    /* This will keep the track of test round for protocol meaing 1x Constant 2x Adaptive*/
    const testRoundRef = useRef(1);
    const currentRestRoundRef = useRef(1);

    /* This will help to keep the track of the tests running */
    const [isTestRunning, setIsTestRunning] = useState(false);
    const isTestRunningRef = useRef(false);

    const [currentTripletAdaptiveIndex, setCurrentTripletAdaptiveIndex] = useState(0); // this will keep the track for the db to +2DB or -2DB

    /* triplet playing state */
    const isTripletPlayingRef = useRef(false);
    const [isDataSaving, setIsDataSaving] = useState(false);
    /* Factor helps us control the amount of digits to be played */
    const factor = Number(process.env.REACT_APP_FACTOR_DIN) || 1; //this will be helpful for testing by reducing 
    console.log({ factor });

    /* channel to track on which ear sound is being played */
    const [currentChannel, setCurrentChannel] = useState(null);
    /* Studio */
    const audioContextRef = useRef(null);
    /* Below is the noise file */
    const noise550ms = require('../../../assets/Sounds/noise550ms.wav'); // Replace with actual noise file path
    /* Noise CD player and the content holder the buffer */
    const noiseSourceRef = useRef(null);
    const noiseBufferRef = useRef(null);
    /* Noise Volumne Control */
    const leftGainNodeRef = useRef(null); // to control left channel noise
    const rightGainNodeRef = useRef(null); // to control right channel noise
    /* Digit CD player and the content holder buffer */
    const digitSourceRef = useRef(null);
    const digitBuffersRef = useRef([]);
    /* Digit volumne control */
    const leftDigitGainNodeRef = useRef(null); // to control left channel noise
    const rightDigitGainNodeRef = useRef(null); // to control right channel noise

    /* This will maintain the state of the current question /triplet thats being played */
    const [questionData, setQuestionData] = useState({
        questionNumber: '',
        timeDigitStopPlaying: '',
        timeDigitAnswered: '',
        speechDB: speechVolumeSetting,
        correct: false,
        questionNumberIndex: 0,
        testName: '',
        channel: '',
        testRound: testRoundRef.current,
        timeDigitStartedPlaying: '',
        timeFirstNumberPressed: '',
    });
    const recordTripletStartTimeRef = useRef('');
    const recordFirstButtonPressed = useRef(false);


    const questionHistoryRef = useRef([]);
    const questionHistoryAllRef = useRef([]);
    
    // Mutable ref to hold dinTableData without causing re-renders // useful of immediate state case handles
    const dinTableDataRef = useRef({
        testStartTimestamp: null,
        testEndTimestamp: null,
        settings: {
            earsSetting: earsSetting,
            adaptiveTest: adaptiveSetting,
            muutumatonTest: muutumatonSetting,
            leftCalibration: headPhoneLeftEarSetting,
            rightCalibration: headPhoneRightEarSetting,
            numberList: numberSeriesSetting,
            noiseDB: speechVolumeSetting,
        },
        questionHistory: []
    });

    /* modal numbers  */
    const selectedNumbersForModal = [4, 0, '?'];



    /* Modals */
    const [showStartTestModal, setShowStartTestModal] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isAbort, setIsAbort] = useState(false);
    const [isHelp, setIsHelp] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [pausedCounter, setPausedCounter] = useState(0);

    /* This will keep the track of the test for if more to play */
    const [isMoreRoundToPlayText, setIsMoreRoundToPlayText] = useState({
        testName: 'd',
        testChannel: 'd',
        testRound: 0,
    });

    console.log(`[+] MAIN DIN VERSION V5:`, { earsSetting, testsDin, leftCalibrationValue, rightCalibrationValue, testIndex, testIndexRef: testIndexRef.current, headPhoneLeftEarSetting, headPhoneRightEarSetting, speakerLeftEarSetting, speakerRightEarSetting, soundSourceSetting, adaptiveSetting, muutumatonSetting, numberSeriesSetting, speechVolumeSetting, isTestRunning, isTestRunningRef: isTestRunningRef.current, testRoundRef: testRoundRef.current, currentRestRoundRef: currentRestRoundRef.current, currentTripletAdaptiveIndex, currentTripletIndex, testDataSaved: isDataSavedRef.current, questionData, questionHistory: questionHistoryRef.current, all: questionHistoryAllRef.current,digitFolderName });


    /* Functions to handle modal smooth closing */
    const handleStartModalClose = () => {
        setIsClosing(true);  // Start the closing animation
        setTimeout(() => {
            setIsClosing(false);
            setShowStartTestModal(false);
            console.log('close action');

            navigate('/TestLandingPage');
            // Actually close the modal after the animation
            // startTest('left', 0);
        }, 300); // 300ms matches the animation duration
    };
    /* This is where the start test will happen */
    const handleStartModalConfirm = () => {
        setIsClosing(true);  // Start the closing animation
        setTimeout(() => {
            setIsClosing(false);
            setShowStartTestModal(false);  // Close the modal
            // Perform your confirm action here
            console.log('Confirmed action test start');
            executeTestWithSettings(testIndex);
            /* Now this will start the test */
            // executeTestWithSettings();
            // startTest('left', 0);

        }, 300);  // 300ms delay for smooth transition
    };

    const handleIsMoreRoundToPlayFunction = () => {
        try {
            setIsClosing(true);
            setTimeout(() => {
                console.log(`[+] Restart The test Meaning next round`);
                console.log(`[+] Test ${onGoingTest} ${testsDin[testIndex].testCountX}X time`);
                setCurrentTripletIndex(0);
                setCurrentTripletAdaptiveIndex(0);
                startTest(testsDin[testIndex].testChannel, 0, testsDin[testIndex].testName, 0);
                setIsClosing(false);
                setIsMoreRoundToPlay(false);
            }, 300);
        } catch (error) {

        }
    };

    /* Function to pause the noise and digit both */
    const pauseNoiseAndDigit = () => {
        try {
            pauseDigit();
            pauseNoise();
        } catch (error) {
            console.error(`[-] Error pauseNoiseand digit:`, error.message);
        }
    };


    /* Play the digit function for now this is for muutumaton*/
    const playTriplet = (index, channel = 'both') => {
        try {
            console.log(`[+] This Is playTriplet testIndex:${testIndexRef.current} testRound:${testRoundRef.current}`);

            isTripletPlayingRef.current = true;
            if (digitBuffersRef.current[index]) {
                if (digitSourceRef.current) {
                    console.log(`[+] digitSourceRef.current found:`);
                    digitSourceRef.current.stop();
                }
                pauseDigit();
                // Default decibel value
                const speechDB = audioObject.defaultDB; // Base decibel level
                const leftCalibration = leftCalibrationValue || 0; // Calibration for left ear
                const rightCalibration = rightCalibrationValue || 0; // Calibration for right ear

                // Calculate gain values considering calibration
                const leftDecibels = speechDB + leftCalibration;
                const rightDecibels = speechDB + rightCalibration;
                /* Gain values */
                const leftGainValue = audioObject.calculateGainFromDecibelsWithThreshold(leftDecibels);
                const rightGainValue = audioObject.calculateGainFromDecibelsWithThreshold(rightDecibels);

                digitSourceRef.current = audioContextRef.current.createBufferSource();
                digitSourceRef.current.buffer = digitBuffersRef.current[index];

                const digitSplitter = audioContextRef.current.createChannelSplitter(2);
                const digitMerger = audioContextRef.current.createChannelMerger(2);

                digitSourceRef.current.connect(digitSplitter);


                if (channel === 'left' || channel === 'both') {
                    digitSplitter.connect(leftDigitGainNodeRef.current, 0); // Connect left channel
                    leftDigitGainNodeRef.current.connect(digitMerger, 0, 0);
                    leftDigitGainNodeRef.current.gain.value = leftGainValue; // Set gain explicitly
                }

                if (channel === 'right' || channel === 'both') {
                    digitSplitter.connect(rightDigitGainNodeRef.current, 1); // Connect right channel
                    rightDigitGainNodeRef.current.connect(digitMerger, 0, 1);
                    rightDigitGainNodeRef.current.gain.value = rightGainValue; // Set gain explicitly
                }


                console.log(`[+] Playing Triplet Mode speechDB:${speechDB} leftChannel:${leftGainValue} rightChannel:${rightGainValue}`);
                digitMerger.connect(audioContextRef.current.destination);

                digitSourceRef.current.onended = () => {
                    console.log('[+] Finished playing triplet, waiting for user input...');
                    setIsAnswerButtonDisabled(false);
                    setQuestionData((prevData) => ({
                        ...prevData,
                        questionNumber: lists[selectedList][index],
                        timeDigitStopPlaying: Date.now(),
                        speechDB: speechDB, // update the speechDB value here
                        questionNumberIndex: index,
                        channel: channel,
                        numberList: selectedList,
                        timeDigitStartedPlaying: recordTripletStartTimeRef.current
                    }));
                    isTripletPlayingRef.current = false;
                    console.log(`[+] Checking if its a randi or not: ${JSON.stringify(questionData)} 1`);
                };

                digitSourceRef.current.start(0);
                /* Record the digit Start playing time */
                recordTripletStartTimeRef.current = Date.now();
                console.log(`[+] Digit Start Time Recorded ${recordTripletStartTimeRef.current}`);
            }
        } catch (error) {
            console.log(`[+] Error Play Triplet:`, error.message);
            isTripletPlayingRef.current = false;
        }
    };

    /* Play the digit function for adaptive */
    const playTripletAdaptive = (index, channel = 'both', decibelIndex = 0) => {
        try {
            console.log(`[+] This Is playTripletAdaptive with decibelIndex:${decibelIndex} testIndex:${testIndexRef.current} testRound:${testRoundRef.current}`);
            isTripletPlayingRef.current = true;
            if (digitBuffersRef.current[index]) {
                if (digitSourceRef.current) {
                    console.log(`[+] digitSourceRef.current found:`);
                    digitSourceRef.current.stop();
                }
                pauseDigit();
                // Default decibel value
                const speechDB = audioObject.defaultDB + audioObject.calculateArithmeticSum(decibelIndex); // Base decibel level
                const leftCalibration = leftCalibrationValue || 0; // Calibration for left ear
                const rightCalibration = rightCalibrationValue || 0; // Calibration for right ear

                // Calculate gain values considering calibration
                const leftDecibels = speechDB + leftCalibration;
                const rightDecibels = speechDB + rightCalibration;
                /* Gain values */
                const leftGainValue = audioObject.calculateGainFromDecibelsWithThreshold(leftDecibels);
                const rightGainValue = audioObject.calculateGainFromDecibelsWithThreshold(rightDecibels);

                digitSourceRef.current = audioContextRef.current.createBufferSource();
                digitSourceRef.current.buffer = digitBuffersRef.current[index];

                const digitSplitter = audioContextRef.current.createChannelSplitter(2);
                const digitMerger = audioContextRef.current.createChannelMerger(2);

                digitSourceRef.current.connect(digitSplitter);


                if (channel === 'left' || channel === 'both') {
                    digitSplitter.connect(leftDigitGainNodeRef.current, 0); // Connect left channel
                    leftDigitGainNodeRef.current.connect(digitMerger, 0, 0);
                    leftDigitGainNodeRef.current.gain.value = leftGainValue; // Set gain explicitly
                }

                if (channel === 'right' || channel === 'both') {
                    digitSplitter.connect(rightDigitGainNodeRef.current, 1); // Connect right channel
                    rightDigitGainNodeRef.current.connect(digitMerger, 0, 1);
                    rightDigitGainNodeRef.current.gain.value = rightGainValue; // Set gain explicitly
                }


                console.log(`[+] Playing TripletAdaptive Mode speechDB:${speechDB} leftChannel:${leftGainValue} rightChannel:${rightGainValue}`);
                digitMerger.connect(audioContextRef.current.destination);

                digitSourceRef.current.onended = () => {
                    console.log('[+] Finished playing tripletAdaptive, waiting for user input...');
                    setIsAnswerButtonDisabled(false);
                    setQuestionData((prevData) => ({
                        ...prevData,
                        questionNumber: lists[selectedList][index],
                        timeDigitStopPlaying: Date.now(),
                        speechDB: speechDB, // update the speechDB value here
                        questionNumberIndex: index,
                        channel: channel,
                        numberList: selectedList,
                        timeDigitStartedPlaying: recordTripletStartTimeRef.current
                    }));
                    isTripletPlayingRef.current = false;
                };

                digitSourceRef.current.start(0);
                /* Record the digit Start playing time */
                recordTripletStartTimeRef.current = Date.now();
                console.log(`[+] Digit Start Time Recorded Adaptive ${recordTripletStartTimeRef.current}`);
            }
        } catch (error) {
            isTripletPlayingRef.current = false;
        }
    };

    /* Play Noise */
    const playNoise = (channel = 'both') => {
        // Default decibel value
        const defaultDecibelValue = audioObject.defaultDB; // Base decibel level
        const leftCalibration = leftCalibrationValue || 0; // Calibration for left ear
        const rightCalibration = rightCalibrationValue || 0; // Calibration for right ear

        // Calculate gain values considering calibration
        const leftDecibels = defaultDecibelValue + leftCalibration;
        const rightDecibels = defaultDecibelValue + rightCalibration;
        /* Gain values */
        const leftGainValue = audioObject.calculateGainFromDecibelsWithThreshold(leftDecibels);
        const rightGainValue = audioObject.calculateGainFromDecibelsWithThreshold(rightDecibels);

        pauseNoise(); // Stop any existing noise before starting

        if (noiseBufferRef.current) {
            noiseSourceRef.current = audioContextRef.current.createBufferSource();
            noiseSourceRef.current.buffer = noiseBufferRef.current;
            noiseSourceRef.current.loop = true;

            const splitter = audioContextRef.current.createChannelSplitter(2);
            const merger = audioContextRef.current.createChannelMerger(2);

            noiseSourceRef.current.connect(splitter);

            if (channel === 'left' || channel === 'both') {
                splitter.connect(leftGainNodeRef.current, 0); // Connect left channel
                leftGainNodeRef.current.connect(merger, 0, 0);
                leftGainNodeRef.current.gain.value = leftGainValue; // Set gain explicitly
            }

            if (channel === 'right' || channel === 'both') {
                splitter.connect(rightGainNodeRef.current, 1); // Connect right channel
                rightGainNodeRef.current.connect(merger, 0, 1);
                rightGainNodeRef.current.gain.value = rightGainValue; // Set gain explicitly
            }

            merger.connect(audioContextRef.current.destination);

            noiseSourceRef.current.start(0);

            setCurrentChannel(channel);
            console.log(`[+] Playing noise with left gain: ${leftGainValue}, right gain: ${rightGainValue}`);
        }
    };


    /* pause the noise */
    const pauseNoise = () => {
        try {
            if (noiseSourceRef.current) {
                noiseSourceRef.current.stop();
                noiseSourceRef.current = null;
                console.log('Background noise paused');
            }
            // Reset gain nodes by setting their values to 0 or disconnecting them
            if (leftGainNodeRef.current) {
                leftGainNodeRef.current.gain.value = 0;
                console.log(`[+] Resetting LeftNoiseGainNode`);
                leftGainNodeRef.current.disconnect();
            }
            if (rightGainNodeRef.current) {
                rightGainNodeRef.current.gain.value = 0;
                console.log(`[+] Resetting RightNoiseGainNode`);
                rightGainNodeRef.current.disconnect();
            }
        } catch (error) {
            console.log(error.message);
        }
    };
    /* pauseDigit */
    const pauseDigit = () => {
        try {
            if (digitSourceRef.current) {
                digitSourceRef.current.stop();
                console.log('Digit stopped playing');
            }
            if (leftDigitGainNodeRef.current) {
                leftDigitGainNodeRef.current.gain.value = 0;
                leftDigitGainNodeRef.current.disconnect();
                console.log('[+] Resetting left Digit Gain');
            }
            if (rightDigitGainNodeRef.current) {
                rightDigitGainNodeRef.current.gain.value = 0;
                rightDigitGainNodeRef.current.disconnect();
                console.log(`[+] Resetting Right Digit Gain`);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    /* Function to update the current list */
    const updateTheNumberList = () => {
        try {
            /* set the test loaded status */
            setIsNewTestLoaded(false);
            /* now update the selected list and load the new list */
            const newListIndex = currentListIndex + 1;
            setCurrentListIndex(newListIndex);
            const newSelectedList = `list${newListIndex}`;
            setSelectedList(newSelectedList);
        } catch (error) {
            console.error(`[-] Update NumberList function error:`, error.message);
        }
    };


    /* Submit Answer */
    const handleSubmitAnswer = (channel = 'both', skipInvoked = false) => {
        try {
            if (isAnswerButtonDisabled || isTripletPlayingRef.current === true)
                return console.info('please wait while digit has stopped playing');
            // Ensure that the user has selected enough numbers (3 digits)
            if (selectedNumbers.length < 3 && skipInvoked === false) {
                return console.info('Please fill in all the answers or question mark if you could not hear ');
            }
            if (skipInvoked === true)
                console.log(`[+] Moving on to next Test Index...`);
            // Retrieve the correct answer for the current triplet from the answer list
            const correctAnswer = answers[`lista${selectedList.slice(-1)}`][currentTripletIndex];

            // Check if the user's input matches the correct answer
            const isCorrect = selectedNumbers.join('') === correctAnswer;

            // Update the question data with the time the answer was given and whether it was correct
            // handleQuestionDataChange('timeDigitAnswered', Date.now());
            // handleQuestionDataChange('correct', isCorrect);

            // Prepare updated question data with additional information
            const updatedQuestionData = {
                ...questionData,
                timeDigitAnswered: Date.now(),
                correct: isCorrect,
                testName: onGoingTest,
                testRound: testRoundRef.current,
                numberList: selectedList,
            };

            setQuestionData(updatedQuestionData);
            /* reset Triplet record time */
            recordTripletStartTimeRef.current = '';
            recordFirstButtonPressed.current = false;
            // Provide feedback to the user
            // setFeedback(isCorrect ? 'Correct' : `Incorrect, correct answer was ${correctAnswer}`);
            isCorrect ? console.info('Correct Answer') : console.info(`Incorrect, correct answer was ${correctAnswer}`);

            // Update the history of questions answered
            // setQuestionHistory((prev) => [...prev, updatedQuestionData]);
            /* This will be the new modified data stored */
            questionHistoryRef.current.push(updatedQuestionData);
            // Clear the selected numbers and disable the answer button until the next triplet is played
            setSelectedNumbers([]);
            setIsAnswerButtonDisabled(true);
            console.log(`[+] CurrentTriplet Index ${currentTripletIndex} ${currentTripletIndex + 1} lists[selectedList].length:${lists[selectedList].length} modlist:${(lists[selectedList].length / factor)}`);

            console.log(`[+] Will now play ${currentTripletIndex + 1} on channel${currentChannel} with ongoing Test ${onGoingTest}  for earSetting:${earsSetting} testIndex:{testIndex} testsArr:`, { testsDin });
            // Check if there are more triplets to play in the current iteration
            if (currentTripletIndex + 1 < (lists[selectedList].length / factor)) {
                let nextIndex = currentTripletIndex + 1;
                setCurrentTripletIndex(nextIndex);
                console.log(`[+] Will now play ${nextIndex} on channel${currentChannel} with ongoing Test ${onGoingTest}`,);
                // Replay the next triplet based on the current channel/ear
                if (testsDin[testIndex].testName === 'Muutumaton')
                    playTriplet(nextIndex, channel || currentChannel);
                else {
                    let newN = 0;
                    if (isCorrect)
                        newN = currentTripletAdaptiveIndex - 1;
                    else
                        newN = currentTripletAdaptiveIndex + 1;
                    setCurrentTripletAdaptiveIndex(newN);
                    playTripletAdaptive(nextIndex, channel || currentChannel, newN);
                }

            } else {
                console.info(`[+] Round Of the test completed`);
                /* Upon completiong updating the questionHistoryAll */
                questionHistoryAllRef.current.push({ 'name': `Round-${testRoundRef.current}-${currentChannel}-${onGoingTest}`, value: questionHistoryRef.current });
                console.log(`[+]QuestionHistoryALLRef:`, questionHistoryAllRef.current);
                questionHistoryRef.current = [];

                /* Updating the round status now */
                /* Now reduce the test count and checking if its still greater than 0 to restart the test automatically */
                let currentTestCount = testsDin[testIndex].testCountX;
                console.log(`[+]testIndex:${testIndex} Before reducing the count:`, currentTestCount);
                console.log(`[+] Now reducing the count:`, currentTestCount);
                currentTestCount = currentTestCount - 1;

                // Update the testsDin array with the new currentTestCount
                const updatedTestsDin = testsDin.map((test, index) => {
                    if (index === testIndex) {
                        // Update the testCountX for the current test
                        return {
                            ...test,
                            testCountX: currentTestCount
                        };
                    }
                    return test;
                });

                // Update the state with the new array
                setTestsDin(updatedTestsDin);


                /* Going to the next round */
                if ((currentTestCount) > 0) {
                    /* Restart the test */
                    console.info('[+] Next Round Initiating....');
                    setIsMoreRoundToPlay(true);
                    pauseNoiseAndDigit(); // pause the digit and noise before playing in the next channel
                    testRoundRef.current = testRoundRef.current + 1;
                    currentRestRoundRef.current = currentRestRoundRef.current + 1;
                    setIsMoreRoundToPlayText((prev) => {
                        return {
                            ...prev,
                            testName: testsDin[testIndex].testName,
                            testChannel: testsDin[testIndex].testChannel,
                            testRound: testRoundRef.current
                        };
                    });
                    // update the number list
                    updateTheNumberList();
                    return;
                }

                /* if not then move on to next test */
                let newTestIndex = testIndex + 1;
                testIndexRef.current = testIndexRef.current + 1;
                if (newTestIndex < testsDin.length) {
                    setIsMoreRoundToPlay(true);
                    pauseNoiseAndDigit(); // pause the digit and noise before playing in the next channel
                    console.log('[+] changing the test:', testsDin[newTestIndex].testName, testsDin[newTestIndex].testChannel);
                    currentRestRoundRef.current = currentRestRoundRef.current + 1;
                    testRoundRef.current = 1;
                    setIsMoreRoundToPlayText((prev) => {
                        return {
                            ...prev,
                            testName: testsDin[newTestIndex].testName,
                            testRound: 1,
                            testChannel: testsDin[newTestIndex].testChannel
                        };
                    });
                    /* Update the number list */
                    updateTheNumberList();
                    setTestIndex(newTestIndex);
                    return;
                }
                console.log(`[+] All tests completed successfully`);
                /*Immediately update the End time*/
                dinTableDataRef.current = {
                    ...dinTableDataRef.current,
                    testEndTimestamp: Date.now()
                };
                setIsPlaying(false);
                setIsTestRunning(false);
                isTestRunningRef.current = false;
                setIsTestCompleted(true);
                pauseNoiseAndDigit();
                return console.info(`[+] All tests completed successfully`);

            }
        } catch (error) {
            console.log(`[-] Error Handle Submit :`, error.message);
        }
    };

    /* Const Save Test */

    const saveTestData = async (invoke = false) => {
        try {

            // Assuming REACT_APP_BACKEND_URL is set to your backend URL in the environment
            const backendURL = `${process.env.REACT_APP_BACKEND_URL}/dintest/createdintest`;
            dinTableDataRef.current = {
                ...dinTableDataRef.current,
                questionHistory: questionHistoryAllRef.current,
            };
            console.log(`[+] Question History`, dinTableDataRef.current);
            // Assuming you have a function to get the user token (if needed for authentication)
            // const token = localStorage.getItem('token'); // Get token from local storage or however it's stored
            if (!token)
                throw new Error('Something went wrong..');
            if (isTestCompleted === false && invoke === false)
                throw new Error("Test has not been completed yet");
            if (isDataSavedRef.current === true)
                throw new Error("Please wait you will be redirected too the test page soon");

            if (invoke === true) {
                /* Forcefully exiting the test in abort test scenario still update the test time */
                dinTableDataRef.current = {
                    ...dinTableDataRef.current,
                    testEndTimestamp: Date.now()
                };
            }

            isDataSavedRef.current = true;
            setIsDataSaving(true);
            console.log('[+] Save test data: value', isDataSavedRef.current);
            // Send the dinTableData to the backend
            const response = await axios.post(
                backendURL,
                dinTableDataRef.current,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Send token for authorization if required
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.status) {
                console.log('Test data saved successfully:', response.data.message);
                console.info('Dear User Your Data has been saved successfully ...');
                // updateSoundTestStatus(soundTest, soundTestIndex, dispatch, setSoundTest);

                setTimeout(() => {
                    isDataSavedRef.current = false;
                    ///updateTestStatusByName(soundTest, 'Hälypuhetesti', dispatch, setSoundTest,);
                    navigate('/DinResultPageAntiphasic', { state: { dinTestData: dinTableDataRef.current } });
                    setIsDataSaving(false);
                }, 2000);
                return console.info("You will soon be directed to the next test");
                // You can add additional success handling (like UI feedback) here
            } else {
                console.error('Failed to save test data:', response.data.message);
                // isDataSavedRef.current = false;

                throw new Error(response.data.message);
            }


        } catch (error) {
            console.log('Error while saving test data:', error.message);
            isDataSavedRef.current = false;
            setIsDataSaving(false);

            return console.info('Something went wrong');
        }
    };



    /* This is the current version of the test */
    const startTest = (channel = 'both', index = 0, testName = 'Muutumaton', adaptiveIndex = 0) => {
        try {
            console.info(`[+] Test ${testName}  Started startest`);
            if (isTestRunning === false) {
                console.log(`[+] Test StartTime updated`);
                /* Immediately update the start time it */
                dinTableDataRef.current = {
                    ...dinTableDataRef.current,
                    testStartTimestamp: Date.now()
                };
            }
            else {
                console.info(`[+]Will not update the start test time as test is running`);
            }
            setCurrentTripletIndex(index);
            setIsTestRunning(true);
            isTestRunningRef.current = true;
            setIsPlaying(true);
            playNoise(channel);
            setOnGoingTest(testName);
            /* this will reset the index for adaptive */
            setCurrentTripletAdaptiveIndex(adaptiveIndex);
            if (testName === 'Muutumaton')
                return playTriplet(index, channel);
            else
                return playTripletAdaptive(index, channel, adaptiveIndex);
        } catch (error) {
            console.log(`[+] Something went wrong in start test:`, error.message);
        }
    };

    /* Resume Test */
    const resumeTest = () => {
        startTest(testsDin[testIndex].testChannel, currentTripletIndex, testsDin[testIndex].testName, currentTripletAdaptiveIndex);
    };

    /* Execute Test with Settings */
    /* Start the test with settings */
    const executeTestWithSettings = (index = testIndex) => {
        try {
            if (testsDin && testsDin.length === 0)
                return console.info('There are Not tests to be played');
            if (earsSetting && earsSetting.length === 0)
                return console.info("Ears setting cannot be empty for example right left both");
            console.info(`[+] Execute test with settings index:${index}`);
            return startTest(testsDin[index].testChannel, 0, testsDin[index].testName, 0);
        } catch (error) {
            console.log(`[-] Error in executeTestWithSettings:`, error.message);
            return console.info(`[-] Something went wrong`);
        }

    };


    const handlePressedEffect = (key, invoke = false) => {
        try {
            let buttonElement = null;
            buttonElement = invoke === false ? document.getElementById(`button-${key}`) : document.getElementById('button-cross');
            // If the buttonElement was found, add a temporary pressed class
            if (buttonElement) {
                buttonElement.classList.add('pressed');

                // Remove the pressed class after a short delay
                setTimeout(() => {
                    buttonElement.classList.remove('pressed');
                }, 150); // 150ms for visual feedback
            }
        } catch (error) {
            console.log(`[+] Error HanldePressedEffect`, error.message);
        }
    };


    /* Handle Number Click On the NumberPad */
    const handleNumberClick = (number) => {
        handlePressedEffect(number);
        if (selectedNumbers.length < 3) {
            setSelectedNumbers((prev) => [...prev, number]);
        }
        /* record the first button pressed */
        if (recordFirstButtonPressed.current === false && number !== '?') {
            console.log(`[+] First Button press recorded ${number}`);
            /* Update the first Number button pressed */
            setQuestionData((prevData) => ({
                ...prevData,
                timeFirstNumberPressed: Date.now(),
            }));
            recordFirstButtonPressed.current = true;
        } else {
            console.log(`[+] Skipping first button press ${JSON.stringify(questionData)}`);
        }
    };


    /* Handle The Cross Click */
    const handleCrossClick = () => {
        setSelectedNumbers(selectedNumbers.slice(0, -1));
        handlePressedEffect(-1, true);
    };


    /* Handle Abort Test */
    const handleAbortTest = (e) => {
        try {
            console.info("[+] Abort Requested");
            pauseNoiseAndDigit();
            setIsAbort(true);
        } catch (error) {
            console.error(`[-] Handle Abort:`, error.message);
            setIsAbort(false);
        }
    };


    // Function to handle abort close (cancel the abort action)
    const handleAbortClose = () => {
        try {
            setIsClosing(true);  // Start the closing animation
            setTimeout(() => {
                setIsClosing(false);
                // Perform your confirm action here
                console.log('Confirmed action Cancel Abort');
                console.log('[+] Abort Canceled');
                console.log('[+] Abort Cancelled...');
                console.info('Resuming the test');
                /* Once the abort is cancelled we resume the test by skipping */
                playNoise(testsDin[testIndex].testChannel);
                handleSubmitAnswer(currentChannel, true);
                setIsAbort(false);
            }, 300);  // 300ms delay for smooth transition
        } catch (error) {
            console.log(`handleAbortClose Error: ${error.message}`);
        }
    };

    // Function to handle abort confirm (confirm the abort action)
    const handleAbortConfirm = () => {
        try {
            setIsClosing(true);  // Start the closing animation
            setTimeout(() => {
                setIsClosing(false);
                // Perform your confirm action here
                console.log('Confirmed Abort');
                console.log('[+] Aborting Test');
                console.log('[+] Aborting Test...');
                console.info('Test is aborting');
                setIsAbort(false);
                saveTestData(true);
                navigate('/TestLandingPage');
            }, 300);  // 300ms delay for smooth transition
        } catch (error) {
            console.log(`handleAbortConfirm Error: ${error.message}`);
        }
    };



    /* Handle Help Modal */
    const handleHelpApua = (e) => {
        try {
            console.info('[+] Help Requested');
            pauseNoiseAndDigit();
            setIsHelp(true);
        } catch (error) {
            console.error(`[-] handleHelpApua:`, error.message);
            setIsHelp(false);
        }

    };
    /* function to close helpmodal */
    const handleCloseHelp = () => {
        try {
            setIsClosing(true);  // Start the closing animation
            setTimeout(() => {
                setIsClosing(false);
                // Perform your confirm action here
                console.log('Confirmed action Cancel help');
                console.log('[+] Help Canceled');
                console.log('[+] Help Cancelled...');
                console.info('Resuming rest');
                setIsHelp(false);
                /* Once the help is cancelled we resume the test */
                resumeTest();
            }, 300);  // 300ms delay for smooth transition
        } catch (error) {
            console.log(`handleCloseHelp Error: ${error.message}`);
        }
    };

    // fucntion to pause the test
    const handlePauseTest = () => {
        try {
            if (isTestRunning === false) {
                return console.info('Cannot pause without starting the test');
            }

            console.log('[+] Pause Test');
            setIsPaused(true);
            pauseNoise();
            pauseDigit();


            /* Logic for pauseCounter */
            const newPauseCounter = pausedCounter + 1;
            setPausedCounter(newPauseCounter);
            /* Pause Noise and digit */
            setIsAnswerButtonDisabled(true);
        } catch (error) {
            console.log(`handlePauseTest Error: ${error.message}`);
        }
    };


    /* Load alogrithm */
    useEffect(() => {

        // Create an array to store test objects
        let tests = [];

        for (let i = 0; i < earsSetting.length; i++) {
            // Add 'Muutumaton' test if muutumatonSetting exists
            if (muutumatonSetting > 0) {
                tests.push({ testName: 'Muutumaton', testCountX: muutumatonSetting, testChannel: earsSetting[i] });
            }
            // Add 'Adaptive' test if adaptiveSetting exists and is greater than 0
            if (adaptiveSetting > 0) {
                tests.push({ testName: 'Adaptive', testCountX: adaptiveSetting, testChannel: earsSetting[i] });
            }
        }
        // Update the testsDin state
        setTestsDin(tests);
    }, []); // Runs when settingDIN changes (or on mount)

    /* Load the noise and the number list */
    useEffect(() => {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

        // Create gain nodes for the noise
        leftGainNodeRef.current = audioContextRef.current.createGain();
        rightGainNodeRef.current = audioContextRef.current.createGain();

        // Create gain nodes for the noise
        leftDigitGainNodeRef.current = audioContextRef.current.createGain();
        rightDigitGainNodeRef.current = audioContextRef.current.createGain();

        // Load and decode the background noise audio
        fetch(noise550ms)
            .then(response => response.arrayBuffer())
            .then(buffer => audioContextRef.current.decodeAudioData(buffer))
            .then(decodedBuffer => {
                noiseBufferRef.current = decodedBuffer;
                console.log('Background noise loaded and decoded');
            });

        // Load digit audio files for the selected list
        const digitPromises = lists[selectedList].map((digit, index) =>
            fetch(require(`../../../assets/16BitDigitFiles/${digitFolderName}/${selectedList}/${digit}.wav`))
                .then(response => response.arrayBuffer())
                .then(buffer => audioContextRef.current.decodeAudioData(buffer))
                .then(decodedBuffer => {
                    digitBuffersRef.current[index] = decodedBuffer;
                    console.log(`Digit ${digit} loaded and decoded From Number List ${selectedList} path:../../../assets/16BitDigitFiles/${digitFolderName}/${selectedList}/${digit}.wav`);
                })
        );

        Promise.all(digitPromises).then(() => {
            console.log('All digit audio files loaded and ready to play');
            /* Remove this later in production */
            setTimeout(() => {
                if (testRoundRef.current === 1 && isTestRunning === false && isTestCompleted === false)
                    setIsTestLoaded(true);
                if (isTestCompleted === false && isTestRunning === true && isNewTestLoaded === false)
                    setIsNewTestLoaded(true);

                /* Modal for start Test */
                setShowStartTestModal(true);
            }, 500);
        });

        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, [selectedList]); // Reload when selectedList changes

    /* this will take care upon unmount */
    useEffect(() => {
        return () => {
            if (noiseSourceRef.current) {
                noiseSourceRef.current.stop(0);
                noiseSourceRef.current.disconnect();
            }
            if (digitSourceRef.current) {
                digitSourceRef.current.stop(0);
                digitSourceRef.current.disconnect();
            }
            console.log('Audio context and nodes cleaned up on unmount');
        };
    }, []);


    /* Effect to handle key presses for numbers 0-9 and Backspace */
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (isTestCompleted === true) return;

            const { key } = event;
            let buttonElement = null;

            if (key >= '0' && key <= '9') {
                handleNumberClick(parseInt(key, 10));
                buttonElement = document.getElementById(`button-${key}`);
            } else if (key === 'Backspace') {
                handleCrossClick();
                buttonElement = document.getElementById('button-cross');
            } else if (key === 'Enter') {
                if (isTestRunningRef.current === false) {
                    return console.info("Test has not been started yet.");
                }
                handleSubmitAnswer(currentChannel, false);
            }

            // If the buttonElement was found, add a temporary pressed class
            if (buttonElement) {
                buttonElement.classList.add('pressed');

                // Remove the pressed class after a short delay
                setTimeout(() => {
                    buttonElement.classList.remove('pressed');
                }, 150); // 150ms for visual feedback
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedNumbers, isAnswerButtonDisabled]);

    /* This will take care of the loading status upon the page if its not loaded */
    if (isTestLoaded === false)
        return <FullPageLoader />;
    return (
        <div className='h-[100vh] box-border bg-gray-100 overflow-y-hidden border relative'>
            {/* Exit and help */}
            <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-0 relative z-40'>
                <div className='rounded-xl absolute left-[0.5%] top-[8px]'>
                    <div className='text-sm gap-x-2 flex py-2 justify-between px-4 bg-gray-100 text-black rounded-xl'>
                        <div className='text-md font-bold flex gap-x-1'>
                            <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-4 lg:px-6
                               2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1' onClick={handleAbortTest}>
                                <img src={SquareIcon} alt='' className='size-4' />
                                <button className=''  >{t('dinv6_cancel')}</button>
                            </div>
                            <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-4 lg:px-19
                               2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1'
                                onClick={handleHelpApua}  >
                                <img src={InfoIcon} alt='ℹ️' className='size-4' />
                                <button className='' >{t('dinv6_help')}</button>
                            </div>
                        </div>
                        {/* <div className='font-bold flex justify-center items-center  '>
                            <p>{currentTripletIndex + 1} /20</p>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* Next button */}
            {/* <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center mt-0 absolute right-[24%] top-[50%] z-40 '>
                <div className={isAnswerButtonDisabled ? `flex justify-center items-center bg-gray-300 text-gray-100 border border-gray-300 py-14 px-8 lg:py-14 lg:px-10 2xl:py-14 2xl:px-12 rounded-2xl cursor-not-allowed gap-x-1` : `flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-14 px-8 lg:py-14 lg:px-10 2xl:py-14 2xl:px-12 rounded-2xl transition-colors duration-300 ease-in-out hover:bg-purple-800 gap-x-1 ${isAnswerButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={e => handleSubmitAnswer(null)}>
                    <button className={`${isAnswerButtonDisabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-3xl`} disabled={isAnswerButtonDisabled}>{t('dinv6_next')}</button> 
                    <GrLinkNext size={32} className='mt-2' />
                </div>
            </div> */}
            {/* Numpads and information */}
            <div className=' h-screen flex flex-col py-10 xl:py-10 2xl:py-22 justify-between overflow-auto'>

                {/* <div className='hidden md:flex lg:flex 2xl:flex'></div> */}
                {/* <div className='flex  border border-black' /> */}

                {/* <div className='flex md:hidden lg:hidden 2xl:hidden'/> */}
                {/* <div className='flex-[10%]'></div> */}

                <div className='gap-y-5 md:gap-y-4 lg:gap-y-4 2xl:gap-y-5 flex flex-col '>
                    <div className='hidden 2xl:flex 2xl:py-10' />
                    {/* Header Section */}
                    <div className='flex justify-center z-[20] '>
                        <Header headerText={t('dinv6_title')} className={`text-[#363F72] text-lg lg:text-1xl 2xl:text-4xl`} />
                    </div>

                    {/* Instruction Section */}
                    <div className='text-center z-[20]'>
                        <p className={`text-[22px] lg:text-2xl 2xl:text-5xl text-[#344054] font-bold`}>{t('dinv6_instruction_part_1')}</p>
                        <p className={`text-[22px] lg:text-2xl 2xl:text-5xl text-[#344054] font-bold`}>{t('dinv6_instruction_part_2')}</p>
                    </div>

                    {/* Display selected numbers */}
                    <div className='z-[20] flex items-center gap-x-4 mt-3 mb-0 justify-center'>
                        <div className='flex gap-x-3'>
                            {Array(3).fill(null).map((_, index) => (
                                <div key={index} className='w-8 h-8 lg:w-16 lg:h-16 2xl:w-18 2xl:h-18 bg-white border-2 border-white rounded-full flex justify-center items-center text-gray-800 text-[24px]'>
                                    {selectedNumbers[index] !== undefined ? selectedNumbers[index] : ''}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Number Pad vold*/}
                    <div className='flex md:hidden lg:hidden 2xl:hidden xl:hidden justify-center w-full px-2 py-4 z-20 mb-0  '>
                        <div className='grid grid-cols-3 gap-5 mt-0 '>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'cross', 0, '?'].map((item, index) => (
                                <button
                                    key={index}
                                    id={`button-${item}`}
                                    className={`w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-xl 2xl:rounded-2xl flex justify-center items-center text-lg lg:text-2xl 2xl:text-3xl font-bold 
          bg-white transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
          shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
          border-4 border-[#6c6f75] text-gray-800
          ${item === 'cross' ? 'text-red-600' : 'text-gray-800'}
          ${isTestCompleted === false ? 'cursor-pointer' : 'cursor-not-allowed'}
        `}
                                    disabled={isTestCompleted}
                                    onClick={() => {
                                        if (item === 'cross') {
                                            return handleCrossClick();
                                        } else if (item === '?') {

                                            return handleNumberClick(item);

                                        } else {
                                            return handleNumberClick(item);
                                        }
                                    }}
                                >
                                    {item === 'cross' ? <img src={CrossIcon} alt='X' className='text-sm' /> : item}
                                </button>
                            ))}
                        </div>
                    </div>
                    {/* New version ----> Fixed compatibility */}
                    {/* Version New That does not break */}
                    <div
                        className="hidden md:flex lg:flex xl:flex 2xl:flex justify-center w-full px-2 py-4  mb-0"
                    >
                        <div
                            className="grid grid-cols-3 gap-5 mt-0 numberpad-container"
                            data-disabled={isAnswerButtonDisabled}
                            data-next-text={t("dinv6_next") || "Seuraava →"}
                            ref={numberpadRef}

                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'cross', 0, '?'].map((item, index) => (
                                <div className="relative group" key={index}>
                                    <button
                                        id={`button-${item}`}
                                        className={`w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-[95px] 2xl:h-[95px] rounded-xl 2xl:rounded-2xl flex justify-center items-center text-lg lg:text-2xl 2xl:text-3xl font-bold 
                     bg-white transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
                     shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
                     border-4 border-[#6c6f75] ${item === 'cross' ? 'text-red-600' : 'text-gray-800'}
                     ${isTestCompleted === false ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                                        disabled={isTestCompleted}
                                        onClick={() => {
                                            if (item === 'cross') {
                                                return handleCrossClick();
                                            } else if (item === '?') {
                                                return handleNumberClick(item);
                                            } else {
                                                return handleNumberClick(item);
                                            }
                                        }}
                                    >
                                        {item === 'cross' ? <img src={CrossIcon} alt='X' className='text-sm' /> : item}
                                    </button>

                                    {item === '?' && (
                                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex px-8 py-4 bg-gray-800 text-white text-md rounded-md shadow-lg transition-opacity duration-300 ease-out opacity-0 group-hover:opacity-100">
                                            {t('dinv6_tooltip_press_thrice_to_skip')}
                                        </div>
                                    )}
                                </div>
                            ))}

                            {/* Invisible "Next" Button to Capture Clicks */}
                            <button
                                className="next-button-overlay"
                                disabled={isAnswerButtonDisabled}
                                onClick={() => {
                                    if (!isAnswerButtonDisabled) {
                                        console.log(`[+] I was invoked`);
                                        handleSubmitAnswer(null);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {/* Test Number Status for small screen*/}
                    <div className='flex md:hidden lg:hidden xl:hidden 2xl:hidden font-bold justify-center items-center py-2'>
                        <p className='text-sm '>{currentTripletIndex + 1} / 20</p>
                    </div>

                    {/* navigation button for smaller screen */}
                    <div className='flex md:hidden lg:hidden xl:hidden 2xl:hidden  justify-center items-center w-full px-0 py-2 z-20  '>
                        <div className='grid grid-cols-4 gap-6 mt-0 bg-white py-3 px-4 rounded-2xl '>

                            <button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-20 2xl:h-20 rounded-xl flex justify-center items-center text-sm lg:text-2xl 2xl:text-3xl font-bold 
            bg-red-400 transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
            shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
            border-4 border-[#6c6f75] text-gray-800 '> <img src={SquareIcon} alt='p' onClick={handleAbortTest}></img></button>

                                <button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-20 2xl:h-20 rounded-xl flex justify-center items-center text-sm lg:text-2xl 2xl:text-3xl font-bold 
            bg-white transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
            shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
            border-4 border-[#6c6f75] text-gray-800'> <img src={Help_Icon} alt='p' onClick={handleHelpApua}></img></button>



                                <button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-20 2xl:h-20 rounded-xl flex justify-center items-center text-sm lg:text-2xl 2xl:text-3xl font-bold 
            bg-yellow-400 transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
            shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
            border-4 border-[#6c6f75] text-gray-800' onClick={isPaused === false ? handlePauseTest : resumeTest}> <img src={isPaused === false ? PauseIcon : ResumeIcon} alt='p' ></img></button>


                                <button className='w-[60px] h-[60px] lg:w-[70px] lg:h-[70px] 2xl:w-20 2xl:h-20 rounded-xl flex justify-center items-center text-sm lg:text-2xl 2xl:text-3xl font-bold 
            bg-purple-500 transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
            shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
            border-4 border-[#6c6f75] text-gray-800 '> <img src={NextIcon} alt='p' onClick={e => handleSubmitAnswer(null)}></img></button>
                        </div>
                    </div>

                    {/* For bigger screen this is the triplet status */}
                    {/* For bigger screens, show the triplet status with tooltip */}
                    <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-1 py-2 '>
                        <div className='font-bold flex justify-center items-center flex-1 cursor-pointer'>
                            <div className='relative group '>
                                <p className='text-4xl'>{currentTripletIndex + 1} / 20</p>

                                {/* Tooltip */}
                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex px-4 py-2 bg-gray-800 text-white text-xs rounded-md shadow-lg transition-opacity duration-300 ease-out opacity-0 group-hover:opacity-100 text-ld w-[320px] justify-center">
                                    {t('dinv6_tooltip_current_triplet')}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                {/* <div className='flex md:hidden lg:hidden 2xl:hidden'/> */}
            </div>
            {/* Below are the modals */}
            {/* Start Modal */}
            <ModalWrapper isOpen={showStartTestModal && !isTestRunning && !isTestCompleted} isClosing={isClosing}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-center items-center gap-x-[0px] relative'>
                        <img src={TickIcon} className='' alt='✓' />
                    </div>
                    <div >
                        <div className='text-[#101828] font-bold text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>{t('dinTestv6_start_modal_title')}<br /> </div>
                    </div>

                    <div className={`flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-purple-800 gap-x-1 cursor-pointer`}
                        onClick={handleStartModalConfirm}
                    >
                        <button className={`cursor-pointer text-sm md:text-sm lg:text-2xl 2xl:text-3xl`}  > {t('dinTestv6_start_modal_start')}</button> {/* Start the animation */}
                    </div>
                    <div className='flex gap-x-1' onClick={handleStartModalClose}>
                        <button className='bg-white text-gray-800 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-400 w-full text-sm md:text-sm lg:text-2xl 2xl:text-3xl' > {t('dinTestv6_start_modal_cancel')}</button>
                    </div>
                </div>
            </ModalWrapper>

            {/* Below is the modal for if there are more rounds to play */}
            {/* More Round to play */}
            {/* <ModalWrapper isOpen={isMoreRoundToPlay} isClosing={isClosing}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-center items-center gap-x-[0px] relative'>
                        <img src={ResumeIcon} className='' />
                    </div>
                    <div >
                        <div className='text-[#101828] font-bold text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>The Test Round is {isMoreRoundToPlayText.testName} <br /> </div>
                    </div>

                    <div>
                        <div className='text-[#1D2939] text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>
                            This is Test Round {isMoreRoundToPlayText.testRound} <br />Being Played on Ear: {isMoreRoundToPlayText.testChannel}.
                        </div>
                    </div>

                    {isNewTestLoaded === true ? <div className={`flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-purple-800 gap-x-1 cursor-pointer`}
                        onClick={(e) => {
                            handleIsMoreRoundToPlayFunction();

                        }}
                    >
                        <button className={`cursor-pointer text-sm md:text-sm lg:text-2xl 2xl:text-3xl`} >Jatka</button> 
                    </div>
                        : <NormalTextLoader />}
                </div>:
            </ModalWrapper> */}

            {/* More Round to play */}
            <FullPageModalWrapper isOpen={isMoreRoundToPlay} isClosing={isClosing}>
                <div className="p-4 border h-[100vh] bg-[#F9FAFB] flex ">
                    {/* Div For Info */}
                    <div className="flex-[50%]">
                        <div className="h-[95vh] overflow-auto flex flex-col justify-center items-center ">
                            <div className="flex flex-col gap-y-4 md:gap-y-4 lg:gap-y-6 xl:gap-y-6">
                                <div className="w-full ">
                                    <div className="flex flex-col gap-y-6  w-full">
                                        <p className="text-sm md:text-1xl lg:text-[36px] 2xl:text-[40px] font-bold flex flex-col  text-left">{t('dinv6_full_modal_great')} </p>
                                        <p className="text-sm md:text-1xl lg:text-[36px] 2xl:text-[40px] font-bold flex flex-col  text-left">{t('dinv6_full_modal_success')}</p>

                                    </div>
                                </div>
                                <div className="w-full flex flex-col gap-y-8">
                                    <div>
                                        <p className="text-[#344054] text-[20px]">{t('dinv6_full_modal_next_test_intro')} <span className='font-bold'>{isMoreRoundToPlayText.testName === 
                                        'Adaptive' ? t('dinv6_full_modal_next_test_type_adaptive') : t('dinv6_full_modal_next_test_type_static')}</span>. {t('dinv6_full_modal_sound_played')}
                                            <span className='font-bold'>{isMoreRoundToPlayText.testChannel === 'right' ? ' stereona' : ' stereona'}</span>.<br />{t('dinv6_full_modal_next_round') } 
                                            <span className='font-bold'> {currentRestRoundRef.current}/{( muutumatonSetting + adaptiveSetting) * earsSetting.length}.</span></p>
                                    </div>
                                    <div className="">
                                        <p className="text-[#344054] text-[20px]">
                                            {t('dinv6_full_modal_instruction')}<br />{t('dinv6_full_modal_if_unclear')}
                                            <span
                                                className={`
        ml-1 mr-1 w-[20px] h-[20px] lg:w-[20px] lg:h-[20px] 2xl:w-[20px] 2xl:h-[20px] 
        rounded-md bg-white transition-all duration-200 ease-out transform hover:scale-105 
        active:scale-95 shadow-[2px_0px_0px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)] 
        border-2 border-[#6c6f75] text-gray-800 inline-block justify-center items-center 
        text-sm lg:text-sm 2xl:text-sm
        `}
                                                style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                ?
                                            </span>
                                            -{t('dinv6_full_modal_question_button')}.
                                        </p>
                                    </div>

                                    <div>
                                        <p className="text-[#344054] text-[20px]">{t('dinv6_full_modal_duration')} <span className="font-bold">20</span> {t('dinv6_full_modal_rounds')}.</p>
                                    </div>

                                </div>

                                <div className="w-full flex justify-start cursor-pointer" onClick={(e) => {
                                    handleIsMoreRoundToPlayFunction();
                                }}>
                                    <div className=' bg-purple-700 text-white px-6 py-4 transition-colors duration-300 ease-out hover:bg-purple-900 rounded-xl'>
                                        <button> {t('dinv6_full_modal_continue_button')}</button>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>

                    {/* Div For Image */}
                    <div className="flex-[50%] hidden md:flex lg:flex 2xl:flex flex-col justify-center items-center relative " style={{
                        backgroundImage: `url(${StarIcon})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'left'
                    }}>


                        {/* Top Numbers (in a flex row layout) */}
                        <div className="relative inline-block cursor-pointer top-[-12%] left-[-20%]">
                            <div className="absolute bg-green-500 text-black font-bold p-4 rounded-lg text-center w-64 text-lg z-40 opacity-0 transition-opacity duration-300 tooltip">
                                Numerot 4, 0,  <span
                                    className="inline-block rounded-full  shadow-md"
                                    style={{
                                        backgroundColor: "rgba(128, 128, 128, 0.3)", // Grayish background color
                                        boxShadow: "0 0 10px 12px rgba(138, 138, 138, 0.4)"
                                    }}
                                >?</span>
                                <div className="absolute top-14 -left-6 transform rotate-45 bg-green-500"></div>
                            </div>
                        </div>

                        <div className="absolute z-20 w-[300px] h-[300px] top-[15%] md:top-[3%] lg:top-[15%] xl:top-[15%] 2xl:top-[20%] 2xl:w-[400px] 2xl:h-[400px] 2xl:right-[35%]">
                            <img src={RippleImage1} alt="RippleImage" />
                        </div>

                        <div className="  overflow-auto flex flex-col justify-center items-center absolute left-[45%] top-[36%]"  >
                            <div className="border px-9 py-4 bg-white rounded-2xl" >
                                <div className='z-[20] flex items-center gap-x-4 mt-3 mb-0 justify-center'>
                                    <div className='flex gap-x-3'>
                                        {Array(3).fill(null).map((_, index) => (
                                            <div key={index} className='w-8 h-8 lg:w-8 lg:h-8 2xl:w-14 2xl:h-14 bg-[#F4EBFF] border-2 border-white rounded-full flex justify-center items-center text-gray-800 text-[24px]'>
                                                {selectedNumbersForModal[index] !== undefined ? selectedNumbersForModal[index] : ''}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex flex-col gap-y-3 md:gap-y-3 lg:gap-y-4 2xl:gap-y-4" >
                                    <div className='flex justify-center w-full px-2 py-4 z-20 mb-0'>
                                        <div className='grid grid-cols-3 gap-5 mt-0 '>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'cross', 0, '?'].map((item, index) => (
                                                <button
                                                    key={index}
                                                    id={`button-${item}`} // Add ID for easy keypress highlighting
                                                    className={`w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] 2xl:w-[95px] 2xl:h-[95px] rounded-xl 2xl:rounded-2xl flex justify-center items-center
                                                    text-lg lg:text-2xl 2xl:text-3xl font-bold 
                                                    bg-white transition-all duration-200 ease-out transform hover:scale-105 active:scale-95 
                                                    shadow-[6px_4px_1px_rgba(0,0,0,0.6),_0px_0px_4px_rgba(255,255,255,0.2)]
                                                    border-4 border-[#6c6f75] text-gray-800
                                                    'text-gray-800'
                                                    
                                                    `}
                                                >
                                                    {item === 'cross' ? <img src={CrossIcon} alt='X' className='text-sm' /> : item}
                                                </button>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </FullPageModalWrapper>

            {/* Modal for the Test Completion */}
            <ModalWrapper isOpen={isTestCompleted} isClosing={isClosing}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-center items-center gap-x-[0px] relative'>
                        <img src={TickIcon} className='' alt='✓' />
                    </div>
                    <div >
                        <div className='text-[#101828] font-bold text-sm md:text-sm lg:text-2xl 2xl:text-3xl'> {t('dinv6_completion_modal_test_completed')}
                            <br /> </div>
                    </div>

                    {isDataSaving === false ? <div className={`flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out
                     hover:bg-purple-800 gap-x-1 cursor-pointer`}
                        onClick={(e) => {

                            return saveTestData();
                        }}
                    >
                        <button className={`cursor-pointer text-sm md:text-sm lg:text-2xl 2xl:text-3xl`}  >{t('dinv6_completion_modal_next')}</button> {/* Start the animation */}
                    </div> : <NormalTextLoader text={t('dinv6_data_saving_modal_please_wait')} />}
                </div>
            </ModalWrapper>

            {/* Manage the Abort Modal */}
            <ModalWrapper isOpen={isAbort} isClosing={isClosing}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-start items-center gap-x-[0px] relative'>
                        <img src={AbortIcon} className={`${isAbort ? 'abort-icon-animate' : ''}`} alt='☐' />
                    </div>
                    <div >
                        <div className='text-[#101828] font-bold text-sm md:text-sm lg:text-2xl 2xl:text-3xl text-left'>{t('dinv6_abort_modal_abort_test')} <br /> </div>
                    </div>

                    <div>
                        <div className='text-[#1D2939] text-sm md:text-sm lg:text-2xl 2xl:text-3xl text-left'>
                            {t('dinv6_abort_modal_confirmation1')}<br /> {t('dinv6_abort_modal_confirmation2')}
                        </div>
                    </div>
                    <div className='flex justify-between gap-x-1 mt-2'>
                        <div className={`flex justify-center items-center bg-white text-gray-500 border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-300 gap-x-1 cursor-pointer flex-1`}
                            // onClick={onConfirm}
                            onClick={(e) => {
                                handleAbortClose();
                            }}
                        >
                            <button className={`cursor-pointer text-sm md:text-sm lg:text-2xl 2xl:text-3xl`}  >{t('dinv6_abort_modal_return_to_test')} </button> {/* Start the animation */}
                        </div>
                        <div className={`flex justify-center items-center bg-red-600 text-gray-100 border border-gray-300 py-3 px-3 rounded-lg transition-colors duration-300 ease-in-out
                         hover:bg-red-800 gap-x-1 cursor-pointer flex-1 text-sm md:text-sm lg:text-2xl 2xl:text-3xl`}
                            // onClick={onConfirm}
                            onClick={(e) => {
                                handleAbortConfirm();
                            }}
                        >
                            <button className={`cursor-pointer`}  >{t('dinv6_abort_modal_abort')}</button> {/* Start the animation */}
                        </div>

                    </div>
                </div>
            </ModalWrapper>

            {/* Modal for the Test help */}
            <ModalWrapper isOpen={isHelp} isClosing={isClosing} invokeOutSideProp={{ close: true }} onClose={handleCloseHelp}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-center items-center gap-x-[0px] relative'>
                        <img src={TickIcon} className='' alt='✓' />
                    </div>
                    <div>
                        <div className='text-[#101828] text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>
                            {renderTextWithBreaks(t('dinv6_test_paused'))}
                        </div>
                        <br />
                        <div className='text-[#101828] text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>
                            {renderTextWithBreaks(t('dinv6_continue_instruction'))}
                        </div>
                    </div>

                    {isDataSavedRef.current === false ? (
                        <div
                            className={`flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-purple-800 gap-x-1 cursor-pointer`}
                            onClick={handleCloseHelp}
                        >
                            <button className='cursor-pointer text-sm md:text-sm lg:text-2xl 2xl:text-3xl'>
                                {t('dinv6_return_button')}
                            </button>
                        </div>
                    ) : (
                        <NormalTextLoader text={t('dinv6_wait_message')} />
                    )}
                </div>
            </ModalWrapper>


            {/* Pause and Resume test model */}
            <ModalWrapper isOpen={isPaused} isClosing={isClosing}>
                <div className='flex flex-col gap-y-4'>
                    <div className='flex justify-center items-center gap-x-[0px] relative'>
                        <img src={ResumeIcon} className='' alt='║' />
                    </div>
                    <div >
                        <div className='text-[#101828] font-bold text-2xl'>Testi pysäytetty <br /> </div>
                    </div>

                    <div>
                        <div className='text-[#1D2939] text-[22px]'>
                            Voit jatkaa testiä samasta kohdasta <br /> mihin jäit.
                        </div>
                    </div>

                    <div className={`flex justify-center items-center bg-purple-600 text-gray-100 border border-gray-300 py-4 px-4 rounded-lg transition-colors duration-300 ease-in-out hover:bg-purple-800 gap-x-1 cursor-pointer`}
                        // onClick={onConfirm}
                        onClick={(e) => {
                            if (isTestRunning === false)
                                return console.info('Cant resume without starting the test');

                            setIsClosing(true);  // Start the closing animation
                            setTimeout(() => {
                                setIsClosing(false);
                                // Perform your confirm action here
                                console.log('Confirmed action test start');
                                console.log(`[+] Reached`);
                                console.log(`[+] Resume Test `);
                                console.info('Test is resuming');
                                setIsPaused(false);
                                resumeTest();
                            }, 300);  // 300ms delay for smooth transition
                        }}
                    >
                        <button className={`cursor-pointer`}  >Jatka</button> {/* Start the animation */}
                    </div>
                </div>
            </ModalWrapper>

            <ToastContainer />
        </div>
    );
};

export default DinTestV10Antiphasic;