import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import QuestionTitle from "./QuestionTitle";
import RatingScaleLandingPage from "./RatingScaleLandingPage";
import FullPageLoader from '../../../utils/Loader/FullPageLoader';
import {
  ArrowLeft,
  InfoIcon,
  HandPointingLeftSmall,
  Subtract,
  SubtractWhite
} from "../../../assets/Icons";



function KuuloKyselyLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRating, setSelectedRating] = useState(5); // Track selected rating

  //Make sure all content is loaded before showing the page. Uses FullPageLoader
const [isImagesLoaded, setIsImagesLoaded] = useState(false);

useEffect(() => {
    const imageSources = [ArrowLeft, InfoIcon, Subtract,SubtractWhite, ArrowLeft, HandPointingLeftSmall];
    let imagesLoadedCount = 0;
    const totalImages = imageSources.length;

    imageSources.forEach((src) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
        imagesLoadedCount++;
        if (imagesLoadedCount === totalImages) {
        setIsImagesLoaded(true);
        }
    };
    img.onerror = () => {
        // Handle errors if needed
        imagesLoadedCount++;
        if (imagesLoadedCount === totalImages) {
        setIsImagesLoaded(true);
        }
    };
    });
}, []);



  // Navigation handlers
  const handleStartClick = () => {
    navigate("/KuuloKysely");
  };

  const handleCancelClick = () => {
    navigate("/TestLandingPage");
  };

  const handleHelpClick = () => {
    navigate("/HelpPage"); // Ensure this route exists
  };


  const handleRatingSelect = rating => {
    setSelectedRating(rating);
  };

  if (!isImagesLoaded) {
    return <FullPageLoader />;
  }

  return (
    <div className="w-full min-h-screen bg-gray-100 relative overflow-hidden">
            <img
            className="absolute z-100" alt="Star Icons" src={Subtract} style={{ top: "190px", left: "600px", }}
            />
              <img
          className="absolute z-100" alt="Star Icons" src={Subtract} style={{ top: "240px", left: "650px", width: "30px" }}
          />
              <img
          className="absolute z-100" alt="Star Icons" src={Subtract} style={{ top: "690px", left: "850px", }}
          />
              <img
          className="absolute z-100" alt="Star Icons" src={Subtract} style={{ top: "680px", left: "100px", width: "30px" }}
          />

<img
            className="absolute z-100" alt="Star Icons" src={SubtractWhite} style={{ top: "150px", left: "700px", }}
            />
              <img
          className="absolute z-100" alt="Star Icons" src={SubtractWhite} style={{ top: "220px", left: "50px", width: "60px" }}
          />
              <img
          className="absolute z-100" alt="Star Icons" src={SubtractWhite} style={{ top: "850px", left: "300px", }}
          />
              <img
          className="absolute z-100" alt="Star Icons" src={SubtractWhite} style={{ top: "920px", left: "400px", width: "60px" }}
          />


      {/* Top Bar */}
      <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-0 relative z-50'>
        <div className='rounded-xl absolute left-[0.5%] top-[8px]'>
          <div className='text-sm gap-x-2 flex py-2 justify-between px-4 bg-gray-100 text-black rounded-xl'>
            <div className='text-md font-bold flex gap-x-1'>
              <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
              2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-['Red Hat Display'] leading-loose tracking-wide" onClick={handleCancelClick}>
                <img src={ArrowLeft} alt='' className='size-6' />
                <button className=''>{t('audiogram_cancel')}</button>
              </div>
              <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-2 lg:px-19
                  2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-['Red Hat Display'] leading-loose tracking-wide"
                      onClick={handleHelpClick}  >
                      <img src={InfoIcon} alt='ℹ️' className='size-5' />
                      <button className=''>{t('audiogram_help')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Container */}
      <div className="relative z-40 grid grid-cols-1 md:grid-cols-2 w-full h-screen px-4 items-center">

        {/* Left Side Content */}
        <div className="relative flex flex-col w-full max-w-4xl gap-6 items-center justify-center bg-white rounded-[40px] shadow-lg p-6 md:p-10 place-self-center">

          {/* Questionnaire with 10 numbers */}
          <div className="flex flex-col items-center gap-6">
            {/* Question Title */}
            <div className="box-border h-[20vh]flex justify-center items-center cursor-not-allowed">
            <QuestionTitle text={t('PromLandingpage_Question')} className 
            />
            <div className="flex flex-wrap justify-center items-center gap-4 cursor-not-allowed">
            <div>
          <RatingScaleLandingPage
            //descriptions={answerDescriptions[currentQuestion.id]}
            onRatingSelect={handleRatingSelect}
            selectedRating={selectedRating}
          />
          {/* Descriptions Below Rating Scale */}
          <div className="flex justify-between w-full mt-8 px-0">
            <span className="text-xl text-gray-700">
              {t('PromLandingpage_1')}
            </span>
            <span className="text-xl text-gray-700">
              {t('PromLandingpage_10')}
            </span>
          </div>
            </div>
            <img
            className="absolute bottom-[-500px] left-80 w-100 h-100 object-cover z-100"
            alt="Hand pointing"
            src={HandPointingLeftSmall}
            />
            </div>
            </div>
          </div>
        </div>

        {/* Right Side Content */}
        <div className="flex flex-col items-start justify-center gap-6 place-self-center">
          {/* Test Title */}
          <div className="text-gray-700 text-6xl font-black font-['Sprat'] leading-10">
            {t('PromLandingpage_questionnnaire')}
          </div>

          {/* Test Description */}
          <p className="text-xl font-normal text-gray-700 font-['Red Hat Display'] tracking-wide">
            {t('PromLandingpage_info1')}
            <br/>
            {t('PromLandingpage_info2')}
          </p>

          {/* "Aloita kuulokysely" Button */}
          <button
            className="inline-flex z-40 items-center justify-center px-6 py-4 bg-purple-600 text-white text-xl font-bold font-['Red Hat Display'] rounded-2xl shadow-md hover:bg-purple-900 focus:ring-4 focus:ring-purple-200 tracking-wide"
            onClick={handleStartClick}
          >
          {t('PromLandingpage_start')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default KuuloKyselyLandingPage;
