import React, { useRef,  } from 'react';
import { useTranslation } from 'react-i18next';
import '../Modals/InfoModalv1/InfoModal.css'; // Importing the CSS file for animations
import './Modal2.css';

const AudiogramLandingPageHelp = ({ onClose}) => {

    const EnableContinueButtonRef = useRef(true); // Initially set to false
    const { t } = useTranslation();

    const handleContinue = () => {
        console.log('Closing the  modal');
        onClose(); // Close the modal
    };

    return (
        <div className="sign-out-modal-overlay">
        <div className="sign-out-modal modal-content">
            <p className="sign-out-modal-text"></p>
            <p className="sign-out-modal-text2 text-[#101828] font-bold text-2xl text-left">
            {t('AudiogramLandingPageHelpModal_bold')}
            </p>
            <br />
            <p className="sign-out-modal-text2 text-[#1D2939] text-[22px] text-left">
            {t('AudiogramLandingPageHelpModal_normal_text')}
            </p>
            <br />
            <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
            {/* "Palaa testiin" button */}
            <button
                onClick={handleContinue}
                disabled={!EnableContinueButtonRef.current} // Disable button until enabled
                className={`flex justify-center items-center border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out gap-x-1 flex-1 ${
                EnableContinueButtonRef.current
                    ? 'bg-purple-600 text-white hover:bg-gray-300 cursor-pointer' // 
                    : 'bg-purple-900 text-white cursor-not-allowed' // 
                }`}
            >
                {t('AudiogramLandingPageHelpModal_button_text')}
            </button>
            </div>
        </div>
        </div>
    );
};

export default AudiogramLandingPageHelp;