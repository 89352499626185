import React from 'react';
import { InfoIcon, LeftIcon, RightIcon } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import SegmentedColorBar from './SegmentedColorBar';
import { useTranslation } from 'react-i18next';
import SegmentedColorBar3Segments from './SegmentedColorBar3';


// The SegmentedColorbars is changed to have only 3 colors + The getSegmentedClass is changed here.

const DinResultPageForPrematureExit = ({ l50 = -8.5 }) => {
    /* Generate the segmentClass to highlight 
    const getSegmentClass = (l50) => {
        switch (true) {
            case l50 >= -4:
                return "highlight-segment-1"; // First Segment
            case l50 < -4 && l50 >= -6:
                return "highlight-segment-2"; // Second Segment
            case l50 < -6 && l50 >= -8:
                return "highlight-segment-3"; // Third Segment
            case l50 < -8 && l50 >= -10:
                return "highlight-segment-4"; // Fourth Segment
            default:
                return "highlight-segment-5"; // Final Segment
        }
    }; */

    const getSegmentClass = (l50) => {
        if (l50 === null) return '';
        switch (true) {
            case l50 >= -8:
                return 'highlight-segment-2'; // Second Segment
            case l50 < -8 && l50 >= -10:
                return 'highlight-segment-3'; // Third Segment
            default:
                return 'highlight-segment-5'; // Final Segment
        }
    };
    /* For Left and right ear */
    const segmentClass = getSegmentClass(l50);
    console.log({ segmentClass });
    /* Translation language */
    const { t } = useTranslation();
    const navigate = useNavigate();
    /* navigat to testlandingpage */
    const handleNext = async () => {
        try {
            return navigate('/TestLandingPgage');
        } catch (error) {
        }
    };

    return (
        <div className="h-screen bg-gray-100 ">
            <div className='h-screen flex flex-col items-center overflow-auto'>
                {/* Navigation Center */}
                {/* <div className='text-md font-bold flex gap-x-1 w-full p-4 justify-between'>
                    <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-4 lg:px-19
                               2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1'
                        onClick={(e) => { }}  >
                        <img src={InfoIcon} alt='ℹ️' className='size-4' />
                        <button className='' >{t('dinResultPage_navigation_help')}</button>
                    </div>
                    <div className='flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-4 lg:px-6
                               2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1' onClick={(e) => { handleNext(); }}>
                        <button className='' >{t('dinresult_soundfield_NextButton')}</button>
                        <img src={RightIcon} alt='' className='size-4' />
                    </div>
                </div> */}
                <div className="text-md font-bold flex justify-between w-full p-4">
                    {/* Left Side Buttons */}
                    <div className="flex gap-x-4">
                        {/* Help Button */}
                        <div
                            className="flex justify-center items-center bg-white text-gray-700 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                            2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                            onClick={() => { }}
                        >
                            <img src={InfoIcon} alt="ℹ️" className="w-6 h-6" />
                            <button>{t('dinResultPage_navigation_help')}</button>
                        </div>
                    </div>

                    {/* Right Side Button */}
                    <button
                        className="flex justify-center items-center bg-purple-700 text-white border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-purple-900 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide mr-4"
                        onClick={handleNext}
                    >
                        {t('audiogramResults_continue')}
                    </button>
                </div>

                {/* Contents */}
                <div className='flex flex-col py-10 gap-y-10 ' >
                    <div className='flex flex-col gap-y-6 '>
                        {/* Header */}
                        <p className='text-sm md:text-3xl lg:text-4xl font-bold px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4'>{t('dinResultPage_header')}</p>
                        <div className='px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4'>
                            <p className='text-sm md:text-1xl lg:text-[20px] text-gray-900'>{t('dinResultPage_results_summary_part1')} <span className='font-bold'>{t('dinResultPage_moderate_severe_hearing_loss_bold')} {' '}</span>{t('dinResultPage_hearing_loss')} </p>
                            <p className='text-sm md:text-1xl lg:text-[20px] text-gray-900'>{t('dinresult_soundfield_ear')}</p>
                        </div>

                    </div>

                    <div className='flex gap-x-4 flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-y-4 justify-center items-center  '>
                        {/* if <70 exit is true */}
                        {<div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col ">
                            <div className="relative flex justify-center items-center w-[360px]  flex-col gap-y-4 ">
                                <div>
                                    {/* <p className='text-gray-900 '>{t('dinResultPage_test_result')}</p> */}
                                    <p className='text-sm md:text-1xl lg:text-2xl font-bold'>{t('dinResultPage_test_result')}</p>
                                </div>
                                <div className='w-full flex justify-center'>
                                    <div className='flex ml-20' />
                                    <SegmentedColorBar3Segments segmentClass={segmentClass} />
                                    {/* Text Labels for Each Segment */}
                                    <div className="space-y-4 ml-6">
                                        <div className="flex flex-col items-start">
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_likely_hearing_loss_first')}
                                            </span>
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_likely_hearing_loss_second')}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_possible_hearing_loss_first')}
                                            </span>
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_possible_hearing_loss_second')}
                                            </span>
                                        </div>
                                        <div className="flex flex-col items-start">
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_normal_hearing_first')}
                                            </span>
                                            <span className="text-sm text-gray-700">
                                                {t('dinResultPage3_normal_hearing_second')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>


                    <div className='px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4'>
                        <p className="text-sm md:text-1xl lg:text-[20px] text-red-700 mt-2 font-bold">
                            {t('dinResultPage_disclaimer')}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DinResultPageForPrematureExit;
