import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonUtil from '../../Global/ButtonUtil';
import { HeadPhoneIconv1, HomeIcon, SpeakersIcon, TestIconv1 } from '../../../assets/Icons';
import { LogOutIconv1 } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';

const SettingsNavbar = ({ children }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation hook


    const handle_Logout = (e) => {
        if (localStorage.getItem('user'))
            localStorage.removeItem('user');
        if (localStorage.getItem('token'))
            localStorage.removeItem('token');
        return navigate('/');
    };

    return (
        <div className=" bg-white p-5 shadow-md">
            <div className="mb-5 font-bold h-[80vh] overflow-auto px-4">
                <div className="Flex flex-col space-y-3">
                    {/* Here you can put the login information or user avatar */}

                    <p></p>
                    <LanguageSwitcher className="flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 w-full"
                    />
                    <ButtonUtil
                        icon={HomeIcon}
                        handleClick={() => navigate('/LandingPage')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_logout_kela_card')}
                    />
                    <p>
                        <span className="text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide">
                            {t('settings_navbar_settings')}
                            </span>
                    </p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/MainSettings')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_select_tests')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/DINSettingsPage')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_din_settings')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramSettingsPage')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_audiogram_settings')}
                    />
                   
                    <p>
                        <span className="text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide">
                            {t('settings_navbar_calibration')}
                        </span>
                    </p>
                    <ButtonUtil
                        icon={HeadPhoneIconv1}
                        handleClick={() => navigate('/HeadPhoneCalibrationPage')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_din_calibration_headphones')}
                    />
                    <ButtonUtil
                        icon={SpeakersIcon}
                        handleClick={() => navigate('/SpeakerCalibrationPage')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_din_calibration_speakers')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramCalibration')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_audiogram_calibration')}
                    />
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/AudiogramCompensationMain')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_headphone_retspl')}
                    />
                        <p>
                        <span className="text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide">
                        {t('settings_navbar_results')}
                        </span>
                        </p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/Dashboard')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_measurement_results')}
                    />
                    <p>
                        <span className="text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide">
                        {t('settings_navbar_ui_design')}
                        </span>
                    </p>
                    <ButtonUtil
                        icon={TestIconv1}
                        handleClick={() => navigate('/PractionerUi')}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_clinician_view')}
                    />
                    <ButtonUtil
                        icon={LogOutIconv1}
                        handleClick={handle_Logout}
                        className = {"flex items-center gap-2 px-6 py-2 bg-white rounded-xl shadow border border-gray-300 hover:bg-gray-100 text-gray-700 text-xl font-bold font-['Red Hat Display'] leading-loose tracking-wide"}
                        text={t('settings_navbar_logout_main_menu')}
                    />

                </div>
            </div>
        </div>
    );
};

export default SettingsNavbar;