
import React, {useState, useEffect, useRef} from "react";
import { useNavigate } from 'react-router-dom';
//import { PauseIcon, InfoIcon } from "../../../assets/Icons"
import { getParam, getCalibrationFactor, getCompensationFactor } from './fetchParam';
import { globalState, fetchData } from './fetchParam';
// import  CustomModal from './AudiometryModal';
//import NavigationBar from './AudiogramNavigationBar';
import AbortPage from '../../Modals/AudiogramModalAbortTest'; // Modal for aborting the test
import PausePage from '../../Modals/AudiogramModalPauseTest'; // Modal for pausing the test
import HelpPageModal from '../../Modals/AudiogramModalHelpPage'; // Modal for help
// import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import isValidJSON from "../../../Functions/IsValidJSON";
import { useTranslation } from 'react-i18next';
import {Raven, RavenGIF, LottieImage, LottieGIF, PauseIcon, Image19 , ArrowLeft,  InfoIcon, NextIcon, ResumeIcon, RippleImage1, SoundIcon, SquareIcon, StarIcon, TickIcon } from '../../../assets/Icons';
import { Frame201 } from "./Frame200";






const Audiogram3 = () => {
    // For language support
    const { t } = useTranslation();

    // For calculating and showing the remaining time
    const [remainingTime, setRemainingTime] = useState(0);
    const [currentFrequencyIndex, setCurrentFrequencyIndex] = useState(0); //also used for tracking frequency
    const stepsPerFrequency = 8;
    const responseTime2 = 4500; // 3500ms + 1000ms = 4500ms
    const [totalEstimatedTime, setTotalEstimatedTime] = useState(0); // in milliseconds

    //
    const [isExaminationRunning, setIsExaminationRunning] = useState(false);
    const [buttonPressCount, setButtonPressCount] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    //const [keyHandled, setKeyHandled] = useState(false);
    const [lightColor, setLightColor] = useState("green");
    const [interrupt, setInterrupt]  = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pressStartTime, setPressStartTime] = useState(null);
    const [pressDuration, setPressDuration] = useState(null);
    const [pressed, setPressed] = useState(false);


    const [currentIntensity, setCurrentIntensity] = useState(50);
    const [currentEarIndex, setCurrentEarIndex] = useState(0);

    const [isTestRunning, setIsTestRunning] = useState(false); // Tracks if the test is running
    const [isNextEnabled, setIsNextEnabled] = useState(false); // Tracks if "Seuraava" button is enabled
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Tracks if the help modal is open
    const [isTestCompleted, setIsTestCompleted] = useState(false);
    const [isTestPaused, setIsTestPaused] = useState(false); 
    const [isTestStarted, setIsTestStarted] = useState(false); 
    const startTimeRef = useRef(null);
    const endTimeRef = useRef(null);

    // FOR PROGRESS BAR UPDATING
    const currentFrequencyIndexRef = useRef(0); // Reference to currentFrequencyIndex
    const [currentStep, setCurrentStep] = useState(0); // Number of frequencies completed
    const currentStepRef = useRef(0); // Reference to currentStep to avoid async issues
    const [totalFrequencies, setTotalFrequencies] = useState(0); // Total frequencies to test
    const [currentFrequencyCompleted, setCurrentFrequencyCompleted] = useState(0); // Frequencies completed
    const [cumulativeProgress, setCumulativeProgress] = useState(0); // Cumulative progress percentage
    const perFrequencyCapRef = useRef(0); // Progress cap per frequency
    // Refs
const cumulativeProgressRef = useRef(cumulativeProgress);


    const [isAbortModalOpen, setIsAbortModalOpen] = useState(false); // Manage abort modal visibility
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false); // Manage abort modal visibility


    const [resultArray, setResultArray] = useState([]);

    // tetsing for time calculation:
    let hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];

    
    //Immediately change state
    const interruptRef = useRef(false);
    const pressedRef = useRef(pressed);
    const heardRef = useRef(false);
    const HeardOnceRef = useRef(false)
    const StartFlagRef = useRef(false);
    const isExaminationRunningRef = useRef(false)
    const isResumingRef = useRef(false)
    const currentEarIndexRef = useRef(0);
    const currentIntensityRef = useRef(50); // Properly initialize currentIntensity
    const buttonDisabledRef = useRef(false); // Create a ref for buttonDisabled
    const pressStartTimeRef = useRef(null);
    const RefSoundStartTime = useRef({ previous: null, current: null });
    const keyHandledRef = useRef(false); // y key pressing handling
    const testPhaseRef =useRef(0)
    const EnableContinueButtonRef = useRef(false)

    const tokenFromStorage = localStorage.getItem("token");
    const token = useSelector((state) => state.authState.token) || (tokenFromStorage && isValidJSON(tokenFromStorage) ? JSON.parse(tokenFromStorage) : null);

    const ipcRenderer = isElectron() ? window.require('electron').ipcRenderer : null;
    const navigate = useNavigate();
    var uncertain = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //let startTime 
    //let endTime;

    //var resultArray = [null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null];
    var db; // Global declaration
    var sum 
    var maxDb
    var numberOfParameters = 3 // How many number of rounds was selected from the settings. 3 for seulonta and 5 for clinical

    var clickTimer;

    var ctrArray =  []; //counter array
    var retest_ctr = 1 // Added: retest counter (max 2)
    var oneKHz_ctr = 1 // Added: the number of occurene of 1000Hz in test
    var oneKTh1 = 0 //Threshold1 in 1000Hz
    var oneKTh2 = 0 //Threshold2 in 1000Hz
    var oneKTh3 = 0 //Threshold3 in 1000Hz
    var oneKTh = 0 // Threshold value in 1000Hz
    var test_accR = 0 //0: accurate data, 1: inaccurate data in right ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times.)
    var test_accL = 0 //0: accurate data, 1: inaccurate data in left ear (ex. When a patient's responses in 1K Hz differ by more than 10 dB on three times)
    var valid_data = 0 //0: valid data, 1: invalid data (ex. When a patient discontinues a test midway.) 2. if the same level was not heard 2 out of 3 presentations
    var total_warmUp_ctrR = 0 //Warm-up test counter on right ear for 1000Hz
    var total_warmUp_ctrL = 0 //Warm-up test counter on left ear for 1000Hz
    var total_main_ctrR = 0 //The number of main test steps for the right ear to find thresholds for each patient
    var total_main_ctrL = 0 //The number of main test steps for the left ear to find thresholds for each patient
    var total_step_ctrR = 0 //The total number of steps for the right ear
    var total_step_ctrL = 0 //The total number of steps for the left ear

    var json = {
        testInfo: [], // Array to store sound data    
        results: [],
        dbLimits: {},  
        sound: [] // Array to store test information
    };

    let responseDuration = null; // = pressStartTime - soundStartTime
    var maxBtnPress = 5; // The maximum number of button press allowed.
    var TotalTime = null;

    var audioContext = new (window.AudioContext || window.webkitAudioContext)(); // Create a single instance of AudioContext
    var currentSrc; // Variable to keep track of the currently playing source


    function isElectron() {
        return typeof window !== 'undefined' && window.process && window.process.type === 'renderer';
    }


    function median(a, b, c) {
    const sorted = [a, b, c].sort((x, y) => x - y);  // Sort the integers
    const middle = Math.floor(sorted.length / 2);
    const isEven = sorted.length % 2 === 0;
    // Return the middle value or average of two middle values
    return isEven ? (sorted[middle - 1] + sorted[middle]) / 2 : sorted[middle];
    }

    async function fetchAndInitialize() {
        await fetchData(); // Ensure this function is awaited
    }


    async function initializeParams() {   // Fetch parameters at the start
        try {
            const params = getParam();
            if (params) {
                const { mode, ResponseTime, rounds, restart, headphoneModel, calibrationLevels, maxLevels, noticeLevels } = params;
                //console.log(`Headphone Model: ${headphoneModel}`);
                console.log(params)
                return params;
            }
        } catch (error) {
            console.error("Error initializing parameters:", error);
        }
        return null;
        }

//fetchAndInitialize()


  // Effect to attach and detach key press listeners
useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('keyup', handleKeyPress);

    return () => {
        window.removeEventListener('keydown', handleKeyPress);
        window.removeEventListener('keyup', handleKeyPress);
    };
  }, []); // Dependencies: handle changes to the pressStartTime state

useEffect(() => {
    buttonDisabledRef.current = buttonDisabled; // Sync the state with the ref
}, [buttonDisabled]);  


useEffect(() => {
pressedRef.current = pressed;  // Sync the ref with the latest `pressed` value
}, [pressed]);


useEffect(() => {
    if (!globalState.settings || !globalState.calibration || !globalState.compensation) {
        fetchAndInitialize();
    }
    }, []); // Empty dependency array to ensure it only runs once on mount


useEffect(() => {
    if (isTestCompleted) {
        setButtonDisabled(true);
    }
    }, [isTestCompleted]);

// ProgressBar
    useEffect(() => {
        cumulativeProgressRef.current = cumulativeProgress;
        }, [cumulativeProgress]);

    

const finishTest = () => { // Marks the test as finished and shows "Seuraava"
    setIsTestRunning(false);
    setIsNextEnabled(true); // Enable "Seuraava" after test completion
};

const handleClick = () => {
    if (!isExaminationRunningRef.current) {
        setIsExaminationRunning(true);
        console.log("Call examination fucntion:");
        calculateRemainingTime();
        examination();
    } else {
        console.log("A voice was heard!:");
        pressTheButton();
    }
};


// Navigate to the previous page when the user wants to leave the test
const handleNextClick = () => {
    setIsModalOpen(false); // Show the modal
    console.log("test ended")
    if (isNextEnabled) {
        setIsTestCompleted(true);
        setButtonDisabled(true);
    }
    globalState.settings = null;
    globalState.calibration = null;
    globalState.compensation = null;
    navigate('/TestLandingPage'); // Navigate to the previous page
};


function handleKeyPress(event) {
    if (event.key === 'y' || event.key === 'Y') { // Check for 'Y' or 'y'
        if (event.type === 'keydown') {
            if (!keyHandledRef.current) { // Ensure the key isn't already handled
                keyHandledRef.current = true;
                handleClick(); // Trigger the same logic as the button click
            }
        } else if (event.type === 'keyup') {
            keyHandledRef.current = false; // Reset the keyHandled flag on key release
        }
    }
}


// Function to pause the test
const pauseTest = (db) => {
    console.log("pauseTest: StartFlag1: " + StartFlagRef.current);
    EnableContinueButtonRef.current = false

    if (StartFlagRef.current === true) { // A test has already started
    console.log("pauseTest: A test has already started and paused.");
    interruptRef.current = true; // Ensure interruptRef is a ref
    isResumingRef.current = true; // Ensure isResumingRef is a ref
    setIsTestPaused(true);  // Mark test as paused
    } else {
    console.log("pauseTest: A test has not started yet.");
    interruptRef.current = true; // Ensure interruptRef is a ref
    isResumingRef.current = true; // Ensure isResumingRef is a ref
    }

    console.log("pauseTest: Pausing at intensity: " + db + "db");
    console.log("pauseTest: Pausing, current state: ", currentFrequencyIndex.current);
};


function resumeTest() {
    console.log("resumeTest: StartFlag1: " + StartFlagRef.current);
    setIsModalOpen(false); // Close the moda
    interruptRef.current = false; // Clear interrupt flag
    setIsExaminationRunning(false);

    console.log("Show db value", db)

    // If the test was not started, begin at 50 dB
    if (StartFlagRef.current === false) {
        db = 50;
        currentIntensityRef.current = db
        console.log("resumeTest: Resuming, using 50 dB as start.");
    } else {
        console.log("resumeTest: Resuming at intensity: ", currentIntensityRef.current, "dB" );
        setCurrentEarIndex(currentEarIndex); // Restore the current ear
    }
    isExaminationRunningRef.current = false;
    isResumingRef.current = true
}



const pressTheButton = () => {
    if (buttonDisabledRef.current) {
        console.log("Button is disabled, skipping press.");
        //buttonPressCount + 1
        return; // Exit if the button is already disabled
    }
    pressStartTimeRef.current = Date.now();
    console.log("Button pressed!", pressStartTimeRef.current);

    setIsTestRunning(!isTestRunning); // Toggle the test running state
    setButtonDisabled(true); // Disable the button immediately
    clearTimeout(clickTimer); // Clear any previous timer to avoid conflicts

    // Increase the press count for tracking button presses
    setButtonPressCount((prevCount) => prevCount + 1); 
    console.log("pressTheButton: buttonPressCount", buttonPressCount + 1);

    if (buttonPressCount + 1 >= maxBtnPress) {
        alert("Liian monta painallusta. Yksi painallus riittää. Jatkuva napin painaminen johtaa testin hylkäämiseen.");
        pressedRef.current = false; // Immediately update ref
        console.log("pressTheButton: too many times a button is pressed. buttonPressCount: " + buttonPressCount);

        // Reset press count and disable the button
        setButtonPressCount(0); 
        setButtonDisabled(true); // Disable the button
        setTimeout(() => {
            setButtonDisabled(false); 
        }, 1000); // Re-enable after 2 seconds

    } else {
        console.log("Pressed line 365",  pressedRef.current );
        pressedRef.current = true; // Immediately update ref

        // Change circle color to light green when the button is pressed
        setLightColor("limegreen");

        // Revert the circle color to dark green after 1 second
        setTimeout(() => {
            setLightColor("green");
        }, 1000);

        console.log("Pressed line  376",  pressedRef.current );
    }

    // Disable the button for 2 seconds after any valid press
    setButtonDisabled(true);
    setTimeout(() => {
        setButtonDisabled(false); // Re-enable the button after 2 seconds
    }, 1000);

    // Reset button press count after 1 second of inactivity
    clickTimer = setTimeout(() => {
        setButtonPressCount(0);
        //setPressStartTime(null);
        setPressDuration(null);
    }, TotalTime);
};



// Define the examination function at the top level
async function examination() {
    console.log("In examination", isExaminationRunningRef.current);

    if (isExaminationRunningRef.current) {
        console.log("Examination: Examination is already running or interrupted.");
        return;
    }

    isExaminationRunningRef.current = true; // Set examination as running
    const params = await initializeParams();
    if (!params) {
        console.error("Failed to initialize parameters.");
        return;
    }

    const compensationData = globalState.compensation; // Ensure compensation data is fetched
    if (!compensationData) {
        console.error("Compensation data not loaded.");
        return;
    }

    //startTimeRef.current = new Date(); // Initialize startTime

    const mode = params.mode;
    const headphoneModel = params.headphoneModel || 'defaultModel';

    // Define earList based on mode
    let earList;
    if (mode === 0) {
        earList = [1, -1]; // Test right ear first, then left ear
    } else if (mode === 1) {
        earList = [0]; // Test both ears at the same time
    } else if (mode === 2) {
        earList = [1]; // Test right ear only
    } else if (mode === 3) {
        earList = [-1]; // Test left ear only
    }
    // Adjusted earList for progress calculation
    let adjustedEarList;
    if (earList.includes('both')) {
        adjustedEarList = [1, -1];
    } else {
        adjustedEarList = earList;
    }

    // Progress bar
    const totalFrequencies = hzList.length * earList.length;
    setTotalFrequencies(totalFrequencies);

    perFrequencyCapRef.current = 100 / totalFrequencies;

    if (!isResumingRef.current) {
        startTimeRef.current = new Date();
        setCurrentEarIndex(0);
        setCurrentFrequencyIndex(0);
        // Reset cumulative progress
        setCumulativeProgress(0);
    }

    await new Promise(resolve => setTimeout(resolve, 5000)); // Initial delay
    isExaminationRunningRef.current = true;



    for (let j = currentEarIndexRef.current; j < earList.length; j++) {
        currentEarIndexRef.current = j;

        let side = earList[j];
        let restarted = false;
        retest_ctr = 1;

        // **Reset currentFrequencyIndexRef.current when switching ears**
        //currentFrequencyIndexRef.current = 0;
        //setCurrentFrequencyIndex(0);

        for (let i = currentFrequencyIndex; i < hzList.length; i++) {
           // currentFrequencyIndexRef.current = i;

            if (interruptRef.current) {
                currentEarIndexRef.current = j;
                console.log("Test interrupted");
                return;
            }

            setCurrentFrequencyIndex(i);

            // Initialize frequencyState for progress tracking


            console.log("PRINT OUT IS RESUMING", isResumingRef.current)
            // If resuming, use currentIntensity instead of resetting to 50dB
            if (isResumingRef.current) {
                console.log('Resuming at saved intensity:', currentIntensityRef.current);
                db = currentIntensityRef.current; // Continue from the paused intensity
                isResumingRef.current = false; // Reset after using the saved value
            } else {
                console.log("Not resuming");
                db = 50; // Start at 50 dB for a new frequency if not resuming
                testPhaseRef.current = 0;
            }

            const frequencyState = {
                dbChangeCounter: 0,
                frequencyProgress: 0,
            };

            StartFlagRef.current = true;

            const hz = hzList[i];
            console.log("DID THE HZ VALUES UPDATE HERE",hz, i)
            const calib = await getCalibrationFactor(side, hz, db);
            const compensation = await getCompensationFactor(headphoneModel, hz);
            console.log(`Calibration: ${calib}, Compensation: ${compensation}`);

            // Max dB levels
            const maxDb = [
                params.maxLevels.max1000,
                params.maxLevels.max2000,
                params.maxLevels.max4000,
                params.maxLevels.max8000,
                params.maxLevels.max1000,
                params.maxLevels.max500,
                params.maxLevels.max250,
                params.maxLevels.max125,
            ];

            // Processing the frequency
            if (hzList[i] === 1000) {
                db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i], frequencyState);
                db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * hzList.length, maxDb[i], frequencyState);
                ctrArray[i + j * hzList.length] = oneKHz_ctr;
                oneKHz_ctr += 1;
            } else {
                // Adjust starting dB based on previous results
                const definedElements = resultArray.filter(Boolean);
                const resultArray_count = definedElements.length;

                console.log("examination: resultArray: " + resultArray);
                console.log("examination: resultArray_count: " + resultArray_count);
                console.log("examination: dB value " + db);

                if (resultArray_count < 5) {
                    if (resultArray[0] > 50) {
                        db = resultArray[0] + 10;
                        db = db >= 100 ? 50 : Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 5 && resultArray_count < 9) {
                    if (resultArray[4] > 50) {
                        db = resultArray[4] + 10;
                        db = db >= 100 ? 50 : Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 9 && resultArray_count < 13) {
                    if (resultArray[8] > 50) {
                        db = resultArray[8] + 10;
                        db = db >= 100 ? 50 : Math.min(Math.max(db, 50), 100);
                    }
                } else if (resultArray_count >= 13 && resultArray_count < 17) {
                    if (resultArray[12] > 50) {
                        db = resultArray[12] + 10;
                        db = db >= 100 ? 50 : Math.min(Math.max(db, 50), 100);
                    }
                }

                db = await warmUp(hzList[i], db, calib, compensation, side, params, maxDb[i], frequencyState);
                db = await mainTest(hzList[i], db, calib, compensation, side, params, params.rounds, i + j * hzList.length, maxDb[i], frequencyState);
            }

            // Store the result
            resultArray[i + j * 8] = db;

            if (hzList[i] !== 1000) {
                ctrArray[i + j * 8] = retest_ctr;
            }

            console.log("Tulokset: " + hzList[i] + " Hz " + db + "db");
            isResumingRef.current = false; // Reset resume flag after resuming

            // Handle retest logic for 1000 Hz
            if (i === 4 && params.restart === 1 && !restarted) {
                if (!(resultArray[i + j * 8] < resultArray[j * 8] + 10 && resultArray[i + j * 8] > resultArray[j * 8] - 10) && retest_ctr < 3) {
                    if (retest_ctr === 1) {
                        oneKTh1 = resultArray[j * 8];
                        oneKTh2 = resultArray[i + j * 8];
                        i = 0;
                        resultArray[j * 8] = resultArray[i + j * 8];
                        ctrArray[j * 8] = ctrArray[i + j * 8];
                        retest_ctr += 1;
                    } else if (retest_ctr === 2) {
                        oneKTh3 = resultArray[i + j * 8];
                        if ((Math.abs(oneKTh2 - oneKTh3) < 10) || (Math.abs(oneKTh1 - oneKTh3)) < 10) {
                            oneKTh = oneKTh3;
                            if (side === 1) {
                                test_accR = 0;
                            } else {
                                test_accL = 0;
                            }
                            restarted = true;
                            retest_ctr += 1;
                        } else {
                            oneKTh = median(oneKTh1, oneKTh2, oneKTh3);
                            resultArray[j * 8] = oneKTh;
                            if (side === 1) {
                                test_accR = 1;
                            } else {
                                test_accL = 1;
                            }
                            restarted = true;
                            retest_ctr += 1;
                        }
                    }
                } else { //A gap between Th1 and Th2 is less than 10dB in 1K. The loop ends. 
                    oneKTh1 = resultArray[j * 8];
                    oneKTh2 = resultArray[i + j * 8];
                    console.log("5. The diffrence is less than 10dB: TH1 in 1K Hz: "+oneKTh1+", TH2 in 1K Hz: "+oneKTh2);
                    restarted = true;
                    resultArray[j * 8] = resultArray[i + j * 8];
                    ctrArray[j * 8] = ctrArray[i + j * 8];
                    if (side === 1) {
                        test_accR = 0;
                    } else {
                        test_accL = 0;
                    }
                    retest_ctr += 1;
                }
            }
            else { //Added 21.10.2024 If the restart is set to false, and teh 1000Hz differ more than 10 dB the testAccr is set to 2. 
                console.log("The results differed more than 10 dB but the restarted option was not chosen")
                oneKTh1 = resultArray[j * 8];
                oneKTh2 = resultArray[i + j * 8];
                oneKTh = resultArray[i + j * 8];
                console.log("Threshold saved as: TH1 in 1K Hz: " +oneKTh1+ ", TH2 in 1K Hz: "+oneKTh2+", and the saved thereshold is "+oneKTh);
                console.log("Resultarray before", resultArray);
                resultArray[j * 8] = resultArray[i + j * 8];
                console.log("Resultarray after", resultArray);
                if (side === 1) {
                    test_accR = 2;
                } else {
                    test_accL = 2;
                }
                retest_ctr += 1;
            }
        
            // After processing the frequency
            updateProgress(frequencyState, true);

            // After processing the frequency, increment currentFrequencyCompleted
            setCurrentFrequencyCompleted(prev => {
                const nextCompleted = prev + 1;

                // Check if all frequencies are completed
                if (nextCompleted >= totalFrequencies) {
                    setCumulativeProgress(100);
                    setIsNextEnabled(true);
                    isExaminationRunningRef.current = false;
                    console.log("All frequencies completed, test finished.");
                }

                return nextCompleted;
            });
        }

        // Adjust cumulative progress to 50% after the first ear
        if (adjustedEarList.length === 2 && j === 0) {
            setCumulativeProgress(50);
            console.log("Side changed, cumulative progress set to 50%");
            console.log("Linde 641", currentFrequencyIndex)
        }

        oneKHz_ctr = 1;
        total_step_ctrR += total_warmUp_ctrR + total_main_ctrR;
        total_step_ctrL += total_warmUp_ctrL + total_main_ctrL;
        console.log("Examination: Side check: " + side);

        currentEarIndexRef.current++;
        console.log("current ear index:", currentEarIndexRef.current)
        console.log("Examination: 1. side check: " + side);

        //currentEarIndex++;
        setCurrentFrequencyIndex(0);
        console.log("line 557 current frequency index at the end of test", currentFrequencyIndex)
    }

    // Ensure cumulative progress reaches 100% (if not already set)
    if (cumulativeProgressRef.current < 100) {
        setCumulativeProgress(100);
    }

    console.log("Test completed for all ears.");
    endTimeRef.current = new Date();
    sessionStorage.setItem('resultArray', resultArray);
    sessionStorage.setItem('mode', params.mode);
    results(resultArray, params.mode);
}


// Testing consists of 2 parts, the warmUp and the mainTest. Warmup is used to quickly find the frequency the patient can hear. 
// 15 dB up or down. 
async function warmUp(hz, db, calib, compensation, side, param, maxDb, frequencyState) {
    let warmUp_ctr = 0; // Counter for warmUp steps
    heardRef.current = false;
    HeardOnceRef.current = false;

    console.log("WARMUP PHASE", hz,"FREQUENCY INDEX" ,currentFrequencyIndex)

    if (testPhaseRef.current >= 1) {
        console.log("db TestPhase exceeded 1, not entering warmUp");
        return db; // Return the current db value
    }

    console.log("warmUpTest dB value: " + db);

    while (db >= 0) {
        if (interruptRef.current) {
            console.log("warmUp: Test paused, exiting warmUp");
            break; // Exit loop if test is paused
        }

        testPhaseRef.current = 0;
        heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, 1);

        // Declare newDb once at the beginning
        let newDb;

        if (heardRef.current) {
            console.log("Patient heard the sound during warmUp");
            HeardOnceRef.current = true;

            if (db === 5) {
                newDb = 0;
            } else {
                newDb = Math.max(-10, db - 15);
            }

            if (newDb !== db) {
                db = newDb;
                updateProgress(frequencyState);
            }

            warmUp_ctr += 1;
        } else {
            if (HeardOnceRef.current) {
                console.log("Patient did not hear the sound after hearing once. Exiting warmUp.");
                db = Math.max(db, -10);
                warmUp_ctr += 1;
                break;
            }

            newDb = db + 15;
            if (newDb !== db) {
                db = newDb;
                updateProgress(frequencyState);
            }

            if (db > maxDb) {
                db = db - 15;
                warmUp_ctr += 1;
                break;
            }
        }
    }

    // Update total warmUp counter
    if (side === 1) {
        total_warmUp_ctrR += warmUp_ctr;
    } else {
        total_warmUp_ctrL += warmUp_ctr;
    }

    return db; // Return the final db value
}

// Main test for testing intensity level on a given frequency.
async function mainTest(hz, db, calib, compensation, side, param, rounds, index, maxDb, frequencyState) {
    let TH;
    let iThr = 0;
    let roundCounter = 0;
    //let startdb = db;
    let heardArray = [];

    heardRef.current = false;
    let mainTest_counter = 0; // Counter for mainTest steps
    let satadB_counter = 0; // Counter for 100 dB tests
    db = currentIntensityRef.current 
    console.log("mainTest dB value: " + db);

    // Initialize TH array based on numberOfParameters
    if (numberOfParameters === 3) {
        TH = [9999, 9998, 9997]; // For rounds = 3
    } else if (numberOfParameters > 3) {
        TH = [9999, 9998, 9997, 9996, 9995]; // For rounds > 3
    } else {
        console.error("Invalid numberOfParameters: " + numberOfParameters);
        return db;
    }

    while (true) {
        if (interruptRef.current) {
            console.log("mainTest: Test paused, exiting mainTest");
            break; // Exit loop if test is paused
        }

        // Declare newDb once at the beginning
        let newDb;

        // Decrease dB when the sound is heard
        while (heardRef.current && db > -10) {
            newDb = Math.max(db - 10, -10);
            if (newDb !== db) {
                db = newDb;
                updateProgress(frequencyState);
            }

            if (db > -5) {
                if (interruptRef.current) {
                    console.log("mainTest: Test paused during decreasing dB");
                    break;
                }

                testPhaseRef.current = 2;
                heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, roundCounter + 1);
                mainTest_counter += 1;
            } else {
                db = -10;
            }
        }

        // Increase dB when the sound is not heard
        newDb = Math.min(maxDb - (maxDb % 5), db + 5);
        if (newDb !== db) {
            db = newDb;
            updateProgress(frequencyState);
        }

        if (interruptRef.current) {
            console.log("mainTest: Test paused during increasing dB");
            break;
        }

        testPhaseRef.current = 1;
        heardRef.current = await soundTest(hz, db, calib, compensation, side, param, testPhaseRef.current, roundCounter + 1);

        if (interruptRef.current) {
            console.log("mainTest: Test paused after soundTest");
            break;
        }

        console.log("MAINTEST heardRef.current:", heardRef.current);

        if (heardRef.current) {
            heardArray.push(db);
            console.log("heardArray:", heardArray);
            TH[iThr] = db;
            console.log("mainTest thresholds:", TH);

            iThr++;

            // Check if threshold criteria are met to progress to the next frequency
            if (numberOfParameters === 3) {
                // For 3-parameter testing, check if any two thresholds are the same
                if (
                    (TH[0] === TH[1]) ||
                    (TH[1] === TH[2]) ||
                    (TH[0] === TH[2])
                ) {
                    console.log("Threshold criteria met, moving to next frequency");
                    break; // Exit the mainTest loop to progress to the next frequency
                } else if (iThr >= 3) {
                    console.log("Threshold criteria not met after 3 attempts, using best estimate");
                    db = Math.min(...heardArray); // Use the lowest dB heard as the threshold
                    break;
                }
            } else if (numberOfParameters > 3) {
                // For more than 3 parameters, check if any three thresholds are the same
                if (
                    (TH[0] === TH[1] && TH[1] === TH[2]) ||
                    (TH[0] === TH[1] && TH[1] === TH[3]) ||
                    (TH[0] === TH[1] && TH[1] === TH[4]) ||
                    (TH[0] === TH[2] && TH[2] === TH[3]) ||
                    (TH[0] === TH[2] && TH[2] === TH[4]) ||
                    (TH[0] === TH[3] && TH[3] === TH[4]) ||
                    (TH[1] === TH[2] && TH[2] === TH[3]) ||
                    (TH[1] === TH[2] && TH[2] === TH[4]) ||
                    (TH[1] === TH[3] && TH[3] === TH[4]) ||
                    (TH[2] === TH[3] && TH[3] === TH[4])
                ) {
                    console.log("Threshold criteria met, moving to next frequency");
                    break; // Exit the mainTest loop to progress to the next frequency
                } else if (iThr >= 5) {
                    console.log("Threshold criteria not met after 5 attempts, using best estimate");
                    db = Math.min(...heardArray); // Use the lowest dB heard as the threshold
                    break;
                }
            }
        } else if (db >= maxDb - (maxDb % 5)) {
            console.log("mainTest: Not heard at max dB:", db);
            if (TH.every(threshold => threshold >= 9995)) {
                console.log("No response at max dB, moving to next frequency");
                uncertain[index] = 1;
                valid_data = 2;
                break;
            } else {
                if (satadB_counter < 2) {
                    console.log("Continuing test at max dB for only two times");
                    satadB_counter++;
                    continue;
                } else {
                    break;
                }
            }
        }

        mainTest_counter += 1; // Increment mainTest counter
        roundCounter++; // Increment round counter

        // Safety check to prevent infinite loops
        if (roundCounter >= 15) {
            console.log("Maximum rounds reached, moving to next frequency");
            break;
        }
    }

    // Update total mainTest counter
    if (side === 1) {
        total_main_ctrR += mainTest_counter;
    } else {
        total_main_ctrL += mainTest_counter;
    }

    return db; // Return the final db value
}



// Added a function for creating a temporary Json file AAA
async function interruptFlag(resultArray, mode, param, hz) {
    let hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];

    interruptRef.current = false; //set interrupt to false so that we can process testresults

    if (currentEarIndex === 0) { // Result is inaccurate.
        test_accR = 1; // right ear
        test_accL = 1;
    } else {
        test_accL = 1; // left ear
    }

    valid_data = 1; // the data is interrupted and terminated by a patient. Meaning invalid.
    await results(resultArray, mode); // Call the modified results function to process and send partial data
}


// Handle abort action from modal
const handleAbort = async () => {
    console.log('Aborting test');
    console.log('startTime', startTimeRef.current)

    //startTime = new Date();
    endTimeRef.current = new Date();
    console.log('startTime not defined', startTimeRef.current)

    // Reset globalState if necessary
    globalState.settings = null;
    globalState.calibration = null;
    globalState.compensation = null;

    // Call interruptFlag
    await interruptFlag(resultArray);

    // Navigate away after aborting
    navigate('/TestLandingPage');
};



async function soundTest(hz, db, calib, compensation, side, param, testPhase, roundCounter) {

    if (interruptRef.current) {
        console.log("soundTest interrupt inside if interrupt", { interrupt: interruptRef.current });
        pauseTest(db);
        endTimeRef.current = new Date();
        results(resultArray, param.mode);
        return;
        }

    calib = getCalibrationFactor(side, hz, db);
    const headphoneModel = param.headphoneModel || 'defaultModel';
    compensation = await getCompensationFactor(headphoneModel, hz);
    console.log("Calibration:", calib, "compensation", compensation)


    playWave(hz, db, calib, compensation, side);
    
    let occurrence = (hz === 1000) ? oneKHz_ctr : retest_ctr; //Added a counter for test occurence
    let num = parseInt(Math.random() * 2000)
    console.log("Print out params in SoundTest Function", param)
    TotalTime = param.ResponseTime + num;


    

    return new Promise(resolve => {
        console.log("Promise inside soundTest got called to save results to soundData")
        setTimeout(() => {

            if (!pressStartTimeRef.current) {
                responseDuration = "NA";
            } else if ((pressStartTimeRef.current) && (buttonPressCount >= maxBtnPress)) {
                responseDuration = "NA";
            } else {
                responseDuration = Math.abs(RefSoundStartTime.current.current - pressStartTimeRef.current);
            }   

            if (interruptRef.current) {
                console.log("soundTest interrupt inside if interrupt", { interrupt: interruptRef.current });
                pauseTest(db);
                endTimeRef.current = new Date();
                results(resultArray, param.mode);
                return;
                }

            //console.log("soundTest: pressStartTime: " + pressStartTimeRef.current);
            //console.log("soundTest: soundStartTime current: " + RefSoundStartTime.current.current);
            //console.log("soundTest: soundStartTime previous: " + RefSoundStartTime.current.previous);
            let responseDurationInSeconds = responseDuration / 1000;
            console.log("soundTest: Response duration in seconds: " + responseDurationInSeconds);
            //console.log("soundTest: Response duration in seconds current: " + responseDurationInSeconds);
            
            var soundData 
            soundData = {  //using global
                "Hz": hz,
                "dbLevel": db,
                "heard": pressedRef.current,
                "ear": side,
                "phase": testPhase,
                "round": roundCounter,
                "occurrence": occurrence, //Added test occurrence
                "responseDuration" : responseDurationInSeconds
            }

                console.log("printOut SoundData", soundData)
                //console.log("line 931", { pressed: pressedRef.current }); // Use pressedRef here
                EnableContinueButtonRef.current = true
                if (!pressedRef.current) {
                    }
                json.sound.push(soundData);
                
                
                resolve(pressedRef.current);

            }, (TotalTime)); //Total Time given for response 5s + random 0-3 s
        pressStartTimeRef.current = (null)
        pressedRef.current = false;
    })
    
}

const NormalFrequencyArray = [1000, 2000, 4000, 8000, 1000, 500, 250, 125]
//const FullFrequencyArray = [1000, 2000, 3000, 4000, 6000, 8000, 1000, 750, 500, 250, 125]

function generateOrderArray(normalArray, testFrequencies) {

    if (!Array.isArray(testFrequencies)) {
    console.error("testFrequencies is not an array:", testFrequencies);
    return [];  // Return an empty array to avoid further errors
    }

    let orderArray = [];
    for (let freq of testFrequencies) {
        // Find the first occurrence of the frequency in the normal array
        let index = normalArray.indexOf(freq);
        if (index !== -1) {
            // Add the index to the order array
            orderArray.push(index);
        }
    }
    return orderArray;
}


async function results(resultArray, mode) {
    let hzList = [1000, 2000, 4000, 8000, 1000, 500, 250, 125];
    const startTime = startTimeRef.current || new Date();  // Fallback to current time if startTime is null - To avoid error when interrupting the test before starTest is pressed.
    const endTime = endTimeRef.current || new Date(); // Fallback to current time if endTime is null 
    console.log('Hz List in results read:', hzList);
    console.log({ interrupt: interruptRef.current });
    let testTime = []

    // Ensure we only send the results if the test was not interrupted
    if (!interruptRef.current) {
    interruptRef.current = false; // Ensure test is not marked as interrupted
    if (startTime && endTime) {
        let testTime = parseInt((endTime.getTime() - startTime.getTime()) / 1000);
        console.log('Test time (in seconds):', testTime);
    } else {
        console.warn("startTime or endTime is null, cannot calculate test time.");
    }

    let testSec = testTime % 60;
    let testMin = (testTime - testSec) / 60;

    const testStartTimestamp = startTime;
    const testEndTimestamp = endTime;
    const testDuration = `${testMin} min, ${testSec} s`;
    const interrupted = interruptRef.current;  // Reflect the interrupt status
    const accuracyRight = test_accR; // Accuracy in the right ear
    const accuracyLeft = test_accL;  // Accuracy in the left ear
    const validity = valid_data; // Validity of the test

    const totalWarmUpStepsRight = total_warmUp_ctrR;
    const totalWarmUpStepsLeft = total_warmUp_ctrL;
    const totalMainStepsRight = total_main_ctrR;
    const totalMainStepsLeft = total_main_ctrL;

    // Constructing result data based on the test mode (both ears or one ear)
    let results = [];

    if (mode === 0) {  // Both ears tested
        let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
        let doubleOrderArray = [];

        for (let index of orderArray) {
        doubleOrderArray.push(index);  // Right ear
        doubleOrderArray.push(index + 8);  // Left ear
        }

        orderArray = doubleOrderArray;

        for (let i = 0; i < orderArray.length; i++) {
        let orderIndex = orderArray[i];
        let hzIndex = orderIndex % NormalFrequencyArray.length;
        let frequency = NormalFrequencyArray[hzIndex];
        let korva = orderIndex < NormalFrequencyArray.length ? 1 : -1;  // Right or Left ear

            // Map Korva to "right" or "left"
            let ear = korva === 1 ? "right" : "left";

        let resultData = {
            Hz: frequency,
            dbLevel: resultArray[orderIndex],
            ear: ear,
            occurrence: ctrArray[orderArray[i]],
            uncertain: uncertain[orderIndex],
        };
        results.push(resultData);
        }
    } else {  // Only one ear tested
        let orderArray = generateOrderArray(NormalFrequencyArray, hzList);
        for (let i = 0; i < orderArray.length; i++) {
        let orderIndex = orderArray[i];
        let hzIndex = orderIndex % NormalFrequencyArray.length;
        let frequency = NormalFrequencyArray[hzIndex];
        let ear = orderIndex < NormalFrequencyArray.length ? 1 : -1;
        let resultData = {
            Hz: frequency,
            dbLevel: resultArray[orderIndex],
            ear: ear,
            occurrence: ctrArray[orderArray[i]],
            uncertain: uncertain[orderIndex],
        };
        results.push(resultData);
        }
    }

    //Retrieve the sounddata that contains the path to the end results. Meaning we store the db  levels to achieve the end result.
    const fullResults = json.sound; 
    console.log("fullResults", fullResults)

    // Build the data object to send
    const dataToSend = {
        testStartTimestamp,
        testEndTimestamp,
        testDuration,
        interrupted,
        accuracyRight,
        accuracyLeft,
        validity,
        totalWarmUpStepsRight,
        totalWarmUpStepsLeft,
        totalMainStepsRight,
        totalMainStepsLeft,
        results,
        fullResults
        };
    
        console.log("Data to send:", dataToSend);
    
        try {
        // Now send the data using fetch
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/audiogram", {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,  // Assuming you're using JWT for auth
            },
            body: JSON.stringify(dataToSend)
        })
        if (!response.ok) {
            const errData = await response.json();
            throw new Error(`Error ${response.status}: ${errData.message || response.statusText}`);
            }

        const data = await response.json();

        try {
            localStorage.setItem('audiogramData', JSON.stringify(dataToSend));
            } catch (error) {
                console.error('Failed to save data to localStorage:', error);
                // Optionally, notify the user or handle the error appropriately
            }

        if (data.status) {
        console.log('Audiogram saved successfully');
        // Proceed as needed
        finishTest();
        } else {
        console.error('Error saving audiogram:', data.message);
        }
    } catch (error) {
        console.error('Error sending data:', error);
        // Handle error appropriately
    }
    }
}
    



// dEFINE THE PROPERTIES OF THE SOUND BEING PLAYED
function playWave(hz, db, calib, compensation, side, duration= 1.5) {
    if (audioContext.state === 'suspended') {
        audioContext.resume();
    }

    // Stop any currently playing sound
    if (currentSrc) {
        currentSrc.stop();
        currentSrc.disconnect();
        currentSrc = null; // Reset to ensure the sound stops
    }

    let oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(hz, audioContext.currentTime);

    let gainNode = audioContext.createGain();
    let dbMultiplier = (10 ** ((db + calib + compensation - 80) / 20));

    if (!isFinite(dbMultiplier)) {
        console.error(`Invalid gain value computed: ${dbMultiplier}`);
        dbMultiplier = 0; // Fallback to 0
        db = 0 // Fallback to 0
        return
    }

    console.log("dB value",db); // SHow the dB in the browser console
    sum = db+calib+compensation
    //console.log("dB value korjattu",sum); // SHow the dB in the browser console

    gainNode.gain.value = dbMultiplier;
    console.log("gainNode value", gainNode.gain.value );

    //Measure time taken to do the test
    let currentTime = new Date(); //Tämä on ajan testausta varten
    let elapsedTime = currentTime - startTimeRef.current;
    // Convert milliseconds into minutes, seconds, and milliseconds
    let seconds = Math.floor(elapsedTime / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    console.log("Freq: ", hz, "dB: ", db, " volume: ", gainNode.gain.value , "Time:",  minutes + "min " + seconds + "s " );
    currentIntensityRef.current = db; // ... current intensity

    // Play the side either from the right or left side using the panner

    console.log("side", side)
    console.log("currentTime", currentTime)
    let panner = audioContext.createStereoPanner();
    panner.pan.setValueAtTime(side, audioContext.currentTime);

    oscillator.connect(gainNode);
    gainNode.connect(panner);
    panner.connect(audioContext.destination);

    RefSoundStartTime.current.previous = RefSoundStartTime.current.current;

    // Set the new current timestamp
    RefSoundStartTime.current.current = Date.now();
    //console.log("Current time:", RefSoundStartTime.current.current);
    //console.log("Previous time:", RefSoundStartTime.current.previous);

    oscillator.start();
    oscillator.stop(audioContext.currentTime + duration);
    currentSrc = oscillator; // Save the source to stop it later
}


    // Three modals state handling - 1) HelpModal 2) PauseModal 3) AbortModal
    const handleHelpClick = () => {
    pauseTest(); // Pause the test before showing the modal
    setIsHelpModalOpen(true); // Show the help modal
    };

    const handleCloseHelpModal = () => {
    resumeTest(); // Resume the test
    setIsHelpModalOpen(false); // Hide the help modal
    };


    const handleAbortClick = () => {
    pauseTest(); // Pause the test
    setIsAbortModalOpen(true); // Show abort modal
    };
    
    const handleCloseAbortModal = () => {
    resumeTest(); // Resume the test
    setIsAbortModalOpen(false); // Hide the abort modal
    };


    const handlePauseClick = () => {
    pauseTest(); // Pause the test
    setIsPauseModalOpen(true); // Show abort modal
    };
        
    const handleClosePauseModal = () => {
    resumeTest(); // Resume the test
    setIsPauseModalOpen(false); // Hide the abort modal
    };


    const handleStopClick = () => {
    pauseTest(); // Pause the test
    setIsHelpModalOpen(true); // Show the help modal
    setIsModalOpen(false); // Hide the abort modal
    };

    const handleResultsClick = () => {
        // Navigate to the results page
        navigate('/AudiogramResults'); // Adjust the path as needed
        };


    /// Calculating the remaining time to show in the UI
    // Function to calculate remaining time
    const earList = ['left', 'right']; // 1 or 2 ears

    const calculateRemainingTime = () => {
    const frequenciesRemaining = hzList.length - currentFrequencyIndex;
    const totalStepsRemaining = frequenciesRemaining * stepsPerFrequency * earList.length;
    const totalTimeRemaining = totalStepsRemaining * responseTime2; // in milliseconds
    setRemainingTime(totalTimeRemaining);
    };

    // Update remaining time when currentFrequencyIndex changes
    useEffect(() => {
        if (isExaminationRunningRef.current) {
        calculateRemainingTime();
        }
    }, [currentFrequencyIndex, isExaminationRunningRef.current]);

        // Function to format time in MM:SS
    const formatTime = (timeInMs) => {
        const totalSeconds = Math.ceil(timeInMs / 1000);
        const minutes = Math.floor(totalSeconds / 60).toString();
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };



    useEffect(() => {
        let timer;
        if (isExaminationRunningRef.current) {
        timer = setInterval(() => {
            setRemainingTime((prevTime) => (prevTime > 1000 ? prevTime - 1000 : 0));
        }, 1000);
        }
        return () => clearInterval(timer);
    }, [isExaminationRunningRef.current]);

          // Calculate total estimated time on component mount
    useEffect(() => {
        const totalSteps = hzList.length * stepsPerFrequency * earList.length;
        const totalTime = totalSteps * responseTime2; // in milliseconds
        setTotalEstimatedTime(totalTime);
        setRemainingTime(totalTime); // Initialize remaining time to total estimated time
    }, []); // Empty dependency array ensures this runs once on mount


    // Progress Bar

    function updateProgress(frequencyState, isFrequencyCompleted = false) {
        const perFrequencyCap = perFrequencyCapRef.current;
    
        if (!isFrequencyCompleted) {
            frequencyState.dbChangeCounter += 1;
    
            // Increment progress after the first db change and every second db change
            if (
                frequencyState.dbChangeCounter === 1 ||
                (frequencyState.dbChangeCounter > 1 && frequencyState.dbChangeCounter % 2 === 0)
            ) {
                if (frequencyState.frequencyProgress < perFrequencyCap) {
                    let increment = 1; // Increment by 1%
    
                    frequencyState.frequencyProgress += increment;
    
                    // Cap frequencyProgress at perFrequencyCap
                    if (frequencyState.frequencyProgress > perFrequencyCap) {
                        increment -= (frequencyState.frequencyProgress - perFrequencyCap);
                        frequencyState.frequencyProgress = perFrequencyCap;
                    }
    
                    // Update cumulativeProgress
                    setCumulativeProgress((prev) => {
                        let newProgress = prev + increment;
    
                        // Cap cumulativeProgress at 50% after first ear
                        if (earList.length === 2 && currentEarIndexRef.current === 0 && newProgress > 50) {
                            newProgress = 50;
                        }
    
                        // Cap cumulativeProgress at 100%
                        if (newProgress > 100) {
                            newProgress = 100;
                        }
    
                        return newProgress;
                    });
                }
            }
        } else {
            // Frequency completed, adjust progress to reach perFrequencyCap
            const remainingProgress = perFrequencyCap - frequencyState.frequencyProgress;
    
            if (remainingProgress > 0) {
                frequencyState.frequencyProgress += remainingProgress;
    
                // Update cumulativeProgress
                setCumulativeProgress((prev) => {
                    let newProgress = prev + remainingProgress;
    
                    // Cap cumulativeProgress at 50% after first ear
                    if (earList.length === 2 && currentEarIndexRef.current === 0 && newProgress > 50) {
                        newProgress = 50;
                    }
    
                    // Cap cumulativeProgress at 100%
                    if (newProgress > 100) {
                        newProgress = 100;
                    }
    
                    return newProgress;
                });
            }
        }
    }



    return (
            <div className="h-screen box-border bg-gray-100 overflow-y-auto relative">
            {/* Frame201 */}
            <Frame201 />
        
                {/* Top Bar */}
        <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-0 relative z-50'>
            <div className='rounded-xl absolute w-full left-[0.5%] top-[8px]'>
                <div className='text-sm gap-x-2 flex py-2 justify-between px-4 bg-gray-100 text-black rounded-xl'>
                    <div className='text-md font-bold flex gap-x-1 top-2'>
                        <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide" onClick={handleAbortClick}>
                            <img src={ArrowLeft} alt='' className='size-6' />
                            <button className=''  >{t('audiogram_cancel')}</button>
                        </div>
                        <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-2 lg:px-19
                        2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                            onClick={handleHelpClick}  >
                            <img src={InfoIcon} alt='ℹ️' className='size-5' />
                            <button className='' >{t('audiogram_help')}</button>
                        </div>
                </div>
                <button
                    className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py- lg:px-6
                    2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide mr-4"
                    onClick={handlePauseClick}
                    >
                    <img src={PauseIcon} alt="" className="w-4 h-4 " />
                    {t('audiogram_stop_test')}
                    </button>
            </div>
        </div>

                {/* Right Side - Stop Button */}

            </div>
        
            {/* Instruction text container */}
            <div className="flex flex-col items-center justify-center h-full relative z-40">
                {/* Instruction Text Container */}
                <div className="flex flex-col items-center mb-12">
                    <div className="text-gray-700 text-sm font-bold font-red-hat-display uppercase tracking-widest text-center">
                    {t('audiogram_test_title')}
                    </div>
                    <p className="text-gray-700 text-5xl font-bold font-sprat leading-10 text-center">
                    {t('audiogram_instruction').split('<br />').map((word, index) => (
                        <React.Fragment key={index}>
                        {word}
                        <br />
                        </React.Fragment>
                    ))}
                    </p>
                </div>

                {/* Content Container */}
                <div className="relative flex flex-col w-full max-w-[504px] gap-4 items-center justify-center bg-white rounded-[40px] shadow-lg p-2 md:p-4">
                    <div className="flex flex-col items-center gap-2">
                    {/* Bird Image */}
                    {isExaminationRunningRef.current && !isNextEnabled ? (
                        // Display GIF when the test is running and not yet completed
                        <img
                        className="w-[360px] h-[360px] object-cover"
                        alt="Bird Animation"
                        src={RavenGIF}
                        />
                    ) : (
                        // Display static image when the test is paused, hasn't started, or is completed
                        <img
                        className="w-[360px] h-[360px] object-cover"
                        alt="Bird"
                        src={Raven}
                        />
                    )}
                    {/* Test Duration Title */}
                    <div className="text-gray-700 text-sm font-bold font-red-hat-display uppercase tracking-widest text-center">
                        {t('audiogram_test_duration')}
                    </div>
                    {/* Time Display (if needed) */}
                    </div>

                    {/* Progress Bar */}
                    <div className="w-1/2 mt-2">
                    <div
                        className="h-4 bg-gray-200 rounded-full"
                        role="progressbar"
                        aria-valuenow={currentFrequencyCompleted}
                        aria-valuemin="0"
                        aria-valuemax={totalFrequencies}
                    >
                        <div
                        className="h-full bg-purple-600 rounded-full transition-all duration-300 ease-in-out"
                        style={{ width: `${cumulativeProgress}%` }}
                        ></div>
                    </div>
                    {/* Display Percentage */}
                    <div className="text-sm font-medium text-gray-700 text-center mt-1">
                        {Math.floor(cumulativeProgress)}%
                    </div>
                    </div>

                    {/* Main Button */}
                    {isNextEnabled ? (
                    <button
                        onClick={handleResultsClick}
                        className="bg-[#12b669] text-gray-700 font-bold font-red-hat-display  tracking-widest py-4 px-8 rounded-lg transition-transform duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-md hover:bg-green-800 text-xl"
                    >
                        {t('audiogram_view_results')} {/* 'Katso tulokset' */}
                    </button>
                    ) : (
                    <button
                        onClick={handleClick}
                        className="bg-purple-600 text-gray-100 py-6 px-8 rounded-lg transition-transform duration-200 ease-out transform hover:scale-105 active:scale-95 shadow-md hover:bg-purple-800 text-xl font-red-hat-display tracking-wide"
                    >
                        {isExaminationRunningRef.current
                        ? t('audiogram_heard_sound') // 'Kuulin äänen'
                        : isTestPaused
                        ? t('audiogram_resume_test') // 'Jatka testiä'
                        : t('audiogram_start_test') // 'Aloita testi'
                        }
                    </button>
                    )}

                    {/* Instruction Text */}
                    <p className="text-base font-normal text-gray-700 font-red-hat-display leading-normal text-center">
                    {t('audiogram_wait_if_no_sound')}
                    </p>
                </div>

                {/* AbortPage Modal */}
                {isAbortModalOpen && (
                    <AbortPage onClose={handleCloseAbortModal} resumeTest={resumeTest} onAbort={handleAbort} />
                )}

                {/* PausePage Modal */}
                {isPauseModalOpen && (
                    <PausePage onClose={handleClosePauseModal} resumeTest={resumeTest} onAbort={handleAbort} />
                )}

                {/* HelpPage Modal */}
                {isHelpModalOpen && (
                    <HelpPageModal onClose={handleCloseHelpModal} resumeTest={resumeTest} />
                )}
                </div>
        </div>       
        );
    }

export default Audiogram3;

