import  React, { useState, useEffect } from "react";
import { useNavigate,  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AudiogramLandingPageHelp from '../../Modals/AudiogramLandingPageHelp'; // Modal for help
import { Image19_small,InfoIcon,LottieImage,ArrowLeft} from '../../../assets/Icons';
import { Frame200 } from "./Frame200";
import FullPageLoader from '../../../utils/Loader/FullPageLoader';

function AudiogramLandingPage2() {
const { t } = useTranslation();
const navigate = useNavigate();
const [isHelpModalOpen, setIsHelpModalOpen] = useState(false); // Tracks if the help modal is open

//Make sure all content is loaded before showing the page. Uses FullPageLoader
const [isImagesLoaded, setIsImagesLoaded] = useState(false);

    useEffect(() => {
        const imageSources = [Image19_small, InfoIcon, LottieImage, ArrowLeft];
        let imagesLoadedCount = 0;
        const totalImages = imageSources.length;
    
        imageSources.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            imagesLoadedCount++;
            if (imagesLoadedCount === totalImages) {
            setIsImagesLoaded(true);
            }
        };
        img.onerror = () => {
            // Handle errors if needed
            imagesLoadedCount++;
            if (imagesLoadedCount === totalImages) {
            setIsImagesLoaded(true);
            }
        };
        });
    }, []);

// Navigation handlers
    const handleStartClick = () => {
        navigate("/Audiogram3");
    };

    const handleCancelClick = () => {
        navigate("/TestLandingPage");
    };

    const handleHelpClick = () => {
        setIsHelpModalOpen(true); // Show the help modal
    };

    const handleCloseHelpModal = () => {
     setIsHelpModalOpen(false); // Hide the help modal
    };

    if (!isImagesLoaded) {
        return <FullPageLoader />;
    }
    return (
    <div className="w-full min-h-screen  bg-gray-100 relative overflow-hidden">
        {/* Background Frame element */}
        <Frame200 />

        {/* Top Bar */}
        <div className='hidden md:flex lg:flex 2xl:flex bg-gray-100 flex-col justify-center items-center w-full mt-0 relative z-50'>
            <div className='rounded-xl absolute left-[0.5%] top-[8px]'>
                <div className='text-sm gap-x-2 flex py-2 justify-between px-4 bg-gray-100 text-black rounded-xl'>
                    <div className='text-md font-bold flex gap-x-1'>
                        <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6  rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide" onClick={handleCancelClick}>
                            <img src={ArrowLeft} alt='' className='size-6' />
                            <button className=''  >{t('audiogram_go_back')}</button>
                        </div>
                        <div className="flex justify-center items-center bg-white text-gray-800 border border-gray-300 py-2 px-9 lg:py-2 lg:px-19
                            2xl:py-4 2xl:px-9 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                                onClick={handleHelpClick}  >
                                <img src={InfoIcon} alt='ℹ️' className='size-5' />
                                <button className='' >{t('audiogram_help')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    {/* Content Container */}
    <div className="relative z-40 grid grid-cols-1 md:grid-cols-2 w-full h-screen px-4 items-center">
        {/* Left Side Content */}
        <div className="relative flex flex-col w-[564px] gap-6 items-center justify-center bg-white rounded-[40px] shadow-lg p-6 md:p-10 place-self-center">
            {/* Hand Image */}
            <img
            className="absolute bottom-[-350px] right-80 w-100 h-100 object-cover z-20"
            alt="Hand pointing"
            src={Image19_small}
            />
            <div className="flex flex-col items-center gap-2">
            {/* Bird Image */}
            <img
                className="w-[260px] h-[260px] object-cover"
                alt="Bird"
                src={LottieImage}
            />
            {/* Test Duration Title */}
            <div className="text-gray-700 text-sm font-bold font-red-hat-display uppercase tracking-widest text-center">
                {t('AudiogramLandingPage_test_duration')}
            </div>

            <div className="w-1/2 mt-2">
                    <div
                        className="h-4 bg-gray-200 rounded-full"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
            <div
                className="h-full bg-purple-600 rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${50}%` }}
                ></div>
            </div>
                             {/* Display Percentage */}
            <div className="text-sm font-medium text-gray-700 text-center mt-1">
                {Math.floor(50)}%
            </div>
            </div>


            </div>

            {/* "Kuulin äänen" Button */}
            <button
            className="relative z-30 inline-flex items-center justify-center px-6 py-4 bg-white border border-gray-300 rounded-2xl shadow-sm hover:shadow-md"
            onClick={handleStartClick}
            disabled
            >
            <span className="text-xl font-bold text-gray-700 cursor-not-allowed font-red-hat-display tracking-wide" >
                {t('AudiogramLandingPage_heard_sound')}
            </span>
            </button>

            {/* Instruction Text */}
            <p className="text-base font-normal font-red-hat-display text-gray-700 text-center leading-normal">
            {t('AudiogramLandingPage_wait')}
            </p>
        </div>

        {/* Right Side Content */}
        <div className="flex flex-col items-start justify-center gap-6 place-self-center">
            {/* Test Title */}
            <div className="  text-gray-700 text-6xl font-black font-['Sprat'] leading-10">
            {t('AudiogramLandingPage_hearingTest')}
            </div>

            {/* Test Description */}
            <p className="text-xl font-normal text-gray-700 font-red-hat-display  tracking-wide">
                {t('AudiogramLandingPage_instructions').split('<br />').map((word, index) => (
                    <React.Fragment key={index}>
                        {word}
                        <br />
                    </React.Fragment>
                ))}
            </p>

            {/* "Siirry kuulokynnystestiin" Button */}
            <button
            className="inline-flex z-40 items-center justify-center px-6 py-4 bg-purple-600 text-white text-xl font-bold font-red-hat-display  rounded-2xl shadow-md hover:bg-purple-900 focus:ring-4 focus:ring-purple-200 tracking-wide"
            onClick={handleStartClick}
            >
            {t('AudiogramLandingPage_move_to_test')}
            </button>
        </div>
    </div>
                {/* HelpPage Modal */}
                {isHelpModalOpen && (
                <AudiogramLandingPageHelp onClose={handleCloseHelpModal}/>
            )}
</div>
);
}

export default AudiogramLandingPage2;
