// Different from ButtonUtil.js as it treats icons differently



const ButtonUtilMainSettings = ({ icon, handleClick, text, className }) => {
    return (
        <button
            onClick={handleClick}
            className={`flex items-center flex-nowrap gap-2 px-4 py-2 bg-white rounded-xl  hover:bg-blue-500`}
        >
            {icon && <img src={icon} alt="" className="w-6 h-6" />}
            <span className={`${className} whitespace-nowrap`}>{text}</span>
        </button>
    );
};

export default ButtonUtilMainSettings;