// Import the calculateCorrectPercentage function
export function calculateCorrectPercentage(questionHistory) {
    if (!Array.isArray(questionHistory) || questionHistory.length === 0) {
        return 0; // Return 0% if the input is not an array or is empty
    }

    const totalQuestions = questionHistory.length;
    const correctAnswers = questionHistory.filter(item => item.correct === true).length;

    return (correctAnswers / totalQuestions) * 100;
}

// Main reusable function
export function handleTestLogic(testName, questionHistory, onNavigationLogic) {
    if (testName === 'Muutumaton') {
        try {
            const correctPercentage = calculateCorrectPercentage(questionHistory);

            if (correctPercentage < 70) {
                console.log('[+] Add Navigation logic here');
                if (typeof onNavigationLogic === 'function') {
                    onNavigationLogic(); // Trigger additional navigation logic if passed
                }
            } else {
                console.log(`[+] correctpercentage > 70:`, correctPercentage + '%');
            }
        } catch (error) {
            console.error(`[+] Correct percentage error:`, error.message);
        }
    }
}