import React, { useEffect, useState } from 'react';
import { InfoIcon, ArrowLeft } from '../../../assets/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import SegmentedColorBar3Segments from './SegmentedColorBar3';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    curveFitLogistic,
    getAverageAnswers,
    getUniqueX,
    } from '../../Modules/LogisticeModule/LogisticHelper';


    const DinResultPageAntiphasic = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [l50Array, setL50Array] = useState([]);
    const [isDataAvailable, setIsDataAvailable] = useState(false);

      // Access the passed data
    const dinTestData = location.state?.dinTestData;


    useEffect(() => {
        if (dinTestData && dinTestData.questionHistory && dinTestData.questionHistory.length > 0) {
            let l50ArrayTemp = [];

            dinTestData.questionHistory.forEach((test) => {
                const testName = test.name.trim().toLowerCase();
                // Since we don't know which channel, we accept any available test
                if (
                    testName.includes('left') ||
                    testName.includes('vasen') ||
                    testName.includes('right') ||
                    testName.includes('oikea') ||
                    testName.includes('speaker') || // Include 'speaker' might be used
                    testName.includes('both') 
                    ) {
                    const L50 = calculateL50ForTest(test);
                    if (L50 !== null) {
                        l50ArrayTemp.push(L50);
                        setIsDataAvailable(true);
                    }
                    }
                });
        
                setL50Array(l50ArrayTemp);
            
            } else {
                console.error('No test data available');
                navigate('/TestLandingPage');
                }
    }, [dinTestData, navigate]);



    const calculateL50ForTest = (test) => {
        if (!test.name.toLowerCase().includes('muuttumaton')) {
        const xData = test.value.map((question) => question.speechDB - 65);
        const yData = test.value.map((question) => (question.correct ? 1 : 0));

        const uniqueX = getUniqueX(xData);
        const averageY = getAverageAnswers(xData, yData, uniqueX);

        if (uniqueX.length >= 3) {
            try {
            const { L50 } = curveFitLogistic(uniqueX, averageY);
            return L50;
            } catch (error) {
            console.error('Error in logistic regression:', error);
            return null;
            }
        } else {
            console.warn(
            `Not enough data points for logistic regression in test "${test.name}".`
            );
            return null;
        }
        }
        return null;
    };

    const getSegmentClassForEar = (l50Array) => {
        if (!l50Array || l50Array.length === 0) return '';
        // Option 1 use the highhest value
        //const l50 = Math.min(...l50Array); // Use the worst (highest) L50 value
        // Option 2 use the last value
        const l50 = l50Array[l50Array.length - 1]; // Use the last value in the array
        return getSegmentClass(l50);
    };

    const getMessageKeysForEar = (l50Array) => {
        if (!l50Array || l50Array.length === 0) return null;
                // Option 1 use the highhest value
        //const l50 = Math.min(...l50Array); // Use the worst (highest) L50 value
        // Option 2 use the last value
        const l50 = l50Array[l50Array.length - 1]; // Use the last value in the array
        return getMessageKeys(l50);
    };


    const getSegmentClass = (l50) => {
        if (l50 === null) return '';
        switch (true) {
        case l50 >= -8:
            return 'highlight-segment-2'; // Second Segment
        case l50 < -8 && l50 >= -10:
            return 'highlight-segment-3'; // Third Segment
        default:
            return 'highlight-segment-5'; // Final Segment
        }
    };

    const getMessageKeys = (l50) => {
        if (l50 === null) return null;
        switch (true) {
        case l50 >= -8:
            return {
            first: 'dinResultPage3_likely_hearing_loss_first',
            second: 'dinResultPage3_likely_hearing_loss_second',
            };
        case l50 < -8 && l50 >= -10:
            return {
            first: 'dinResultPage3_possible_hearing_loss_first',
            second: 'dinResultPage3_possible_hearing_loss_second',
            };
        default:
            return {
            first: 'dinResultPage3_normal_hearing_first',
            second: 'dinResultPage3_normal_hearing_second',
            };
        }
    };

    const segmentClass = getSegmentClassForEar(l50Array);
    const messageKeys = getMessageKeysForEar(l50Array);

    const handleNext = () => {
        navigate('/TestLandingPage');
    };

    return (
        <div className="h-screen bg-gray-100">
        <div className="h-screen flex flex-col items-center overflow-auto">
            {/* Navigation Center */}
            <div className="text-md font-bold flex justify-between w-full p-4">
            {/* Left Side Buttons */}
            <div className="flex gap-x-4">
                {/* Help Button */}
                <div
                className="flex justify-center items-center bg-white text-gray-700 border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                            2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-gray-400 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide"
                onClick={() => {}}
                >
                <img src={InfoIcon} alt="ℹ️" className="w-6 h-6" />
                <button>{t('dinResultPage_navigation_help')}</button>
                </div>
            </div>

            {/* Right Side Button */}
            <button
                className="flex justify-center items-center bg-purple-700 text-white border border-gray-300 py-2 px-4 lg:py-2 lg:px-6
                        2xl:py-4 2xl:px-6 rounded-xl transition-colors duration-300 ease-in-out hover:bg-purple-900 gap-x-1 font-bold font-red-hat-display leading-loose tracking-wide mr-4"
                onClick={handleNext}
            >
                {t('audiogramResults_continue')}
            </button>
            </div>

            {/* Contents */}
            <div className="flex flex-col justify-center items-center py-10 gap-y-10">
            <div className="flex flex-col gap-y-6">
                {/* Header */}
                <p className="text-sm md:text-3xl lg:text-4xl font-bold px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
                {t('dinResultPage_header')}
                </p>
                <div className="px-8 md:px-4 lg:px-4 xl:px-4 2xl:px-4">
                {!isDataAvailable || l50Array === null ? (
                    <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900"> 
                    {t('dinResultPage_no_data')}
                    </p>
                ) : (
                    <>
                    <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900 ">
                        {t('dinResultPage_results_summary_part1')}
                        <span className="font-bold lowercase">
                            {t(messageKeys.first)}{' '}
                        {t(messageKeys.second)} 
                        </span> 
                    </p>
                    <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900 ">
                        {t('dinResultPage3_Soundfield')}
                    </p>
                    </>
                )}
                {/*
                    {l50 !== null ? (
                        <p className="text-sm md:text-1xl lg:text-[20px] text-gray-900 ">
                        {l50.toFixed(2)} 
                        </p>
                    ) : (
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                        {t('dinResultPage_no_data')}
                        </p>
                    )} */}
                </div>
            </div>

            {isDataAvailable && (
                <div className="flex justify-center items-center">
                <div className="p-4">
                    <div className="bg-white shadow-lg text-center rounded-3xl p-4 flex flex-col">
                    <div className="relative flex justify-center items-center w-[360px] h-[400px] flex-col gap-y-4">
                        <div>
                        <p className="text-gray-900">{t('dinResultPage_test_result')}</p>
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                            {t('dinResultPage_speaker_result')} 
                        </p>

                        {/* Display all L50 values separated by commas */}
                        {l50Array.length > 0 ? (
                        <p className="text-sm md:text-l lg:text-l ">
                            {l50Array.map((l50, index) => (
                            <span key={index}>
                                {l50.toFixed(2)}{index < l50Array.length - 1 ? ', ' : ''}
                            </span>
                            ))}
                        </p>
                        ) : (
                        <p className="text-sm md:text-1xl lg:text-2xl font-bold">
                            {t('dinResultPage_no_data')}
                        </p>
                        )}

                        </div>
                        {/* Segmented Color Bar */}
                        <div className="w-full flex justify-center"> 
                        <div className="flex justify-center items-center ml-20" />
                            <div className="relative ml-10 ">
                            <SegmentedColorBar3Segments className=""  segmentClass={segmentClass} />
                            </div>
                        {/* Text Labels for Each Segment */}
                        <div className="space-y-4 ml-6">
                            <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_likely_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_likely_hearing_loss_second')}
                            </span>
                            </div>
                            <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_possible_hearing_loss_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_possible_hearing_loss_second')}
                            </span>
                            </div>
                            <div className="flex flex-col items-start">
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_normal_hearing_first')}
                            </span>
                            <span className="text-sm text-gray-700">
                                {t('dinResultPage3_normal_hearing_second')}
                            </span>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>
            )}
            {/* Disclaimer Text */}
            <p className="text-sm md:text-1xl lg:text-[20px] text-red-700 mt-4">
            {t('dinResultPage_disclaimer')}
            </p>
            </div>

        </div>
        </div>
    );
};

export default DinResultPageAntiphasic;
