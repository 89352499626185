import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./Modal2.css"

const AbortPage = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContinue = () => {
    console.log("Continuing the test");
    onClose(); // Close the modal
  };

  const handleAbort = () => {
    console.log("Aborting test");
    navigate('/TestLandingPage'); // Redirect to home page or wherever you want after abort
  };

  return (
    <div className="sign-out-modal-overlay">
        <div className="sign-out-modal modal-content">
          {/* Abort Icon Display */}
            <p className="sign-out-modal-text"></p>
            <p className="sign-out-modal-text2 text-[#101828] font-bold text-2xl text-left"> 
            {t('prom_modal_abort_modal_bold')}  </p> 
              <p className="sign-out-modal-text2 text-[#101828] text-2xl text-left">
              {t('prom_modal_help_page_normal_text')}
          </p>
            <br></br>

           
            <div className="sign-out-modal-actions flex justify-between gap-x-6 mt-2">
                <button onClick={handleContinue} className="flex justify-center items-center bg-purple-600 text-white border border-gray-300 py-3 px-2 rounded-lg transition-colors duration-300 ease-in-out hover:bg-gray-300 gap-x-1 cursor-pointer flex-1">
                  {t('prom_modal_help_page_button')}
                  </button>

            </div>
        </div>
    </div>
  );
};

export default AbortPage;
