import { t } from 'i18next';
import React from 'react';


const UserDisplaySection = ({ user, bannerText = 'Tervetuloa kuulotestiin! Voitte valita alta suoritettavat kuulotestit' }) => {
    return (
        <div className='flex flex-col items-center justify-center '>
            <div>
                <p className="text-[#344054] font-bold font-['Sprat'] text-2xl lg:text-6xl md:text-4xl xl:text-6xl 2xl:text-8xl">{t('userDetails_greeting')}, {user.firstName}!</p>
            </div>
            <div className='mt-4 '>
                <p className='px-4 text-sm md:text-1xl lg:text-2xl xl:text-4xl 2xl:text-4xl text-[##344054]'>{bannerText}</p>
            </div>
        </div>
    );
};

export default UserDisplaySection;